// @flow
import React from "react";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormGroup,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Box,
  Grid,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";

import type { Question, Answer } from "../../models";
import ImagePicker from "./ImagePicker";
import ImageCardField from "../ImageCardField";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  inputWrapper: {
    background: theme.palette.background.paper,
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    marginBottom: theme.spacing(3),
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey[300]}`,
    "&:hover": {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  inputLabel: {
    width: "100%",
  },
  imageButton: {
    width: "100%",
  },
  imageInput: {
    display: "none",
  },
}));

type Props = {
  question: Question,
  answer: Answer,
  onChange: Function,
};

export default function Field(props: Props) {
  const classes = useStyles();
  const { question, answer } = props;
  const { inputType } = question;
  const choices = question.choices || [];

  const getNumberValue = () => {
    return answer && answer.numAnswer ? answer.numAnswer.toString() : "";
  };

  const getTextValue = () => {
    return answer && answer.textAnswer ? answer.textAnswer : "";
  };

  const getSingleChoice = () => {
    return answer && answer.choices ? answer.choices[0] : -1;
  };

  const getMultipleChoices = () => {
    return answer && answer.choices ? answer.choices : [];
  };

  const handleChange = (partialAnswer) => {
    props.onChange({
      ...partialAnswer,
      questionId: question.id,
    });
  };

  const getField = () => {
    if (inputType === "NUMBER") {
      return (
        <Box className={classes.inputWrapper}>
          <TextField
            InputProps={{ disableUnderline: true, inputMode: "numeric" }}
            inputProps={{ inputMode: "numeric" }}
            fullWidth
            type="number"
            value={getNumberValue()}
            onChange={(e) =>
              handleChange({
                numAnswer: parseFloat(e.target.value),
              })
            }
          />
        </Box>
      );
    }

    if (inputType === "TEXT") {
      return (
        <Box className={classes.inputWrapper}>
          <TextField
            InputProps={{ disableUnderline: true }}
            fullWidth
            multiline={question.multiline}
            rows={question.multiline ? 4 : 1}
            value={getTextValue()}
            onChange={(e) =>
              handleChange({
                textAnswer: e.target.value,
              })
            }
          />
        </Box>
      );
    }

    if (inputType === "PICTURE") {
      return (
        <Box className={classes.inputWrapper}>
          <ImagePicker
            question={question}
            onChange={(uri) => handleChange({ blob: uri })}
          />
        </Box>
      );
    }

    if (inputType === "DATE") {
      return (
        <Box className={classes.inputWrapper}>
          <DatePicker
            InputProps={{ disableUnderline: true }}
            disableFuture
            value={getTextValue()}
            openTo="year"
            format="dd/MM/yyyy"
            views={["year", "month", "date"]}
            onChange={(date) =>
              handleChange({
                textAnswer: date.toISOString(),
              })
            }
          />
        </Box>
      );
    }

    if (inputType === "SINGLE_SELECTION") {
      if (choices[0].imageUrl) {
        return (
          <Grid container spacing={4}>
            {choices.map((choice, i) => (
              <Grid item xs={12} md={6} key={`choice_${i}`}>
                <ImageCardField
                  imageUrl={choice.imageUrl}
                  description={choice.description}
                  selected={choice.id === getSingleChoice()}
                  onSelect={() => {
                    handleChange({ choices: [choice.id] });
                  }}
                />
              </Grid>
            ))}
          </Grid>
        );
      }

      return (
        <RadioGroup
          aria-label={question.description}
          value={getSingleChoice().toString()}
          onChange={(e) => {
            handleChange({
              choices: [parseInt(e.target.value)],
            });
          }}
        >
          {choices.map((choice, i) => (
            <Box className={classes.inputWrapper} key={`choice_${i}`}>
              <FormControlLabel
                className={classes.inputLabel}
                value={choice.id.toString()}
                control={<Radio color="primary" />}
                label={choice.description}
              />
            </Box>
          ))}
        </RadioGroup>
      );
    }

    if (inputType === "MULTIPLE_SELECTION") {
      const selectedChoices = getMultipleChoices();
      const isChecked = (choiceId) => selectedChoices.indexOf(choiceId) > -1;

      const getChoices = (choiceId) => {
        if (choiceId === question.exclusiveChoice) {
          return [choiceId];
        }

        // remove exclusive choice
        const idx = selectedChoices.indexOf(question.exclusiveChoice);

        if (idx > -1) {
          selectedChoices.splice(idx, 1);
        }

        return selectedChoices.concat(choiceId);
      };

      return (
        <FormGroup>
          {choices.map((choice, i) => (
            <Box className={classes.inputWrapper} key={`choice_${i}`}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={choice.id.toString()}
                    checked={isChecked(choice.id)}
                    onChange={(e) => {
                      const choiceId = parseInt(e.target.name);

                      if (e.target.checked && !isChecked(choiceId)) {
                        // add new choice
                        handleChange({
                          choices: getChoices(choiceId)
                        });
                      } else if (!e.target.checked && isChecked(choiceId)) {
                        const idx = selectedChoices.indexOf(choiceId);
                        selectedChoices.splice(idx, 1);
                        // remove choice
                        handleChange({
                          choices: selectedChoices,
                        });
                      }
                    }}
                  />
                }
                label={choice.description}
              />
            </Box>
          ))}
        </FormGroup>
      );
    }

    return <div>This should not happen</div>;
  };

  return (
    <FormControl component="fieldset" className={classes.root}>
      {getField()}
    </FormControl>
  );
}
