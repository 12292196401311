// @flow
import { Button, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DialogContainer from "./DialogContainer";

type Props = {
  defaultOpen?: boolean,
  url?: string,
  text: string,
  hasCancel?: boolean,
};

export default function RedirectDialog({ url, text, defaultOpen, hasCancel }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(defaultOpen || false);
  }, [defaultOpen]);

  const handleRedirect = () => {
    window.location.assign(url);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ActionsRenderer = () => {
    return (
      <React.Fragment>
        {hasCancel ? (
          <Button onClick={handleClose} disableElevation>
            {t("cancel")}
          </Button>
        ) : null}

        <Button onClick={handleRedirect} color="primary" variant="contained">
          {t("redirect")}
        </Button>
      </React.Fragment>
    );
  };

  return (
    <DialogContainer
      title={t("redirect")}
      open={open}
      actionRenderer={ActionsRenderer}
      fullWidth={false}
    >
      <Typography variant="body2" component="p">
        {text}
      </Typography>
    </DialogContainer>
  );
}
