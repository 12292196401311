// @flow

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, makeStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useMutation, useQueryCache } from "react-query";

import type { Address } from "../models";
import { saveAddress, updateAddress } from "../services/api";
import { getPreviewAddress } from "../services/address";
import AddressForm from "../components/AddressForm";
import type { Summary } from "../models";
import EditPreviewSection from "../components/EditPreviewSection";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  onSubmit: Function,
  address: Address
};

export default function AddressEditSection({ onSubmit, address }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const update = ({ addressData, defaultAddress }) => {
    if (defaultAddress) {
      return updateAddress(defaultAddress.id, addressData);
    }

    return saveAddress(addressData);
  };

  const cache = useQueryCache();

  const [editing, setEditing] = useState(false);

  const [mutate, mutateState] = useMutation(update, {
    onSuccess: (data) => {
      cache.invalidateQueries("default-address");

      setEditing(false);

      onSubmit(data);
    },
  });

  const handleSubmit = async (result: Address) => {
    try {
      await mutate({ addressData: result, defaultAddress: address });
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = () => {
    if (!address) {
      setEditing(true);
    }

    setEditing(!editing);
  };

  const renderActions = (isExpand: boolean) => {
    if (!isExpand) {
      return <Button color="primary">{t("address.change")}</Button>;
    }
  };

  const expand = address == null || editing;

  const summaries: Array<Summary> = [
    {
      title: `${t("shipping.address")}:`,
      description: getPreviewAddress(address),
    },
    {
      title: `${t("contact.number")}:`,
      description: address ? address.phone : "",
    },
  ];

  return (
    <EditPreviewSection
      onChange={handleChange}
      expanded={expand}
      title={getPreviewAddress(address)}
      summaries={summaries}
      renderActions={() => {
        return renderActions(expand);
      }}
    >
      <AddressForm
        onCancel={() => setEditing(false)}
        onSubmit={(result) => handleSubmit(result)}
        isLoading={mutateState.isLoading}
        defaultAddress={address}
        alwaysShow={address == null}
      />
      {mutateState.error ? (
        <Alert className={classes.alert} severity="error">
          {mutateState.error.message}
        </Alert>
      ) : null}
    </EditPreviewSection>
  );
}
