// @flow

import { Grid, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { useQuery } from "react-query";
import Fallback from "../components/Fallback";
import PageLoader from "../components/PageLoader";
import { findTaskContents } from "../services/api";
import type { TaskContentSection, ContentType } from "../models";
import ConsultationTaskContentList from "../components/ConsultationTaskContentList";
import { Trans, useTranslation } from "react-i18next";
import clsx from "clsx";
import { SUPPORT_EMAIL } from "../constants";
import Seperator from "../components/Seperator";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    marginBottom: theme.spacing(2),
  },
  extra: {
    marginTop: theme.spacing(4),
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

type Props = {
  taskId: string,
  consultationId?: string,
  type: ContentType,
  title?: string,
  disableTitle?: boolean,
  className?: string,
  seperator?: boolean,
  alternative?: string,
  disableExtra?: boolean,
};

type ExtraProps = {
  section: TaskContentSection,
};

export default function ConsultationTaskContent({
  taskId,
  type,
  title,
  disableTitle,
  className,
  seperator,
  alternative,
  disableExtra,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: contents, status, error } = useQuery(`${taskId}-contents`, () =>
    findTaskContents(taskId)
  );

  if (status === "loading") {
    return <PageLoader />;
  }

  if (status === "error") {
    return <Fallback error={error} />;
  }

  const Extra = ({ section }: ExtraProps) => {
    if (disableExtra) {
      return null;
    }

    if (section.type.toLowerCase() === "faq") {
      return (
        <Grid item className={classes.extra}>
          <Typography variant="body2" component="div">
            <Trans
              i18nKey="faq.contact"
              values={{ supportEmail: SUPPORT_EMAIL }}
            >
              Got more questions? Contact us at:
              <a
                href={`mailto:${SUPPORT_EMAIL}`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                {SUPPORT_EMAIL}
              </a>
            </Trans>
          </Typography>
        </Grid>
      );
    }

    return null;
  };

  const section: TaskContentSection = {
    type,
    contents: contents.filter((content) => content.type === type),
  };

  if (!section.contents || !section.contents.length) {
    if (alternative) {
      return (
        <Typography
          variant="body1"
          component="p"
          className={clsx(classes.root, className)}
        >
          {alternative}
        </Typography>
      );
    }

    return null;
  }

  return (
    <Grid
      container
      direction="column"
      className={clsx(classes.root, className)}
    >
      {seperator ? <Seperator /> : null}
      {!disableTitle ? (
        <Typography variant="h4" component="h4" className={classes.title}>
          {title || t(`contenttype.${section.type.toLowerCase()}`)}
        </Typography>
      ) : null}

      <Grid item>
        <ConsultationTaskContentList contents={section.contents} />
      </Grid>
      <Extra section={section} />
    </Grid>
  );
}
