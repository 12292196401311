// @flow

import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Button, Grid, makeStyles } from "@material-ui/core";
import RichTextField from "./RichTextField";
import { useConsultation } from "../providers/ConsultationProvider";

const useStyles = makeStyles((theme) => ({
  avatar: {
    flex: 1,
  },
  editBox: {
    flex: 11,
    minHeight: 100,
    border: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: theme.spacing(4),
  },
  action: {},
  cancelBtn: {
    marginLeft: theme.spacing(2),
  },
}));

type Props = {
  willAutoFocus?: boolean,
  scrollIntoView?: boolean,
  onSaveEdit: Function,
  onCancelEdit?: Function,
  avatar?: React$Node,
  canUploadImage?: boolean,
  primaryText?: string
};

export default function CommentEdit(props: Props) {
  const [isEditing, setIsEditing] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [defaultTextValue, setDefaultTextValue] = useState("");
  const classes = useStyles();
  const { t } = useTranslation();
  const { setFocusCommentEdit } = useConsultation();
  const focusRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (props.scrollIntoView && focusRef && focusRef.current) {
      focusRef.current.scrollIntoView({ behavior: "auto", block: "center" });
    }
  }, [props.scrollIntoView]);

  return (
    <Grid>
      <Grid container direction="row" alignItems="flex-start">
        <Grid item className={classes.editBox} ref={focusRef}>
          <RichTextField
            height={150}
            canUploadImage={props.canUploadImage}
            autoFocus={props.willAutoFocus}
            onFocus={() => {
              if (!isEditing) {
                setIsEditing(true);
              }
            }}
            onChange={(value) => {
              setTextValue(value);
            }}
            defaultValue={defaultTextValue}
          />
        </Grid>
      </Grid>
      {isEditing ? (
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="flex-end"
          className={classes.action}
        >
          <Button
            className={classes.cancelBtn}
            size="small"
            disableElevation
            onClick={() => {
              if (isEditing) {
                setDefaultTextValue("");
                setIsEditing(false);
                setFocusCommentEdit(false);

                if (props.onCancelEdit) {
                  props.onCancelEdit();
                }
              }
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            color="primary"
            size="small"
            variant="contained"
            disableElevation
            disabled={textValue.length === 0}
            onClick={() => {
              setTextValue("");
              setIsEditing(false);

              if (props.onSaveEdit) {
                props.onSaveEdit(textValue);
              }
            }}
          >
            {props.primaryText ? props.primaryText : t("save")}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
}
