// @flow

import type { Address } from "../models";

export function getDefaultAddress(
  addresses: Array<Address>
): Address {
  const address: ?Address = addresses.find((p) => p.isDefault);

  return address || addresses[0];
}

export function getPreviewAddress(address?: Address) {
  if (!address) {
    return "";
  }

  return `${address.address1}, ${address.state}, ${address.postalCode}`
}