import React from "react";
import { useTranslation } from "react-i18next";

import {
  List,
  ListItem,
  Typography,
  makeStyles,
  Grid,
} from "@material-ui/core";

import {
  getQuestionsAndAnswers,
  isConsultationQuestion,
} from "../services/survey";
import ConsultationSection from "./ConsultationSection";
import { useConsultation } from "../providers/ConsultationProvider";
import PageLoader from "./PageLoader";
import Fallback from "./Fallback";
import useAnswers from "../hooks/useAnswers";
import useQuestions from "../hooks/useQuestions";

const useStyles = makeStyles((theme) => ({
  list: {},
  item: {
    flexDirection: "column",
  },
}));

type Props = {
  title: string,
};

export default function QuestionnaireList(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { consultation } = useConsultation();
  const questionsReq = useQuestions(
    consultation.category.id,
    consultation.reason.id
  );
  const answersReq = useAnswers(consultation.id);

  if (questionsReq.status === "loading" || answersReq.status === "loading") {
    return <PageLoader />;
  }

  if (questionsReq.status === "error" || answersReq.status === "error") {
    return <Fallback error={questionsReq.error || answersReq.error} />;
  }

  const questions = questionsReq.data.filter(isConsultationQuestion);
  const answers = answersReq.data;
  const items = getQuestionsAndAnswers(questions, answers, {
    noanswer: t("consultation.noanswer"),
    choice404: t("consultation.choice404"),
  });

  const getAnswer = (input) => {
    const { description, type, answer } = input;

    if (type === "PICTURE") {
      return <img src={answer[0]} alt={description} width="50%" />;
    }

    return answer.map((a, i) => <p key={`answer_${i}`}>{a}</p>);
  };

  return (
    <ConsultationSection title={t("consultation.qa")} defaultExpanded={true}>
      <List className={classes.list}>
        {items.map((question, idx) => (
          <ListItem
            alignItems="flex-start"
            key={`question_${idx}`}
            className={classes.item}
          >
            <Grid container direction="row">
              <Grid item xs={1}>
                <Typography variant="h5" component="div" gutterBottom>
                  {`${idx + 1}.`}
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body2" component="div" gutterBottom>
                  {question.description}
                </Typography>
                <Typography variant="h5" component="div" gutterBottom>
                  {getAnswer(question)}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
    </ConsultationSection>
  );
}
