// @flow

import { Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatTimeDistance } from "../common/utils";
import type { Consultation } from "../models";

type Props = {
  consultation: Consultation,
};

type ItemProps = {
  title: string,
  value: string,
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4)
  },
  value: {
    marginBottom: theme.spacing(4)
  }
}));

export default function ConsultationMeta({ consultation }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const Item = ({ title, value }: ItemProps) => {
    return (
      <Grid item container direction="column">
        <Grid item className={classes.title}>
          <Typography variant="overline" component="div" color="textSecondary">
            {title}
          </Typography>
        </Grid>
        <Grid item  className={classes.value}>
          <Typography variant="body2" component="div">
            {value}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Item title={t("category")} value={consultation.category.name} />
      <Item title={t("reason")} value={consultation.reason.name} />
      <Item
        title={t("updatedAt")}
        value={formatTimeDistance(
          consultation.updatedAt || new Date().toISOString()
        )}
      />
      <Item
        title={t("createdAt")}
        value={formatTimeDistance(
          consultation.createdAt || new Date().toISOString()
        )}
      />
    </Grid>
  );
}
