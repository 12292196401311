// @flow
import * as React from "react";
import { Container, makeStyles } from "@material-ui/core";
import TopBar from "./TopBar";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: props => ({
    display: "flex",
    overflow: "hidden",
    width: "100%",
    height: props.maxHeight ? "100%" : "auto"
  }),
  wrapper: {
    paddingTop: 80,
    paddingBottom: theme.spacing(7),
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    justifyContent: "center",
  },
  content: {
    flex: "none",
    height: "100%",
    width: "50%",
    overflow: "auto",
  },
}));

type Props = {
  title: string,
  containerOverflow?: string,
  children: React.Node,
  maxHeight?: boolean
};

const DefaultLayout = ({ title, children, maxHeight }: Props) => {
  const classes = useStyles({maxHeight});

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <TopBar />
      <Container maxWidth="lg" className={classes.wrapper}>
        {children}
      </Container>
    </div>
  );
};

export default DefaultLayout;
