import { useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";

const Logo = (props) => {
  const theme = useTheme();
  const isSmallPage = useMediaQuery(theme.breakpoints.down("xs"));
  
  return <img alt="Logo" src="/static/logo.png" height={isSmallPage ? 40 : 40} {...props} />;
};

export default Logo;
