import React from "react";
import { useTheme } from "@material-ui/core/styles";
import StepsList from "../components/StepsList";
import { getDefaultSteps } from "../services/api";
import { useMediaQuery } from "@material-ui/core";
import { useQuery } from "react-query";
import PageLoader from "../components/PageLoader";
import Fallback from "../components/Fallback";

type Props = {
  consultationId?: string,
  onError: Function,
  reason: string
};

export default function SteppersView(props: Props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const { data: steps, status, error } = useQuery("steps", () => getDefaultSteps(props.reason), {
    onError: (err) => props.onError(err)
  });

  if (status === "loading") {
    return <PageLoader />;
  }

  if (status === "error") {
    return <Fallback error={error} />;
  }

  return (
    <StepsList
      steps={steps}
      orientation={matches ? "horizontal" : "vertical"}
    />
  );
}
