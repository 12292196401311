// @flow

import React from "react";
import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import { refillConsult } from "../services/api";
import useConsultMutation from "../hooks/useConsultMutation";
import DefaultLayout from "../components/DefaultLayout";
import { useTranslation } from "react-i18next";
import useConsult from "../hooks/useConsult";
import PageLoader from "../components/PageLoader";
import { FallbackPage } from "./FallbackPage";
import { getMedicineItem, getLastPaidOrder } from "../services/order";
import type { Order } from "../models";
import TreatmentDetails from "../components/TreatmentDetails";
import OrderSummary from "../components/OrderSummary";
import Seperator from "../components/Seperator";
import SubmitButton from "../components/SubmitButton";
import { getLocalPath } from "../common/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
  },
  content: {
    marginTop: theme.spacing(2),
    margin: "auto",
  },
  title: {
    marginBottom: theme.spacing(6),
  },
  submit: {
    marginTop: theme.spacing(6)
  }
}));

export default function RefillPage() {
  const { id } = useParams();
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const refill = () => {
    return refillConsult(id);
  };

  const [refillMutate, { isLoading }] = useConsultMutation(id, refill, {
    onSuccess: () => {
      navigate(getLocalPath(`/consultations/${id}`));
    }
  });

  const { data: consultation, status, error } = useConsult(id);

  if (status === "loading") {
    return <PageLoader />;
  }

  if (status === "error") {
    return <FallbackPage error={error} />;
  }

  const order: ?Order = getLastPaidOrder(consultation.orders);

  if (!order || !order.items || !order.items.length) {
    return <FallbackPage error={new Error("Order does not exist")} />;
  }

  const handleRefill = async () => {
    await refillMutate();
  }
  
  const item = getMedicineItem(order);
  const newOrder: Order = Object.assign({}, order);
  newOrder.items = item ? [ item ] : [];

  return (
    <DefaultLayout title={t("refill")}>
      <Container maxWidth="sm" className={classes.root}>
        <Grid container className={classes.content} direction="column">
          <Typography variant="h2" component="h2" className={classes.title}>
            {t("refill.title")}
          </Typography>
          <TreatmentDetails consultation={consultation} />
          <Seperator />
          <OrderSummary order={newOrder} showSlogan={true} />
          <SubmitButton
            className={classes.submit}
            text={t("refill.action")}
            disabled={isLoading}
            onClick={handleRefill}
          />
        </Grid>
      </Container>
    </DefaultLayout>
  );
}
