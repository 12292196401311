import React from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
  Outlet,
} from "react-router-dom";

import DoctorHomePage from "./views/DoctorHomePage";
import EntryPage from "./views/EntryPage";
import PatientHomePage from "./views/PatientHomePage";
import ConsultationPage from "./views/ConsultationPage";
import PrivateRoute from "./common/PrivateRoute";
import LoginPage from "./views/LoginPage";
import RegisterPage from "./views/RegisterPage";
import AssessmentPage from "./views/AssessmentPage";
import WelcomePage from "./views/WelcomePage";
import ShippingDetailsPage from "./views/ShippingDetailsPage";
import AdminHomePage from "./views/AdminHomePage";
import Role from "./models/role";
import PermissionDeniedPage from "./views/PermissionDeniedPage";
import CheckoutPage from "./views/CheckoutPage";
import VerificationPage from "./views/VerificationPage";
import { useAuth } from "./providers/AuthProvider";
import ForgetPasswordPage from "./views/ForgetPasswordPage";
import ChangePasswordPage from "./views/ChangePasswordPage";
import PaymentReturnPage from "./views/PaymentReturnPage";
import { getLanguage } from "./common/utils";
import OrderCompletePage from "./views/OrderCompletePage";
import RefillPage from "./views/RefillPage";

const LocalisedRoute = () => {
  const location = useLocation();
  const { pathname } = location;
  const params = useParams();

  const { lang } = params;
  const language = getLanguage(lang);

  if (!pathname.includes(`/${language}/`)) {
    return <Navigate to={`/${language}/`} />;
  }

  return <Outlet />;
};

const RedirectLocalisedRoute = () => {
  const location = useLocation();
  const { pathname, search } = location;
  const { user } = useAuth();
  const language = (user && user.language) || getLanguage("");

  return <Navigate to={`/${language}/${pathname}${search}`} />;
}

export default function RouteConfig() {
  return (
    <Routes>
      <Route path="/:lang" element={<LocalisedRoute />}>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/change-password" element={<ChangePasswordPage />} />
        <Route path="/forget-password" element={<ForgetPasswordPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/entry" element={<EntryPage />} />
        <Route path="/entry/:type" element={<WelcomePage />} />
        <PrivateRoute path="/403" element={<PermissionDeniedPage />} />
        <PrivateRoute path="/home" element={<PatientHomePage />} />
        <PrivateRoute
          path={"/consultations/:id"}
          element={<ConsultationPage />}
        />
        <PrivateRoute
          path={"/consultations/:id/shipping"}
          element={<ShippingDetailsPage />}
        />
        <PrivateRoute
          path={"/consultations/:id/checkout"}
          element={<CheckoutPage />}
        />
        <PrivateRoute
          path={"/consultations/:id/payment-return"}
          element={<PaymentReturnPage />}
        />
        <PrivateRoute
          path={"/consultations/:id/payment-complete"}
          element={<OrderCompletePage />}
        />
        <PrivateRoute
          path={"/consultations/:id/assessment"}
          element={<AssessmentPage />}
        />
        <PrivateRoute
          path={"/consultations/:id/verifyid"}
          element={<VerificationPage />}
        />
        <PrivateRoute
          path={"/consultations/:id/refill"}
          element={<RefillPage />}
        />
        <PrivateRoute
          path="/doctor"
          roles={[Role.DOCTOR, Role.ADMIN]}
          element={<DoctorHomePage />}
        />
        <PrivateRoute
          path="/admin"
          roles={[Role.ADMIN]}
          element={<AdminHomePage />}
        />
      </Route>
      <Route path="/" element={<RedirectLocalisedRoute />} />
      <Route path="/login" element={<RedirectLocalisedRoute />} />
      <Route path="/register" element={<RedirectLocalisedRoute />} />
      <Route path="/consultations/*" element={<RedirectLocalisedRoute />} />
      <Route path="/entry/*" element={<RedirectLocalisedRoute />} />
      <Route path="/change-password" element={<RedirectLocalisedRoute />} />
      <Route path="/forget-password" element={<RedirectLocalisedRoute />} />
    </Routes>
  );
}
