
import type { SelectOption } from "../models";

const AUStates: SelectOption = [
  {
    label: "NSW | New South Wales",
    value: "NSW"
  },
  {
    label: "QLD | Queensland",
    value: "QLD"
  },
  {
    label: "SA | South Australia",
    value: "SA"
  },
  {
    label: "TAS | Tasmania",
    value: "TAS"
  },
  {
    label: "VIC | Victoria",
    value: "VIC"
  },
  {
    label: "WA | Western Australia",
    value: "WA"
  },
  {
    label: "ACT | Australian Capital Territory",
    value: "ACT"
  },
  {
    label: "NT | Northern Territory",
    value: "NT"
  }
];

export default AUStates;