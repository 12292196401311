// @flow
import React from "react";
import { createMarkup } from "../common/utils";
import { makeStyles } from "@material-ui/core";

type Props = {
  htmlStr: string,
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& ul": {
      marginLeft: theme.spacing(4),
    },
    "& li": {
      marginBottom: theme.spacing(2),
    },
    "& h4": {
      marginBottom: theme.spacing(4),
    },
    "& p": {
      marginBottom: theme.spacing(4),
    },
    "& p:last-of-type": {
      marginBottom: 0,
    },
  },
}));

const HtmlComponent = (props: Props) => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      dangerouslySetInnerHTML={createMarkup(props.htmlStr)}
    />
  );
};

export default HtmlComponent;
