// @flow

import React from "react";
import type { Node } from "react";
import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  sectionContent: {
    paddingLeft: 0,
  },
  contentRoot: {
    "&:last-child": {
      paddingBottom: 0,
    },
  },
}));

type SectionProps = {
  children: Node,
  title: string,
};

export default function CheckoutSection({ children, title }: SectionProps) {
  const classes = useStyles();

  return (
    <Card className={classes.section} elevation={0}>
      <CardContent
        className={classes.sectionContent}
        classes={{ root: classes.contentRoot }}
      >
        <Typography variant="h3" component="div" gutterBottom>
          {title}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
}
