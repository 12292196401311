// @flow

import * as React from "react";

import { Button, Grid } from "@material-ui/core";

import type { ConsultationActionModel } from "../models";

type Props = {
  isLoading: boolean,
  consultationAction: ConsultationActionModel,
};

export default function ConsultationTaskFormAction({
  isLoading,
  consultationAction,
}: Props) {
  if (consultationAction.type === "custom" && consultationAction.render) {
    return consultationAction.render();
  }

  if (consultationAction.actions && consultationAction.type === "default") {
    return (
      <Grid container direction="row" spacing={6}>
        {consultationAction.actions.map((action, index) => (
          <Grid item key={`item_${index}`}>
            <Button
              onClick={action.onClick}
              disableElevation
              color={action.primary ? "primary" : "default"}
              disabled={isLoading}
              variant={action.primary ? "contained" : "text"}
              size="small"
            >
              {action.label}
            </Button>
          </Grid>
        ))}
      </Grid>
    );
  }

  return null;
}
