// @flow

import * as React from "react";
import { useTranslation } from "react-i18next";

import { Typography, Divider, makeStyles } from "@material-ui/core";

import type { NestedComment } from "../models";

import CommentItem from "./CommentItem";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    height: "0.5px",
  },
  commentListContainer: {
    marginTop: theme.spacing(4),
  },
}));

type Props = {
  comments: Array<NestedComment>,
  onCommentCreate: Function,
  onSetAsAnswered: Function,
};

export default function CommentList({
  comments,
  onCommentCreate,
  onSetAsAnswered,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const RenderNoComment = () => {
    return (
      <Typography
        className={classes.noCommentsMessage}
        variant="body2"
        component="div"
        color="textSecondary"
      >
        {t("comment.nocomments")}
      </Typography>
    );
  };

  if (!comments.length) {
    return <RenderNoComment />;
  }

  return (
    <div className={classes.commentListContainer}>
      {comments.map((comment: NestedComment, index: number) => (
        <React.Fragment key={comment.id}>
          <CommentItem
            comment={comment}
            onCommentAnswer={onCommentCreate}
            onSetAsAnswered={onSetAsAnswered}
            key={`comment_${index}`}
          ></CommentItem>
          {comments.length - 1 !== index ? (
            <Divider className={classes.divider} />
          ) : null}
        </React.Fragment>
      ))}
    </div>
  );
}
