// @flow

import { useQuery } from "react-query";
import { getConsultation } from "../services/api";

type Options = {
  onSuccess?: Function,
  onError?: Function,
};

const getConsultationById = async (consultationId: string) => {
  if (consultationId === "invalid") {
    return null;
  }
  return await getConsultation(consultationId);
};

export default function useConsult(consultationId: string, opts?: Options) {
  return useQuery(
    `consultation-${consultationId}`,
    () => getConsultationById(consultationId),
    {
      onSuccess: (data) => {
        if (opts && opts.onSuccess) {
          opts.onSuccess(data);
        }
      },
    }
  );
}
