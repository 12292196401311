// @flow

import type { Category } from '.';

export const defaultCategory: Category = {
  id: "hairloss",
  name: "Hair Loss",
  description: "",
  color: "blue",
  imageUrl: "",
};