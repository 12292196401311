// @flow

import type {
  Consultation,
  ConsultationTask,
  TaskModel,
  TaskContent,
  TaskContentSection,
} from "../models";
import { Tasks } from "../models/enums";
import { defaultTask } from "../models/helpers";
import { CheckoutTasks, PaymentTasks, ShippingTasks } from "../models/tasks";

export function getCurrentTask(consultation: Consultation): ConsultationTask {
  if (!consultation || !consultation.tasks) {
    return defaultTask;
  }

  // TODO: might need to return entire task list
  return consultation.tasks[0];
}

function toTask(id: string): TaskModel {
  for (let key in Tasks) {
    if (Tasks[key] === id) {
      return Tasks[key];
    }
  }

  return Tasks.DEFAULT;
}

export function getTask(consultation: Consultation): TaskModel {
  if (!consultation.tasks || !consultation.tasks.length) {
    return Tasks.DEFAULT;
  }

  return toTask(getCurrentTask(consultation).id);
}

function containTask(
  consultation: Consultation,
  taskList: Array<string>
): boolean {
  if (!consultation.tasks || !consultation.tasks.length) {
    return false;
  }

  const index = consultation.tasks.findIndex(
    (task) => taskList.indexOf(task.id) > -1
  );

  return index > -1;
}

export function hasCheckoutTask(consultation: Consultation): boolean {
  return containTask(consultation, CheckoutTasks);
}

export function hasPaymentTask(consultation: Consultation): boolean {
  return containTask(consultation, PaymentTasks);
}

export function hasShippingTask(consultation: Consultation): boolean {
  return containTask(consultation, ShippingTasks);
}

export function getCurrentTaskName(consultation: Consultation): string {
  if (!consultation || !consultation.tasks || !consultation.tasks.length) {
    return "Invalid";
  }

  return getCurrentTask(consultation).name || "";
}

export function getCurrentTaskId(consultation: Consultation): string {
  if (!consultation || !consultation.tasks || !consultation.tasks.length) {
    return "";
  }

  return getCurrentTask(consultation).id || "";
}

export function getContentSections(
  contents: Array<TaskContent>
): Array<TaskContentSection> {
  const sections: Array<TaskContentSection> = [];

  contents.forEach((content) => {
    const sectionIndex = sections.findIndex(
      (section) => section.type === content.type
    );

    if (sectionIndex === -1) {
      sections.push({ type: content.type, contents: [content] });
    } else {
      sections[sectionIndex].contents.push(content);
    }
  });

  return sections;
}
