// @flow

import type { Comment, NestedComment, User, TaskModel } from "../models";
import { defaultUser } from "../models/helpers";
import Tasks from "../models/tasks";

export function getNestedComments(
  comments: Array<Comment>,
  users: Array<User>
): Array<NestedComment> {
  if (!comments || !comments.length || !users || !users.length) {
    return [];
  }

  let nestedComments: Array<NestedComment> = [];

  let childComments: Array<NestedComment> = [];
  let parentComments: Array<NestedComment> = [];

  nestedComments = comments
    .filter((comment) => {
      const user = users.find((usr) => usr.id === comment.userId);

      if (!user) {
        return false;
      }

      return true;
    })
    .map((comment) => {
      const user: User =
        users.find((usr) => usr.id === comment.userId) || defaultUser;

      const newComment: NestedComment = {
        id: comment.id,
        type: comment.type,
        userId: comment.userId,
        userName: user.displayName,
        isPatient: user.patient,
        isDoctor: user.doctor,
        userPicture: user.pictureUrl,
        text: comment.text,
        updatedAt: comment.updatedAt,
        parentId: comment.parentId,
        isAnswered: comment.isAnswered,
      };

      return newComment;
    });

  nestedComments.forEach((comment) => {
    if (comment.parentId !== null) {
      childComments.push(comment);
    } else {
      parentComments.push(comment);
    }
  });

  childComments.forEach((child) => {
    const parent = parentComments.find(
      (parent) => parent.id === child.parentId
    );

    if (parent) {
      parent.child = child;
    }
  });

  return parentComments;
}

export function canCreateComment(user: User, task: TaskModel): boolean {
  if (user.patient && task === Tasks.ACCEPT_QUOTA) {
    return true;
  }

  if (
    user.doctor &&
    (task === Tasks.REVIEW_CONSULTATION || task === Tasks.WAIT_FOR_ANSWER)
  ) {
    return true;
  }

  return false;
}
