// @flow

import * as React from "react";
import { useTranslation } from "react-i18next";
import { Typography, makeStyles } from "@material-ui/core";
import { useAuth } from "../providers/AuthProvider";
import { useConsultation } from "../providers/ConsultationProvider";
import { isPatient } from "../models/helpers";

const useStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(4),
  },
}));

export default function RejectedConsultationPanel() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { consultation } = useConsultation();

  if (!isPatient(user)) {
    return (
      <Typography variant="body1" component="p" color="textSecondary">
        {t("consultation.rejected")}
      </Typography>
    );
  }

  return (
    <React.Fragment>
      <Typography
        variant="body2"
        component="div"
        color="textSecondary"
        className={classes.description}
      >
        {t("consultation.rejected.patient")}
      </Typography>

      <Typography variant="overline" component="div" className={classes.note} gutterBottom>
        {t("consultation.fromdoctor")}
      </Typography>

      <Typography variant="body2" component="div" className={classes.note} color="textSecondary" >
        {consultation.note}
      </Typography>
    </React.Fragment>
  );
}
