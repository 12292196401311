// @flow
import * as React from 'react';
import { useState, useCallback } from 'react';

type ErrorContextProps = {
  error: any,
  addError: () => void,
  removeError: () => void
}

export const APIErrorContext = React.createContext<ErrorContextProps>({
  error: null,
  addError: () => { },
  removeError: () => { }
});

type Props = {
  children?: React.Node,
};

export default function APIErrorProvider({ children }: Props) {
  const [error, setError] = useState(null);

  const removeError = () => {
    setError(null)
  };

  const addError = (message, status) => {
    setError({ message, status })
  };

  const contextValue = {
    error,
    addError: useCallback((message, status) => addError(message, status), []),
    removeError: useCallback(() => removeError(), [])
  };

  return (
    <APIErrorContext.Provider value={contextValue}>
      {children}
    </APIErrorContext.Provider>
  );
}
