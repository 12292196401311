//@flow

import type { User, RoleModel } from ".";

export const defaultUser: User = {
  id: "bruce",
  role: "PATIENT",
  firstName: "Bruce",
  lastName: "Li",
  displayName: "Bruce Li",
  email: "bli@test.com",
  admin: false,
  doctor: false,
  patient: true
};

// Does user has role in roles?
export function hasRole(user: User, roles: Array<RoleModel>) {
  if (!roles) return true;

  return roles.indexOf(user.role) > -1;
}

export function isPatient(user: User) {
  return user.patient;
}

export function isDoctor(user: User) {
  return user.doctor;
}

export function isAdmin(user: User) {
  return user.admin;
}