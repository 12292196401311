// @flow
import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import HtmlComponent from "./HtmlComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(2),
  },
}));

type Props = {
  note: string,
};

export default function Letter({ note }: Props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <HtmlComponent htmlStr={note} />
      </Box>
    </React.Fragment>
  );
}
