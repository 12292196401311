// @flow

import React, { useState } from "react";
import usePopNotifications from "../hooks/usePopNotifications";
import { submitTask } from "../services/api";
import type { Consultation } from "../models";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useConsult from "../hooks/useConsult";
import useConsultMutation from "../hooks/useConsultMutation";
import { CompleteCheckoutPrescriptionTaskRequest } from "../models/tasks";
import { getUnpaidOrder } from "../services/order";
import { useTranslation } from "react-i18next";
import PageLoader from "../components/PageLoader";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import ReplayIcon from "@material-ui/icons/Replay";
import { getLocalPath } from "../common/utils";
import ResultLayout from "../components/ResultLayout";
import type { VerifyStatusModel, Order } from "../models";
import { VerifyStatus } from "../models/enums";
import { FallbackPage } from "./FallbackPage";
import { SUPPORT_EMAIL } from "../constants";

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  subtitle: {
    marginBottom: theme.spacing(7),
  },
}));

const useIconStyles = makeStyles((theme) => ({
  icon: (props) => ({
    zIndex: 1,
    width: 100,
    height: 100,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: props.color,
  }),
}));

export default function PaymentReturnPage() {
  const { id } = useParams();
  const [query] = useSearchParams();
  const isReturnSuccss = query.get("success");
  const isReturnCancel = query.get("canceled");
  const { setNotification } = usePopNotifications();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [currentStatus, setCurrentStatus] = useState(VerifyStatus.VERIFYING);
  // eslint-disable-next-line no-unused-expressions
  (currentStatus: VerifyStatusModel);

  const [mutate, mutateState] = useConsultMutation(
    id,
    ({ orderId }) => {
      return submitTask(id, CompleteCheckoutPrescriptionTaskRequest(orderId));
    },
    {
      onSuccess: () => {
        setCurrentStatus(VerifyStatus.SUCCESS);
      },
      onError: (err) => {
        setNotification(err.message, "error");
      },
    }
  );

  const { status, error } = useConsult(id, {
    onSuccess: (consultation: Consultation) => {
      const order: ?Order = getUnpaidOrder(consultation.orders);

      if (!order) {
        setCurrentStatus(VerifyStatus.SUCCESS);

        return;
      }

      if (isReturnSuccss) {
        return mutate({ orderId: order.id });
      }

      if (isReturnCancel) {
        setCurrentStatus(VerifyStatus.CANCEL);

        return navigate(getLocalPath(`/consultations/${id}`));
      }

      setCurrentStatus(VerifyStatus.FAILED);
    },
  });

  if (status === "error") {
    return <FallbackPage error={error} />;
  }

  if (status === "loading") {
    return <PageLoader />;
  }

  const isVerifying = currentStatus === VerifyStatus.VERIFYING;
  const isFailed = currentStatus === VerifyStatus.FAILED;
  const isSuccess = currentStatus === VerifyStatus.SUCCESS;
  const isCancel = currentStatus === VerifyStatus.CANCEL;

  const Title = () => {
    if (isVerifying) {
      return t("payment.verifying");
    }

    if (isFailed) {
      return t("payment.failed");
    }

    if (isSuccess) {
      return t("payment.success");
    }

    if (isCancel) {
      return t("payment.cancel");
    }

    return null;
  };

  const SubTitle = () => {
    if (isVerifying) {
      return t("payment.verifying.subtitle");
    }

    if (isFailed) {
      return t("payment.failed.subtitle");
    }

    if (isSuccess) {
      return t("payment.success.subtitle");
    }

    if (isCancel) {
      return t("payment.cancel.subtitle");
    }

    return null;
  };

  const IconArea = ({ icon, color }) => {
    const iconClasses = useIconStyles({ color });

    return (
      <Grid item className={iconClasses.icon}>
        {icon}
      </Grid>
    );
  };

  const StatusIcon = () => {
    if (isVerifying) {
      return (
        <IconArea
          icon={<HourglassEmptyIcon fontSize="large" />}
          color="#FFBC32"
        />
      );
    }

    if (isFailed) {
      return <IconArea icon={<ErrorIcon fontSize="large" />} color="#FF5C5C" />;
    }

    if (isSuccess) {
      return <IconArea icon={<CheckIcon fontSize="large" />} color="#22CF7B" />;
    }

    if (isCancel) {
      return (
        <IconArea icon={<ReplayIcon fontSize="large" />} color="#FFBC32" />
      );
    }

    return null;
  };

  const getLabel = () => {
    if (isFailed) {
      return t("contact.us");
    }

    return t("continue");
  };

  return (
    <ResultLayout title={t("payment.return")}>
      <StatusIcon />
      <Typography variant="h2" component="h1" className={classes.title}>
        <Title />
      </Typography>
      <Typography variant="body1" component="p" className={classes.subtitle}>
        <SubTitle />
      </Typography>
      <Button
        color="primary"
        variant="contained"
        href={isFailed ? `mailto:${SUPPORT_EMAIL}` : null}
        disabled={mutateState.isLoading || isVerifying}
        onClick={() =>
          isCancel
            ? navigate(getLocalPath(`/consultations/${id}`))
            : navigate(getLocalPath(`/consultations/${id}/payment-complete`))
        }
      >
        {getLabel()}
      </Button>
    </ResultLayout>
  );
}
