// @flow

// only work for patient
import { Grid, makeStyles } from "@material-ui/core";
import * as React from "react";
import { useConsultation } from "../providers/ConsultationProvider";
import DoctorNote from "./DoctorNote";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginBottom: theme.spacing(7),
  },
  note: {
    marginBottom: theme.spacing(7),
  },
}));
type Props = {
  description?: string,
};

export default function ConsultationNoChangePanel({ description }: Props) {
  const classes = useStyles();
  const { consultation } = useConsultation();

  return (
    <Grid container direction="column">
      <Grid item className={classes.note}>
        <DoctorNote note={consultation.note || ""} />
      </Grid>
    </Grid>
  );
}
