import React from "react";

import {
  Card,
  Typography,
  Button,
  CardActions,
  CardContent,
  makeStyles,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";

import type { Consultation, ConsultationTask } from "../models";
import { getCurrentTask, getCurrentTaskName } from "../services/task";
import { formatTimeDistance } from "../common/utils";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${theme.palette.background.default}`,
    "&:hover": {
      border: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  cardContent: {
    flexGrow: 1,
    paddingBottom: 0,
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  task: {
    marginBottom: theme.spacing(4),
  },
}));

type Props = {
  onSelect: Function,
  consultation: Consultation,
};

export default function ConsultationPreview(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { reason, category } = props.consultation;
  const task: ConsultationTask = getCurrentTask(props.consultation);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Typography
          gutterBottom
          variant="h4"
          component="h3"
          className={classes.title}
        >
          {`${category.name} (${reason.name})`}
        </Typography>
        <Typography
          variant="body2"
          color="textPrimary"
          component="p"
          className={classes.task}
        >
          {getCurrentTaskName(props.consultation)}
        </Typography>
        <Typography variant="caption" color="textSecondary" component="p">
          {formatTimeDistance(props.consultation.updatedAt)}
        </Typography>
      </CardContent>

      <CardActions>
        {task ? (
          <Button size="small" color="primary" onClick={() => props.onSelect()}>
            {task.action || t("view")}
          </Button>
        ) : null}
      </CardActions>
    </Card>
  );
}
