// @flow

import * as React from "react";
import { createContext, useState, useCallback, useMemo } from "react";

import type {
  Severities,
  PopNotificationObject,
} from "../models/pop_notification";

type PopNotificationContextProps = {
  notification: ?PopNotificationObject,
  setNotification: (message: string, severity: Severities) => void,
  removeNotification: () => void,
};

type Props = {
  children?: React.Node,
};

export const PopNotificationContext = createContext<PopNotificationContextProps>(
  {
    notification: null,
    setNotification: () => {},
    removeNotification: () => {},
  }
);

export function PopNotificationProvider(props: Props): React.Node {
  const [notification, setNotificationState] = useState<
    PopNotificationObject & Function
  >(null);

  const setNotification = useCallback(
    (message: string | null, severity: ?Severities): void => {
      if (message && message.length && severity && severity.length) {
        setNotificationState({ message, severity });
      } else if (severity === ("success" || "info" || "warning" || "error")) {
        throw new Error(
          "The setNotification hook's severities must be either 'success', 'info', 'warning' or 'error'."
        );
      } else {
        throw new Error("The setNotification hook has required parameters.");
      }
    },
    []
  );

  const removeNotification = useCallback((): void => {
    setNotificationState(null);
  }, []);

  const contextValue = useMemo(
    () => ({ notification, setNotification, removeNotification }),
    [notification, setNotification, removeNotification]
  );

  return (
    <PopNotificationContext.Provider value={contextValue}>
      {props.children}
    </PopNotificationContext.Provider>
  );
}
