// @flow

import * as React from "react";
import { useTranslation } from "react-i18next";

import PermIdentityIcon from "@material-ui/icons/PermIdentity";

import type { Comment, TaskModel, MutationAction } from "../models";
import {
  AnswerQuestionTaskRequest,
  AskForChangeTaskRequest,
} from "../models/tasks";
import { ReviewType, Tasks } from "../models/enums";

import ConsultationSection from "../components/ConsultationSection";
import CommentEdit from "../components/CommentEdit";

import { reviewConsult, submitTask } from "../services/api";
import { canCreateComment, getNestedComments } from "../services/comment";
import { getTask } from "../services/task";

import { useConsultation } from "../providers/ConsultationProvider";
import { useAuth } from "../providers/AuthProvider";

import useComments, { useCommentMutation } from "../hooks/useComments";
import useConsultMutation from "../hooks/useConsultMutation";

import CommentList from "../components/CommentList";
import PageLoader from "../components/PageLoader";
import Fallback from "../components/Fallback";

type Props = {};

export default function CommentListView(props: Props) {
  const { t } = useTranslation();
  const {
    consultation,
    focusCommentEdit,
    updated,
    setUpdated,
  } = useConsultation();
  const {
    data: comments,
    status: commentStatus,
    error: commentError,
  } = useComments(consultation.id);
  const { user } = useAuth();
  const task: TaskModel = getTask(consultation);
  // prettier-ignore
  const [mutationAction, setMutationAction] = React.useState<MutationAction>("");

  const taskSubmitHandler = (newComment: Comment) => {
    let newCommentId: number;

    if (newComment.id === undefined) {
      throw new Error("Comment ID is undefined.");
    } else {
      newCommentId = newComment.id;
    }

    if (newComment.type === "COMMENT") {
      return submitTask(
        consultation.id,
        AskForChangeTaskRequest(newCommentId, newComment.text)
      );
    }

    if (user.doctor && !newComment.parentId && task !== Tasks.WAIT_FOR_ANSWER) {
      return reviewConsult(consultation.id, {
        type: ReviewType.QUESTION,
        commentId: newCommentId
      });
    } else if (user.patient && newComment.parentId) {
      return submitTask(
        consultation.id,
        AnswerQuestionTaskRequest(newCommentId, newComment.text)
      );
    }
  };

  const [consultMutate] = useConsultMutation(
    consultation.id,
    taskSubmitHandler,
    {
      successText: user.patient ? t("message.message.confirm") : "",
      onSuccess: () => {
        setUpdated(!updated);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const [mutateComments, mutateCommentsState] = useCommentMutation(
    consultation.id,
    mutationAction,
    {
      onSuccess: (newComment: Comment) => {
        return consultMutate(newComment);
      },
    }
  );

  const users = [consultation.creator].concat(
    consultation.assignee ? consultation.assignee : []
  );

  if (commentStatus === "loading" || mutateCommentsState.isLoading) {
    return <PageLoader />;
  }

  if (commentStatus === "error" || mutateCommentsState.isError) {
    return <Fallback error={commentError || mutateCommentsState.error} />;
  }

  const nestedComments = getNestedComments(comments, users);

  const getCurrentUserAvatarIcon = () => {
    // TODO: check current user group
    return <PermIdentityIcon />;
  };

  const handleCommentPost = async (
    textFieldValue: string,
    commentParentId: number
  ) => {
    const commentToPost: Comment = {
      consultationId: consultation.id,
      type: commentParentId ? "ANSWER" : "QUESTION",
      text: textFieldValue,
      parentId: commentParentId ? commentParentId : null,
    };

    if (task === Tasks.ACCEPT_QUOTA && user.patient) {
      commentToPost.type = "COMMENT";
    }

    setMutationAction("post");

    return await mutateComments(commentToPost);
  };

  const handleSetAsAnswered = async (commentId: number) => {
    setMutationAction("put");

    return await mutateComments({
      commentId: commentId,
      commentUpdateRequest: { isAnswered: true },
    });
  };

  return (
    <ConsultationSection
      title={t("consultation.comments")}
      defaultExpanded={true}
    >
      {canCreateComment(user, task) ? (
        <CommentEdit
          canUploadImage={true}
          onSaveEdit={handleCommentPost}
          willAutoFocus={focusCommentEdit}
          scrollIntoView={focusCommentEdit}
          avatar={getCurrentUserAvatarIcon()}
        />
      ) : null}
      <CommentList
        comments={nestedComments}
        onCommentCreate={handleCommentPost}
        onSetAsAnswered={handleSetAsAnswered}
      />
    </ConsultationSection>
  );
}
