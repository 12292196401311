// @flow

import React from "react";
import { Grid, makeStyles, Typography, Box } from "@material-ui/core";
import { useQuery } from "react-query";
import PageLoader from "../components/PageLoader";
import Fallback from "../components/Fallback";
import ConsultationPreview from "../components/ConsultationPreview";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(6)
  },
}));

type Props = {
  viewId: string,
  fetch: Function,
  onSelect: Function,
  title: string
};

export default function ConsultationTileListView(props: Props) {
  const classes = useStyles();
  const { data: consultations, status, error } = useQuery(`consultations-${props.viewId}`, props.fetch);

  if (status === "loading") {
    return <PageLoader />;
  }

  if (status === "error") {
    return <Fallback error={error} />;
  }

  if (!consultations.length) {
    return null;
  }

  return (
    <React.Fragment>
      <Box className={classes.sectionTitle}>
        <Typography component="h1" variant="h3" color="inherit" gutterBottom>
          {props.title}
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {consultations.map((consultation) => (
          <Grid item key={consultation.id} xs={12} sm={6} md={3}>
            <ConsultationPreview
              consultation={consultation}
              onSelect={() => props.onSelect(consultation)}
            />
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}
