// @flow

import type { TaskRequest, KeyValue } from "./";

const Tasks = {
  DEFAULT: "default",
  QUESTIONNAIRE: "take_questionnaire",
  CHECKOUT_ADMINISTRATION: "checkout_administration",
  WAIT_ASSIGNMENT: "waiting_for_assignment",
  CONSULTATION: "wait_for_doctor_response",
  WAIT_FOR_ANSWER: "wait_for_answer",
  ANSWER_QUESTIONS: "answer_questions",
  ACCEPT_QUOTA: "accept_quota",
  CHECKOUT_PRESCRIPTION: "checkout_prescription",
  CONFIRM_ORDER: "confirm_order",
  REVIEW_CONSULTATION: "review_consultation",
  ASSIGN_CONSULTATION: "assign_consultation",
  VERIFY_ID: "verify_identification",
  WAIT_DELIVERAY: "wait_for_delivery",
  COMPLETED: "completed",
  APPROVED: "approved",
  CANCELED: "canceled",
  REJECTED: "rejected",
  NOCHANGE: "nochange",
};

export const CheckoutTasks = [
  Tasks.CHECKOUT_ADMINISTRATION,
  Tasks.CHECKOUT_PRESCRIPTION,
];

export const PaymentTasks = [
  Tasks.CHECKOUT_ADMINISTRATION,
  Tasks.CHECKOUT_PRESCRIPTION,
];

export const ShippingTasks = [Tasks.CHECKOUT_PRESCRIPTION];

export const AnswerQuestionTaskRequest = (
  commentId: ?number,
  commentText: ?string
): TaskRequest => {
  const vars: Array<KeyValue> = [
    {
      key: "comment_id",
      value: commentId ? commentId.toString() : null,
    },
    {
      key: "comment_text",
      value: commentText ? commentText : null,
    },
  ];

  return { id: Tasks.ANSWER_QUESTIONS, vars };
};

export const AskForChangeTaskRequest = (
  commentId: ?number,
  commentText: ?string
): TaskRequest => {
  const vars: Array<KeyValue> = [
    {
      key: "review_decision",
      value: "change",
    },
    {
      key: "comment_id",
      value: commentId ? commentId.toString() : null,
    },
    {
      key: "comment_text",
      value: commentText ? commentText : null,
    },
  ];

  return { id: Tasks.ACCEPT_QUOTA, vars };
};

export const AcceptQuotaTaskRequest = (): TaskRequest => ({
  id: Tasks.ACCEPT_QUOTA,
  vars: [{ key: "review_decision", value: "accepted" }],
});

export const VerifyIdentityTaskRequest = (code: string): TaskRequest => ({
  id: Tasks.VERIFY_ID,
  vars: [{ key: "code", value: code }],
});

export const SubmitQuestionnaireTaskRequest = (): TaskRequest => ({
  id: Tasks.QUESTIONNAIRE,
});

export const CompleteCheckoutAdministrationTaskRequest = (
  order: string,
  paymentId: string
): TaskRequest => {
  const vars: Array<KeyValue> = [
    { key: "order_id", value: order },
    { key: "payment_key", value: paymentId },
  ];

  return { id: Tasks.CHECKOUT_ADMINISTRATION, vars };
};

export const CompleteCheckoutPrescriptionTaskRequest = (
  order: string
): TaskRequest => {
  const vars: Array<KeyValue> = [{ key: "order_id", value: order }];

  return { id: Tasks.CHECKOUT_PRESCRIPTION, vars };
};

export default Tasks;
