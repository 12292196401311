// @flow

// only work for patient
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { useConsultation } from "../providers/ConsultationProvider";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import OrderSummary from "./OrderSummary";
import type { Order, Summary } from "../models";
import { getPaidOrders } from "../services/order";
import Seperator from "./Seperator";
import SummarySection from "./SummarySection";
import { getPreviewAddress } from "../services/address";
import DoctorNote from "./DoctorNote";
import CommentListView from "../views/CommentListView";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  orderDescription: {
    marginBottom: theme.spacing(2),
  },
  content: {
    margin: "auto",
  },
  action: {
    marginBottom: theme.spacing(7),
  },
  orderSummary: {
    marginTop: theme.spacing(6)
  }
}));

export default function ConsultationCompletePanel() {
  const { t } = useTranslation();
  const [redirectUrl] = React.useState(null);
  const { consultation } = useConsultation();
  const classes = useStyles();
  const orders: Array<Order> = getPaidOrders(consultation.orders);

  if (redirectUrl) {
    return <Navigate push to={redirectUrl} />;
  }

  const getAddressSummaries = (address): Array<Summary> => {
    return [
      {
        title: `${t("shipping.address")}:`,
        description: getPreviewAddress(address),
      },
      {
        title: `${t("contact.number")}:`,
        description: address.phone,
      },
    ];
  };

  return (
    <React.Fragment>
      <Grid container className={classes.content} direction="column">
        <Seperator />
        {orders.map((order) => (
          <>
            <Grid item className={classes.orderDescription}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="div"
              >
                {t("order.complete.description")}
              </Typography>
            </Grid>
            <SummarySection title={t("order.id")} description={order.id} />
            <SummarySection
              title={t("order.date")}
              description={format(new Date(order.updatedAt), "dd/MM/yyyy HH:mm")}
            />
            <Grid item className={classes.orderSummary}>
              <OrderSummary order={order} />
            </Grid>
            {order.address && (
              <>
                <Grid item>
                  <Typography
                    variant="h3"
                    component="h2"
                    className={classes.title}
                  >
                    {t("shipping.title")}
                  </Typography>
                  {getAddressSummaries(order.address).map((summary, i) => (
                    <SummarySection
                      key={`section_${i}`}
                      description={summary.description}
                      renderImg={summary.img}
                      title={summary.title}
                    />
                  ))}
                </Grid>
                <Seperator />
              </>
            )}
          </>
        ))}
        <Grid item>
          <DoctorNote note={consultation.note} />
        </Grid>
        <Grid item>
          <CommentListView />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
