// @flow
import React from "react";
import { Typography, Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import UnassignedConsultationView from "./UnassignedConsultationView";
import DefaultLayout from "../components/DefaultLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}));

export default function AdminHomePage() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <DefaultLayout title={t("page.adminhome")}>
      <Grid className={classes.root}>
        <Typography variant="h2" component="h2" gutterBottom>
          {t("consultations.notassigned")}
        </Typography>
        <UnassignedConsultationView />
      </Grid>
    </DefaultLayout>
  );
}
