// @flow

import { useMutation, useQuery, useQueryCache } from "react-query";
import { findAnswers, findPersonalAnswers } from "../services/api";
import type { Consultation } from "../models";

export default function useAnswers(consultationId: string) {
  const fetch = async () => {
    return await findAnswers(consultationId);
  };

  return useQuery(`answers-${consultationId}`, fetch);
}

export function usePersonalAnswers(consultation: Consultation) {
  const fetch = async () => {
    return await findPersonalAnswers(consultation.id);
  };
  return useQuery(`answers-${consultation.id}-personal`, fetch);
}

export function useAnswerMutation(
  updateAnswer: Function,
  consultationId: string
) {
  const cache = useQueryCache();

  return useMutation(updateAnswer, {
    onSuccess: (newAnswer) => {
      const key = `answers-${consultationId}`;

      cache.setQueryData(key, (answers) => {
        const newAnswers = [...answers];

        const idx = newAnswers.findIndex(
          (a) => a.questionId === newAnswer.questionId
        );

        if (idx > -1) {
          newAnswers[idx] = newAnswer;
        } else {
          newAnswers.push(newAnswer);
        }

        return newAnswers;
      });
    },
  });
}
