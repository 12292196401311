// @flow

import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ConsultationSection from "../components/ConsultationSection";
import Fallback from "../components/Fallback";
import PageLoader from "../components/PageLoader";
import useNotes, { useNotesMutation } from "../hooks/useNotes";
import { useConsultation } from "../providers/ConsultationProvider";
import type { UserNote } from "../models";
import { formatTimeDistance } from "../common/utils";
import { createNote } from "../services/api";

const useStyles = makeStyles((theme) => ({
  inline: {
    display: "inline",
  },
  primaryText: {
    marginBottom: theme.spacing(2),
  },
  editbox: {
    marginBottom: theme.spacing(4),
  },
}));

export default function DoctorNotesView() {
  const { t } = useTranslation();
  const [inputError, setInputError] = useState(null);
  const classes = useStyles();
  const { consultation } = useConsultation();
  const ref = useRef("");
  const userId = consultation.creator.id;
  const { data: notes, status, error } = useNotes(userId);
  const [mutateNote, mutateNoteState] = useNotesMutation(
    (text) => createNote(userId, text),
    userId
  );

  if (status === "loading" || mutateNoteState.isLoading) {
    return <PageLoader />;
  }

  if (status === "error") {
    return <Fallback error={error} />;
  }

  const handleNoteSave = async () => {
    const text = ref.current && ref.current.value;

    if (!text || !text.length) {
      setInputError(t("consultation.notes.error.empty"));
    } else if (text.length < 10) {
      setInputError(t("consultation.notes.error.tooshort"));
    } else {
      setInputError(null);
      await mutateNote(text);
    }
  };

  return (
    <ConsultationSection title={t("consultation.notes")} defaultExpanded={true}>
      <List className={classes.list}>
        {notes.map((note: UserNote) => (
          <ListItem key={note.id}>
            <ListItemText
              classes={{ primary: classes.primaryText }}
              primary={note.note}
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    {note.doctor && note.doctor.displayName}
                  </Typography>
                  {` - ${formatTimeDistance(
                    note.updatedAt || new Date().toISOString()
                  )}`}
                </React.Fragment>
              }
            />
          </ListItem>
        ))}
      </List>
      <TextField
        inputRef={ref}
        error={Boolean(inputError)}
        helperText={inputError}
        className={classes.editbox}
        fullWidth
        label=""
        multiline
        rows={2}
        variant="outlined"
      />
      <Grid container justify="flex-start">
        <Button
          onClick={handleNoteSave}
          disableElevation
          color="primary"
          disabled={mutateNoteState.isLoading}
          variant="contained"
          size="small"
        >
          {t("save")}
        </Button>
      </Grid>
    </ConsultationSection>
  );
}
