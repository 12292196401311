import React, { useState, useRef } from "react";
import { useLocation, Link, useNavigate, Navigate } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Container,
  makeStyles,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAuth } from "../providers/AuthProvider";
import DefaultLayout from "../components/DefaultLayout";
import { getLocalPath, validateEmail, validatePassword } from "../common/utils";
import type { LoginAuthRequest } from "../models";
import SubmitButton from "../components/SubmitButton";
import { useMutation } from "react-query";
import usePopNotifications from "../hooks/usePopNotifications";
import { getHomeUrl } from "../services/auth";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(6),
  },
  inputfield: {
    marginBottom: theme.spacing(6),
  },
  submit: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  noaccount: {
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.main
  }
}));

export default function LoginPage() {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const { user, login } = useAuth();
  const [errors, setErrors] = useState({});
  const emailRef = useRef("");
  const passwordRef = useRef("");
  const { setNotification } = usePopNotifications();
  const navigate = useNavigate();

  const getValues = (): LoginAuthRequest => {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    return { email, password };
  };

  const [mutate, { isLoading }] = useMutation(login, {
    onSuccess: () => {
      setNotification(t("login.success"), "success");

      const { from } = location.state || {
        from: { pathname: getLocalPath("/") },
      };

      navigate(from);
    },
    onError: (err) => {
      setNotification(err.message, "error");
    },
  });

  const validate = (vals) => {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const newErrors = {};

    if (!validateEmail(email)) {
      newErrors.email = t("account.emailerror");
    }

    if (!email || !email.length) {
      newErrors.email = t("account.emptyemail");
    }

    if (!validatePassword(password)) {
      newErrors.password = t("account.passworderror");
    }

    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await mutate(getValues());
  };

  const handleChange = (e) => {
    validate();
  };

  const isValid = (): boolean => {
    const { email, password } = getValues();
    return Boolean(
      !errors.email && !errors.password && email && password && !isLoading
    );
  };

  if (user) {
    return <Navigate to={`${getHomeUrl(user)}`} />
  }

  return (
    <DefaultLayout title={t("login")}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="start"
        marginTop="10%"
      >
        <Container maxWidth="sm">
          <form>
            <Box mb={3} className={classes.title}>
              <Typography color="textPrimary" variant="h1">
                {t("login")}
              </Typography>
            </Box>
            <TextField
              className={classes.inputfield}
              error={Boolean(errors.email)}
              fullWidth
              helperText={errors.email}
              label={t("account.emaillabel")}
              margin="normal"
              name="email"
              type="email"
              variant="outlined"
              inputRef={emailRef}
              onBlur={handleChange}
              onChange={handleChange}
            />
            <TextField
              error={Boolean(errors.password)}
              fullWidth
              helperText={errors.password}
              label={t("account.passwordlabel")}
              margin="normal"
              name="password"
              type="password"
              variant="outlined"
              inputRef={passwordRef}
              onBlur={handleChange}
              onChange={handleChange}
            />
            <Box my={2} className={classes.submit}>
              <SubmitButton
                onClick={handleSubmit}
                text={t("login")}
                disabled={!isValid()}
                isLoading={isLoading}
                fullWidth={true}
              />
            </Box>
            <Button
              variant="text"
              color="primary"
              disableElevation
              disableRipple
              onClick={() => navigate(getLocalPath("/forget-password"))}
            >
              {t("login.forgetpassword")}
            </Button>
            <Typography variant="body2" className={classes.noaccount}>
              {t("account.noaccount")}
              <Link
                to={getLocalPath("/register")}
                variant="body2"
                className={classes.link}
                state={{ from: location.state ? location.state.from : "/" }}
              >
                {t("signup")}
              </Link>
            </Typography>
          </form>
        </Container>
      </Box>
    </DefaultLayout>
  );
}
