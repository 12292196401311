// @flow

import React, { useState, useEffect } from "react";
import Assessment from "../components/Assessment";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  createAnswer,
  updateAnswer,
  submitTask,
  uploadImage,
} from "../services/api";
import {
  Container,
  createMuiTheme,
  CssBaseline,
  makeStyles,
  Slide,
  ThemeProvider,
} from "@material-ui/core";
import DefaultLayout from "../components/DefaultLayout";
import { useGlobalState } from "../providers/StateProvider";
import {
  getCurrentQuestion,
  getQuestionTotal,
  isLastQuestion,
} from "../services/survey";
import { SubmitQuestionnaireTaskRequest } from "../models/tasks";
import useQuestions from "../hooks/useQuestions";
import useAnswers, { useAnswerMutation } from "../hooks/useAnswers";
import PageLoader from "../components/PageLoader";
import useConsultMutation from "../hooks/useConsultMutation";
import { getLocalPath } from "../common/utils";
import usePopNotifications from "../hooks/usePopNotifications";
import { FallbackPage } from "./FallbackPage";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
  },
}));

export default function AssessmentPage() {
  const { globalState, setGlobalState } = useGlobalState();
  const { t } = useTranslation();
  const classes = useStyles();
  const { id } = useParams();
  const [query, setQuery] = useSearchParams();
  const [submitted, setSubmitted] = useState(false);

  const reason = query.get("reason");
  const category = query.get("category");
  const questionIdx = query.get("question");
  const questionIndex = parseInt(globalState.questionIndex || questionIdx || 0);
  const { setNotification } = usePopNotifications();
  const [preQuestionIndex, setPreQuestionIndex] = useState(questionIndex);
  const slide = questionIndex >= preQuestionIndex ? "left" : "right";

  const {
    data: questions,
    status: questionStatus,
    error: questionError,
  } = useQuestions(category, reason);
  const {
    data: answers,
    status: answerStatus,
    error: answerError,
  } = useAnswers(id);

  const saveAnswer = async ({ newAnswer, question }) => {
    const payload = Object.assign(newAnswer, {
      questionId: question.id,
      consultationId: id,
    });

    const existedAnswer = answers.find((a) => a.questionId === question.id);

    if (existedAnswer && existedAnswer.id) {
      return await updateAnswer(payload, existedAnswer.id);
    }

    return await createAnswer(payload);
  };

  const [mutateAnswer, mutateAnswerState] = useAnswerMutation(saveAnswer, id);
  const [mutateTask, mutateTaskState] = useConsultMutation(
    id,
    () => submitTask(id, SubmitQuestionnaireTaskRequest()),
    {
      onSuccess: () => {
        setGlobalState({ questionIndex: 0 });
        setSubmitted(true);
      },
    }
  );

  const handlePrevious = () => {
    if (questionIndex > 0) {
      setGlobalState({ questionIndex: questionIndex - 1 });
    }
  };

  useEffect(() => {
    setPreQuestionIndex(questionIndex);
  }, [questionIndex]);

  useEffect(() => {
    if (preQuestionIndex !== questionIndex) {
      setQuery({
        reason,
        category,
        question: questionIndex,
      });
    }
  }, [questionIndex, preQuestionIndex, reason, category, setQuery]);

  useEffect(() => {
    window.addEventListener("popstate", handlePrevious);

    return () => window.removeEventListener("popstate", handlePrevious);
  });

  const isLoading =
    questionStatus === "loading" ||
    answerStatus === "loading" ||
    mutateAnswerState.isLoading ||
    mutateTaskState.isLoading;

  if (isLoading && (!answers || !questions)) {
    return <PageLoader />;
  }

  if (
    questionStatus === "error" ||
    answerStatus === "error" ||
    mutateAnswerState.isError ||
    mutateTaskState.isError
  ) {
    return (
      <FallbackPage
        error={
          questionError ||
          answerError ||
          mutateAnswerState.error ||
          mutateTaskState.error
        }
      />
    );
  }

  const question = getCurrentQuestion(questions, answers, questionIndex);
  const answer = answers.find((a) => a.questionId === question.id) || {
    consultationId: id,
    questionId: -1,
  };
  const isLast = isLastQuestion(question, questions, answers);
  const totalQuestions = getQuestionTotal(question, questions, answers);

  const handleAnswerChange = async (val) => {
    if (!val || val.questionId === -1) return;

    // save image if there is one
    if (question.inputType === "PICTURE" && val.blob) {
      try {
        const fileInfo = await uploadImage(val.blob);

        val.textAnswer = fileInfo.url;
        val.blob = null;
      } catch (err) {
        setNotification(err.message, "error");
        return;
      }
    }

    await mutateAnswer({ newAnswer: val, question });

    if (isLast) {
      // submit assessment to server
      await mutateTask();
    } else {
      setGlobalState({ questionIndex: questionIndex + 1 });
    }
  };

  if (submitted) {
    // TODO: need tell server the questionnaire has been submitted.
    return <Navigate replace to={getLocalPath(`consultations/${id}`)} />;
  }

  return (
    <ThemeProvider
      theme={(theme) =>
        createMuiTheme({
          ...theme,
          palette: {
            ...theme.palette,
            background: {
              default: "#F2F2F2",
            },
          },
        })
      }
    >
      <CssBaseline />

      <DefaultLayout title={t("page.assessment")}>
        <Container className={classes.container}>
          <Slide
            key={question.id}
            direction={slide}
            in={true}
            mountOnEnter
            unmountOnExit
          >
            <Assessment
              isLoading={isLoading}
              isLastStep={isLast}
              onChange={handleAnswerChange}
              question={question}
              answer={answer}
              currentIndex={questionIndex + 1}
              totalQuestions={totalQuestions}
            />
          </Slide>
        </Container>
      </DefaultLayout>
    </ThemeProvider>
  );
}
