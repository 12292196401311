// @flow

import {
  List,
  makeStyles,
} from "@material-ui/core";
import * as React from "react";
import type { TaskContent } from "../models";
import ConsultationTaskContentListItem from "./ConsultationTaskContentListItem";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

type Props = {
  contents: Array<TaskContent>,
};
export default function ConsultationTaskContentList({ contents }: Props) {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      {contents.map((content, index) => (
        <ConsultationTaskContentListItem content={content} key={`content_${index}`} />
      ))}
    </List>
  );
}
