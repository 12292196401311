// @flow

import type { Answer, Question, QuestionAndAnswer } from "../models";
import { format } from "date-fns";

type Trans = {
  noanswer: string,
  choice404: string,
};

const getChoices = (question, answer, trans: Trans) => {
  const choices = (answer.choices || []).map((choiceId) => {
    const choice = (question.choices || []).find((c) => c.id === choiceId);

    if (!choice) return trans.choice404;

    return choice.description;
  });

  return choices;
};

const getQuestionsAndAnswers = (
  questions: Array<Question>,
  answers: Array<Answer>,
  trans: Trans
) => {
  const getAnswer = (answer: Answer, question: Question) => {
    if (!answer) return [trans.noanswer];
    const unit = question.unit ? ` ${question.unit}` : "";

    switch (question.inputType) {
      case "DATE":
        // TODO: format date string with language code
        return [format(new Date(answer.textAnswer || ""), "dd MMM yyyy")];
      case "NUMBER":
        return [`${answer.numAnswer || -1}${unit}`];
      case "SINGLE_SELECTION":
      case "MULTIPLE_SELECTION":
        return getChoices(question, answer, trans);
      default:
        return [answer.textAnswer || ""];
    }
  };

  const hasAnswer = (question: Question) =>
    answers.findIndex((a) => a.questionId === question.id) > -1;

  return questions
    .filter((question) => hasAnswer(question))
    .map<QuestionAndAnswer>((question) => {
      const answer = answers.find((a) => a.questionId === question.id) || {};

      return {
        description: question.description,
        name: question.name,
        type: question.inputType,
        answer: getAnswer(answer, question),
      };
    });
};

// does this question has dependency or not
const meetDependency = (
  question: Question,
  questions: Array<Question>,
  answers: Array<Answer>
): boolean => {
  // find out the question dependency
  if (question.dependentQuestion) {
    const foundAnswer = answers.find(
      (a) => a.questionId === question.dependentQuestion
    );
    const choices =
      foundAnswer && foundAnswer.choices ? foundAnswer.choices : [];
    const dependents = question.dependentChoices
      ? question.dependentChoices
      : [];

    return choices.some((choice) => {
      return dependents.indexOf(choice) > -1;
    });
  }

  return true;
};

const getCurrentQuestion = (
  questions: Array<Question>,
  answers: Array<Answer>,
  questionIndex: number
) => {
  const defaultQuestion: Question = {
    id: -1,
    inputType: "NUMBER",
    name: "",
    description: "",
    section: "",
  };

  if (!questions || !questions.length) {
    return defaultQuestion;
  }

  if (questionIndex === 0) {
    return questions[0];
  }

  // find current question
  let qIdx = 0;
  for (let question of questions) {
    const hasDependency = meetDependency(question, questions, answers);
    
    if (
      qIdx === questionIndex && hasDependency
    ) {
      return question;
    }

    if (answers.find((a) => a.questionId === question.id) && hasDependency) {
      qIdx++;
    }
  }

  return defaultQuestion;
};

const isLastQuestion = (
  currentQuestion: Question,
  questions: Array<Question>,
  answers: Array<Answer>
): boolean => {
  const currentIndex = questions.findIndex((q) => q.id === currentQuestion.id);

  if (currentIndex === -1 || !answers.length) {
    return false;
  }

  for (let i = currentIndex + 1; i < questions.length; i++) {
    const question = questions[i];
    const answer = answers.find((a) => a.questionId === question.id);

    if (!answer && meetDependency(question, questions, answers)) {
      return false;
    }
  }

  return true;
};

const getQuestionTotal = (
  currentQuestion: Question,
  questions: Array<Question>,
  answers: Array<Answer>
): number => {
  const currentIndex = questions.findIndex((q) => q.id === currentQuestion.id);

  if (currentIndex === -1) {
    return questions.length;
  }

  let total = 0;

  for (let i = 0; i < questions.length; i++) {
    const question = questions[i];

    if (meetDependency(question, questions, answers)) {
      total = total + 1;
    }
  }

  return total;
};

const isConsultationQuestion = (question: Question) => {
  return question.section !== "personal" && question.section !== "agreement";
};

const isPersonalQuestion = (question: Question) => {
  return question.section === "personal";
};

export {
  getQuestionsAndAnswers,
  getCurrentQuestion,
  isLastQuestion,
  getQuestionTotal,
  isConsultationQuestion,
  isPersonalQuestion,
};
