// @flow

// only work for patient
import { Grid, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useConsultation } from "../providers/ConsultationProvider";
import DoctorNote from "./DoctorNote";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginBottom: theme.spacing(7),
  },
  note: {
    marginBottom: theme.spacing(7),
  },
}));
type Props = {
  description?: string,
};

export default function ConsultationRejectPanel({ description }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { consultation } = useConsultation();

  return (
    <Grid container direction="column">
      <Grid item className={classes.note}>
        <DoctorNote note={consultation.note || ""} />
      </Grid>
      <Grid item className={classes.subtitle}>
        <Typography variant="h3" component="h3">
          {t("reject.wheretofrom")}
        </Typography>
      </Grid>
      <Grid item className={classes.subtitle}>
        <Typography variant="body1" component="p">
          {t("reject.askgp")}
        </Typography>
      </Grid>
    </Grid>
  );
}
