// @flow

import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import DefaultLayout from "../components/DefaultLayout";
import Fallback from "../components/Fallback";
import FormContainer from "../components/FormContainer";
import PageLoader from "../components/PageLoader";
import useConsult from "../hooks/useConsult";
import { getCurrentTask } from "../services/task";
import type { ConsultationTask, VerificationRequest } from "../models";
import { Verify } from "../components/Verify";
import { VerifyIdentityTaskRequest } from "../models/tasks";
import useConsultMutation from "../hooks/useConsultMutation";
import { submitTask } from "../services/api";
import usePopNotifications from "../hooks/usePopNotifications";
import SubmitButton from "../components/SubmitButton";
import CheckIcon from "@material-ui/icons/Check";
import { getLocalPath } from "../common/utils";
import ConsultationTaskContent from "./ConsultationTaskContent";

const useStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(4),
  },
  submit: {
    marginTop: theme.spacing(7),
  },
}));

export default function VerificationPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams();
  const { setNotification } = usePopNotifications();
  const navigate = useNavigate();
  const [verified, setVerified] = useState();
  const [verifiedCode, setVerifiedCode] = useState();

  const verifyId = ({ code }) => {
    return submitTask(consultation.id, VerifyIdentityTaskRequest(code));
  };

  const { data: consultation, status, error } = useConsult(id, {
    onSuccess: ({ creator }) => {
      setVerified(creator.verified || false);
    },
  });

  const [mutate, mutateState] = useConsultMutation(id, verifyId, {
    onSuccess: () => {
      navigate(getLocalPath(`/consultations/${id}`));
    },
  });

  const handleComplete = React.useCallback(
    async (response: VerificationRequest) => {
      setVerifiedCode(response.code);
      setVerified(true);

      setNotification(t("verification.success"), "success");
    },
    [setNotification, t]
  );

  const handleFailed = React.useCallback(
    (err) => {
      setNotification(err.message, "error");
    },
    [setNotification]
  );

  const handleContinue = async () => {
    await mutate({ code: verifiedCode });
  };

  if (status === "loading") {
    return <PageLoader />;
  }

  if (status === "error") {
    return <Fallback error={error} />;
  }

  const task: ConsultationTask = getCurrentTask(consultation);

  return (
    <DefaultLayout title={t("page.verification")}>
      <Container className={classes.container}>
        <FormContainer title={task.name}>
          <ConsultationTaskContent
            consultationId={consultation.id}
            taskId={task.id}
            type="PARAGRAPH"
            title={task.description}
            className={classes.description}
          />
          {verified ? (
            <Grid container spacing={4} direction="row">
              <Grid item>
                <CheckIcon />
              </Grid>
              <Grid item>
                <Typography variant="h4" component="div">
                  {t("verification.verified")}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Verify onSuccess={handleComplete} onFailed={handleFailed} />
          )}
          <SubmitButton
            className={classes.submit}
            text={t("continue")}
            disabled={!verified}
            isLoading={mutateState.isLoading}
            onClick={handleContinue}
          />
        </FormContainer>
      </Container>
    </DefaultLayout>
  );
}
