// @flow

import React from "react";
import { useTranslation } from "react-i18next";
import { useConsultation } from "../providers/ConsultationProvider";

import { Typography, makeStyles, Grid } from "@material-ui/core";
import { getQuestionsAndAnswers } from "../services/survey";
import Fallback from "./Fallback";
import PageLoader from "./PageLoader";
import { usePersonalAnswers } from "../hooks/useAnswers";
import { usePersonalQuestions } from "../hooks/useQuestions";
import { useAuth } from "../providers/AuthProvider";
import UserAvatar from "./UserAvatar";
import { format } from "date-fns";
import { calculateAge } from "../common/utils";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  list: {},
  item: {},
  avatar: {
    marginBottom: theme.spacing(4),
  },
}));

type Props = {
  title: string,
};

export default function PersonalInfo(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuth();
  const { consultation } = useConsultation();
  const questionsReq = usePersonalQuestions(consultation.category.id);
  const answersReq = usePersonalAnswers(consultation);

  if (questionsReq.status === "loading" || answersReq.status === "loading") {
    return <PageLoader />;
  }

  if (questionsReq.status === "error" || answersReq.status === "error") {
    return <Fallback error={questionsReq.error || answersReq.error} />;
  }

  const questions = questionsReq.data;
  const answers = answersReq.data;
  const items = getQuestionsAndAnswers(questions, answers, {
    noanswer: t("consultation.noanswer"),
    choice404: t("consultation.choice404"),
  });

  const birthday = new Date(consultation.creator.birthday);

  const personalItems = [
    { name: t("name"), value: consultation.creator.displayName },
    { name: t("email"), value: consultation.creator.email },
    { name: t("dob"), value: format(birthday, "dd MMM yyyy") },
    { name: t("age"), value: calculateAge(birthday) },
  ];

  const Info = ({ name, value }) => {
    return (
      <Grid item container direction="row" spacing={3} justify="space-between">
        <Grid item>
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
            gutterBottom
          >
            {`${name}:`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" component="div" gutterBottom>
            {value}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container direction="column">
      <Grid item className={classes.title}>
        <Typography variant="overline" component="div" color="textSecondary">
          {user.patient
            ? t("consultation.mydetail")
            : t("consultation.personal")}
        </Typography>
      </Grid>
      {!user.patient ? (
        <Grid item className={classes.avatar}>
          <UserAvatar name={consultation.creator.displayName} />
        </Grid>
      ) : null}
      <Grid
        container
        justify="space-between"
        spacing={3}
        className={classes.list}
        direction="column"
      >
        {personalItems.map((personalItem, idx) => (
          <Grid item className={classes.item}>
            <Info name={personalItem.name} value={personalItem.value} />
          </Grid>
        ))}
        {items.map((question, idx) => (
          <Grid item key={`question_${idx}`} className={classes.item}>
            <Info name={`${question.name || ""}`} value={question.answer[0]} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
