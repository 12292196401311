// @flow

import * as React from "react";
import { queryCache, useQuery } from "react-query";
import * as auth from "../services/auth";
import { request } from "../services/request";
import PageLoader from "../components/PageLoader";
import Fallback from "../components/Fallback";
import type {
  RequestOptions,
  LoginAuthRequest,
  RegisterAuthRequest,
} from "../models";

export const AuthContext: React.Context<any> = React.createContext();
AuthContext.displayName = "AuthContext";

type Props = {
  children: React.Node,
};

function AuthProvider(props: Props) {
  const [user, setUser] = React.useState(null);

  const bootstrapAppData = async () => {
    let user = null;

    const token = await auth.getToken();
    if (token) {
      const data = await request("bootstrap", { token });
      user = data.user;
    }

    return user;
  };

  const { error, status } = useQuery("bootstrap", bootstrapAppData, {
    onSuccess: (data) => setUser(data),
  });

  const login = React.useCallback(
    (form: LoginAuthRequest) => auth.login(form).then((user) => setUser(user)),
    []
  );
  const register = React.useCallback(
    (form: RegisterAuthRequest) =>
      auth.register(form).then((user) => setUser(user)),
    []
  );

  const logout = React.useCallback(() => {
    auth.logout();
    queryCache.clear();
    setUser(null);
  }, []);

  const value = React.useMemo(() => ({ user, login, logout, register }), [
    login,
    logout,
    register,
    user,
  ]);

  if (status === "loading") {
    return <PageLoader />;
  }

  if (status === "error") {
    return <Fallback error={error} />;
  }

  if (status === "success") {
    return (
      <AuthContext.Provider value={value}>
        {props.children}
      </AuthContext.Provider>
    );
  }

  throw new Error(`Unhandled status: ${status}`);
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

function useRequest() {
  const { user } = useAuth();
  const token = user ? user.token : undefined;

  return React.useCallback(
    (endpoint: string, config: RequestOptions) =>
      request(endpoint, { ...config, token }),
    [token]
  );
}

export { AuthProvider, useAuth, useRequest };
