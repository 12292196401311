// @flow
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(4)
  }
}));

type Props = {
  error: Error,
};

// TODO: finalise this component
export default function Fallback({ error }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container justify="center" alignItems="center" direction="column">
      <Grid item className={classes.section}>
        <Typography variant="h4" component="h3" color="textSecondary">
          {t("error.cannotload")}
        </Typography>
      </Grid>
      <Grid item className={classes.section}>
        <Typography variant="body2" component="p" color="textSecondary">
          {t("error.difficulties")}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          onClick={() => window.location.reload()}
        >
          {t("refresh")}
        </Button>
      </Grid>
    </Grid>
  );
}
