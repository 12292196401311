// @flow

import * as React from 'react';
import {
  Typography,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

type Props = {
  title: string,
  defaultExpanded: boolean,
  children: React.Node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.grey["300"]}`,
    marginTop: theme.spacing(7),
    backgroundColor: theme.palette.background.paper,
    paddingLeft: 0
  },
  summary: {
    paddingLeft: 0
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  details: {
    flexDirection: "column"
  }
}));

export default function ConsultationSection(props: Props) {
  const classes = useStyles();

  return (
    <Accordion className={classes.root} defaultExpanded={props.defaultExpanded} elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.summary}>
        <Typography variant="h3" component="h2" className={classes.title}>
          {props.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
}