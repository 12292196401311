// @flow
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Button,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useQuery } from "react-query";
import PageLoader from "./PageLoader";
import Fallback from "./Fallback";
import { findItems } from "../services/api";
import { useConsultation } from "../providers/ConsultationProvider";
import { getAvailableOrders, getTreatmentFromOrders } from "../services/order";
import { useAuth } from "../providers/AuthProvider";
import { isDoctor } from "../models/helpers";
import type { Item } from "../models";

type Props = {};

const useStyles = makeStyles((theme) => ({
  details: {
    marginTop: theme.spacing(4),
  },
  controls: {},
  actions: {
    marginTop: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(2),
    },
  },
}));

export default function PrescribePanel(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    consultation,
    parentConsultation,
    selectedTreatment,
    setSelectedTreatment,
    setPrescribDialogOpen,
    setRejectDialogOpen,
    setNochangeDialogOpen
  } = useConsultation();
  const orders = getAvailableOrders(consultation, parentConsultation);
  const [treatment, setTreatment] = useState(selectedTreatment || getTreatmentFromOrders(orders));
  // eslint-disable-next-line no-unused-expressions
  (treatment: ?Item);
  const category = consultation.category.id;
  const reason = consultation.reason.id;
  const { data: items, status, error } = useQuery(`items-${category}`, () =>
    findItems(category)
  );
  const { user } = useAuth();

  const handleTreatmentChange = (e, newValue) => {
    const item = items.find((item) => item.name === newValue);

    setTreatment(item);
  };

  if (status === "loading") {
    return <PageLoader />;
  }

  if (status === "error") {
    return <Fallback error={error} />;
  }

  return (
    <Grid container direction="column" spacing={2} className={classes.controls}>
      <Typography variant="body1" component="p">
        {t("consultation.treatment.action")}
      </Typography>
      {isDoctor(user) && (
        <Grid item className={classes.treatmentselection}>
          <Autocomplete
            value={treatment ? treatment.name : null}
            options={items.map((item) => item.name)}
            onChange={handleTreatmentChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("consultation.treatment.selection")}
                margin="normal"
              />
            )}
          />
        </Grid>
      )}
      {isDoctor(user) && (
        <Grid item className={classes.actions}>
          <Button
            variant="contained"
            color="primary"
            disabled={!treatment}
            onClick={() => {
              setSelectedTreatment(treatment);
              setPrescribDialogOpen(true);
            }}
          >
            {t("consultation.prescribe")}
          </Button>
          <Button onClick={() => {
            setRejectDialogOpen(true);
          }}>
            {t("consultation.reject")}
          </Button>
          {reason === "followup" ? (
            <Button onClick={() => {
              setNochangeDialogOpen(true);
            }}>
              {t("consultation.nochange")}
            </Button>
          ) : null}
        </Grid>
      )}
    </Grid>
  );
}
