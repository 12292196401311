// @flow

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useParams, useSearchParams } from "react-router-dom";

import {
  Container,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import QuestionnaireList from "../components/QuestionnaireList";
import DefaultLayout from "../components/DefaultLayout";
import CommentListView from "./CommentListView";
import PrescribeDialog from "../components/PrescribeDialog";
import RejectDialog from "../components/RejectDialog";
import {
  ConsultationContext,
  ConsultationProvider,
} from "../providers/ConsultationProvider";
import ConsultationNextTask from "../components/ConsultationNextTask";
import ConsultationLinks from "../components/ConsultationLinks";
import { useMutation } from "react-query";
import PageLoader from "../components/PageLoader";
import Fallback from "../components/Fallback";
import { createConsultation } from "../services/api";
import { useAuth } from "../providers/AuthProvider";
import FormContainer from "../components/FormContainer";
import ConsultationTaskForm from "../components/ConsultationTaskForm";
import { getCurrentTaskName } from "../services/task";
import DoctorNotesView from "./DoctorNotesView";
import DoctorLetterView from "./DoctorLetterView";
import ConsultationSidePanel from "../components/ConsultationSidePanel";
import NoChangeDialog from "../components/NoChangeDialog";
import RedirectDialog from "../components/RedirectDialog";
import { getLocalPath } from "../common/utils";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "100%",
    flexWrap: "nowrap",
  },
  title: {
    marginBottom: theme.spacing(6),
  },
  content: {
    flex: 5,
    overflowX: "hidden",
    overflowY: "auto",
    padding: theme.spacing(4),
  },
  mobileContent: {
    padding: theme.spacing(2),
  },
  panel: {
    flex: 2,
  },
  contentWrapper: {},
}));

export default function ConsultationPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { id } = useParams();
  const [query] = useSearchParams();
  const reason = query.get("reason");
  const category = query.get("category");
  const parent = query.get("parent");
  const [newId, setNewId] = useState(id);
  const { user } = useAuth();
  const [redirectUrl, setRedirectUrl] = useState(null);

  const create = () => {
    return createConsultation({
      category,
      reason,
      parent,
    });
  };

  const [mutate, mutateState] = useMutation(create, {
    onSuccess: (newConsultation) => {
      setNewId(newConsultation.id);
    },
    onError: (err) => {
      if (err.redirect) {
        setRedirectUrl(getLocalPath(err.url));
      }
    },
  });

  useEffect(() => {
    setNewId(id);

    if (id === "new") {
      mutate();
    }
  }, [id, mutate]);

  if (mutateState.isError) {
    if (mutateState.error.redirect) {
      return (
        <RedirectDialog
          defaultOpen={Boolean(redirectUrl)}
          text={t("redirect.consultation", { categoryName: mutateState.error.value })}
          url={redirectUrl || "/"}
        />
      );
    }

    return <Fallback error={mutateState.error} />;
  }

  if (mutateState.isLoading || newId === "new") {
    return <PageLoader />;
  }

  if (user.patient) {
    return (
      <ConsultationProvider
        parent={parent}
        consultationId={newId}
      >
        <ConsultationContext.Consumer>
          {(value) => (
            <DefaultLayout title={getCurrentTaskName(value.consultation)}>
              <Container className={classes.container}>
                <FormContainer title={getCurrentTaskName(value.consultation)}>
                  <Grid container direction="column">
                    <ConsultationTaskForm />
                  </Grid>
                </FormContainer>
              </Container>
            </DefaultLayout>
          )}
        </ConsultationContext.Consumer>
      </ConsultationProvider>
    );
  }

  return (
    <ConsultationProvider
      category={category}
      reason={reason}
      parent={parent}
      consultationId={newId}
    >
      <DefaultLayout title={t("consultation")}>
        <Grid
          container
          direction={isMobile ? "column" : "row"}
          justify="space-between"
          className={classes.wrapper}
        >
          <Grid
            item
            className={isMobile ? classes.mobileContent : classes.content}
          >
            <Typography variant="h2" component="h1" className={classes.title}>
              {t("consultation.details")}
            </Typography>
            {isMobile ? <ConsultationSidePanel /> : null}
            <ConsultationNextTask />
            <ConsultationLinks />
            <DoctorLetterView />
            <CommentListView />
            <QuestionnaireList />
            <DoctorNotesView />
          </Grid>
          {isMobile ? null : (
            <Grid item className={classes.panel}>
              <ConsultationSidePanel />
            </Grid>
          )}
        </Grid>
        <PrescribeDialog />
        <RejectDialog />
        <NoChangeDialog />
      </DefaultLayout>
    </ConsultationProvider>
  );
}
