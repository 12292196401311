// @flow

import * as React from "react";
import {
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@material-ui/core";
import SummarySection from "./SummarySection";
import type { Summary } from "../models";

type Props = {
  renderImg?: Function,
  renderActions?: Function,
  title: string,
  description?: string,
  expanded: boolean,
  children: React.Node,
  onChange: Function,
  summaries: Array<Summary>,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: `${theme.spacing(4)}px 0`,
  },
  summary: {
    flexDirection: "column",
  },
  action: {
    textAlign: "right",
  },
  details: {
    flexDirection: "column",
    paddingLeft: 0,
  },
}));

export default function EditPreviewSection(props: Props) {
  const classes = useStyles();

  return (
    <Accordion
      className={classes.root}
      expanded={props.expanded}
      onChange={props.onChange}
      elevation={0}
    >
      <Box display={props.expanded ? "none" : "inherit"}>
        <AccordionSummary classes={{ content: classes.summary }}>
          {props.summaries.map((summary, i) => (
            <SummarySection
              key={`section_${i}`}
              description={summary.description}
              renderImg={summary.img}
              title={summary.title}
            />
          ))}
          <Box className={classes.action}>
            {props.renderActions ? props.renderActions() : null}
          </Box>
        </AccordionSummary>
      </Box>
      <AccordionDetails className={classes.details}>
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
}
