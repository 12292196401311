// @flow

import React from "react";
import { useTranslation } from "react-i18next";
import Fallback from "../components/Fallback";
import ResultLayout from "../components/ResultLayout";

type Props = {
  error: Error,
}

export function FallbackPage({ error }: Props) {
  const { t } = useTranslation();
  
  return (
    <ResultLayout title={t("page.error")}>
      <Fallback error={error} />
    </ResultLayout>
  )
}