// @flow

import React, { useState, useEffect } from "react";
import type { Node } from "react";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Button,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import CheckIcon from "@material-ui/icons/Check";

import type { NestedComment } from "../models";
import CommentEdit from "./CommentEdit";
import { useConsultation } from "../providers/ConsultationProvider";
import { useAuth } from "../providers/AuthProvider";
import UserAvatar from "./UserAvatar";
import { formatTimeDistance } from "../common/utils";
import HtmlComponent from "./HtmlComponent";

const useStyles = makeStyles((theme) => ({
  textContainer: {
    flex: "1 1 auto",
  },
  item: {},
  avatarContainer: {
    flex: `0 0 3em`,
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  childsection: {
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    borderLeft: `3px solid ${theme.palette.grey[300]}`,
  },
  isAnsweredText: {
    verticalAlign: "middle",
    margin: `0px ${theme.spacing(1)}px`,
  },
  markAsAnsweredButton: {
    padding: 0,
    fontSize: theme.typography.caption.fontSize,
    lineHeight: theme.typography.caption.lineHeight,
    verticalAlign: "baseline",
  },
  checkIcon: {
    verticalAlign: "middle",
  },
  actionsContainer: {
    justifyContent: "space-between",
  },
  timeCreated: {
    marginLeft: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  answerButton: {
    marginTop: theme.spacing(3),
  },
}));

type Props = {
  comment: NestedComment,
  onCommentAnswer: Function,
  onSetAsAnswered: Function,
};

type ItemProps = {
  item: NestedComment,
  children?: Node,
};

export default function CommentItem({
  comment,
  onCommentAnswer,
  onSetAsAnswered,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [replying, setReplying] = useState(false);
  const {
    setFocusCommentAnswerEdit,
    focusCommentAnswerEdit,
  } = useConsultation();
  const { user } = useAuth();

  useEffect(() => {
    if (focusCommentAnswerEdit) {
      setReplying(true);
    }
  }, [focusCommentAnswerEdit]);

  const getAvatarIcon = (comment: NestedComment) => {
    if (comment.isPatient) {
      return <UserAvatar name={comment.userName || ""} small={true} />;
    }

    return <Avatar alt={comment.userName} src={comment.userPicture} />;
  };

  const getCurrentUserAvatar = () => {
    // TODO: check current user group
    return <PermIdentityIcon />;
  };

  const getUserName = (item: NestedComment) => {
    if (item.userId === user.id) {
      return t("me");
    }

    return item.userName;
  };

  const onCancelEdit = () => {
    setReplying(false);
    setFocusCommentAnswerEdit(false);
  };

  const onSaveEdit = (e) => {
    onCommentAnswer(e, comment.id);
  };

  const Item = ({ item, children }: ItemProps) => {
    return (
      <Grid container direction="row" className={classes.item}>
        <Grid item xs={1} className={classes.avatarContainer}>
          <Avatar className={classes.avatar}>{getAvatarIcon(item)}</Avatar>
        </Grid>
        <Grid
          item
          container
          className={classes.textContainer}
          direction="column"
          xs={11}
        >
          <Grid container direction="row" className={classes.actionsContainer}>
            <Typography variant="overline">{getUserName(item)}</Typography>
            <Grid item>
              {!item.isAnswered && item.type === "QUESTION" && user.doctor && (
                <Button
                  className={classes.markAsAnsweredButton}
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={() => onSetAsAnswered(item.id)}
                >
                  {t("comment.markasanswered")}
                </Button>
              )}
              <Typography variant="caption" className={classes.timeCreated}>
                {formatTimeDistance(item.updatedAt || new Date().toISOString())}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="body2" component="div">
              <HtmlComponent htmlStr={item.text} />
            </Typography>
          </Grid>
          {item.isAnswered && !item.child && (
            <Grid item>
              <CheckIcon
                className={classes.checkIcon}
                fontSize="inherit"
                color="secondary"
              />
              <Typography
                className={classes.isAnsweredText}
                variant="caption"
                component="span"
                color="secondary"
              >
                {t("comment.isanswered")}
              </Typography>
            </Grid>
          )}
          <Grid item>{children}</Grid>
        </Grid>
      </Grid>
    );
  };

  const Child = () => {
    if (comment.type === "COMMENT") {
      return null;
    }

    if (!comment.child && (comment.userId === user.id || user.admin)) {
      return null;
    }

    if (!comment.child && comment.isAnswered) {
      return null;
    }

    if (comment.child) {
      return (
        <Grid item className={classes.childsection}>
          <Item item={comment.child}></Item>
        </Grid>
      );
    }

    if (!replying) {
      return (
        <Grid item>
          <Button
            className={classes.answerButton}
            color="primary"
            size="small"
            variant="contained"
            onClick={() => setReplying(true)}
          >
            {t("reply")}
          </Button>
        </Grid>
      );
    }

    return (
      <Grid item className={classes.childRoot}>
        <CommentEdit
          canUploadImage={true}
          labelText={t("comment.response")}
          scrollIntoView={focusCommentAnswerEdit}
          willAutoFocus={true}
          onCancelEdit={onCancelEdit}
          onSaveEdit={onSaveEdit}
          avatar={getCurrentUserAvatar()}
        />
      </Grid>
    );
  };

  return (
    <Item item={comment}>
      <Child />
    </Item>
  );
}
