// @flow
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React from "react";

import type { Consultation, Item } from "../models";
import { getTreatmentFromOrders } from "../services/order";
import TreatmentSection from "./TreatmentSection";

type Props = {
  consultation: Consultation,
};

export default function TreatmentDetails({ consultation }: Props) {
  const treatment: ?Item = getTreatmentFromOrders(consultation.orders);
  const { t } = useTranslation();

  if (!treatment) {
    return null;
  }

  return (
    <Grid container direction="column">
      <TreatmentSection name={t("yourtreatment")} text={treatment.description} />
    </Grid>
  );
}
