// @flow

import { formatDistance } from "date-fns";
import { enAU, zhCN } from "date-fns/locale";
import { AppLanguage } from "../models/enums";

export function getDateFromServerTimestamp(date: string) {
  const a: Array<number> = date.split(/[^0-9]/).map((d) => parseInt(d));

  // Find the solution here:
  // https://stackoverflow.com/questions/6427204/date-parsing-in-javascript-is-different-between-safari-and-chrome
  return new Date(Date.UTC(a[0], a[1] - 1, a[2], a[3], a[4], a[5]));
}

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
}

export function validatePassword(password: ?string) {
  return password && password.length > 0;
}

export function validateNewPassword(password: ?string) {
  return password && password.length > 7;
}

export function isAssessmentPage(path: string) {
  const re = /\/consultations\/\S*\/assessment/;

  return re.test(String(path));
}

/**
 * From stackoverflow answer: https://stackoverflow.com/questions/12168909/blob-from-dataurl
 */
export function imageURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(",")[1]);

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  let ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: "image/png" });

  return blob;
}

export function createMarkup(htmlStr: string) {
  return { __html: htmlStr };
}

const locales = { en: enAU, zh: zhCN };

//
export function formatTimeDistance(value: string) {
  const timestamp = getDateFromServerTimestamp(value);

  return formatDistance(timestamp, new Date(), {
    addSuffix: true,
    locale: locales[getLanguageCode()],
  });
}

export function formatCurrency(price: number) {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "AUD",
  }).format(price);
}

export function validateCardNumber(number: string) {
  var regex = new RegExp("^[0-9]{16}$");
  if (!regex.test(number)) return false;

  return luhnCheck(number);
}

function luhnCheck(val) {
  var sum = 0;
  for (var i = 0; i < val.length; i++) {
    var intVal = parseInt(val.substr(i, 1));
    if (i % 2 === 0) {
      intVal *= 2;
      if (intVal > 9) {
        intVal = 1 + (intVal % 10);
      }
    }
    sum += intVal;
  }
  return sum % 10 === 0;
}

export function acronym(text: string): string {
  return text
    .split(/\s/)
    .reduce((accumulator, word) => accumulator + word.charAt(0), "")
    .toUpperCase();
}

export function mobileCheck() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  }

  return false;
}

export function getBaseUrl() {
  return window.location.origin;
}

export function getLanguageCode() {
  const parsedData = window.location.pathname.split("/");
  const languageCode = parsedData[1];

  return languageCode;
}

export function getLanguage(lang: string) {
  const languageKey = Object.keys(AppLanguage).find((key) => {
    const languageCode = AppLanguage[key];

    return languageCode === lang;
  });

  return languageKey ? AppLanguage[languageKey] : AppLanguage["English"];
}

export function getLocalPath(path: string) {
  return `/${getLanguageCode()}/${path}`.replace(/([^:]\/)\/+/g, "$1");
}

export function calculateAge(birthday: Date) {
  // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function calculateBMI(weight: number, height: number): string {
  const heightByMeter = height / 100;
  const bmi = weight/ (heightByMeter * heightByMeter);

  return bmi.toFixed(2);
}