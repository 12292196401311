import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  imageButtonFullSize: {
    width: "100%",
  },
  imageInput: {
    display: "none",
  },
  imageButton: {},
}));

type Props = {
  size?: boolean,
  fullSize?: boolean,
  text: string,
  startIcon?: React.ReactNode,
  onChange: Function
};

export default function OpenFileButton(props: Props) {
  const classes = useStyles();
  const { size, fullSize, text } = props;

  return (
    <Button
      size={size}
      className={fullSize ? classes.imageButtonFullSize : classes.imageButton}
      variant="outlined"
      color="primary"
      component="label"
      startIcon={props.startIcon}
    >
      {text}
      <input
        type="file"
        name="image"
        accept="image/*"
        capture="environment"
        className={classes.imageInput}
        onChange={props.onChange}
      />
    </Button>
  );
}
