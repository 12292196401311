// @flow

import axios from "axios";
import { queryCache } from "react-query";
import { apiUrl } from "../common/globals";
import type { AxiosRequestConfig } from "axios";
import type { RequestOptions } from "../models";
import { getToken, logout } from "./auth";
import { getLanguageCode, getLocalPath } from "../common/utils";

async function request(endpoint: string, requestProps?: RequestOptions) {
  const props = requestProps || {};
  const authToken = await getToken();
  const token = props.token || authToken;
  const method = props.method ? props.method : props.data ? "post" : "get";
  const headers = {
    Authorization: token ? `Bearer ${token}` : undefined,
    "Accept-Language": getLanguageCode(),
  };
  const customConfig = props.customConfig || {};
  const config: AxiosRequestConfig = {
    method,
    url: `${apiUrl}/${endpoint}`,
    data: props.data,
    headers,
    ...customConfig,
  };

  return axios(config)
    .then(async (response) => {
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 202
      ) {
        return response.data;
      }
    })
    .catch(async (err) => {
      if (err.response) {
        if (err.response.status === 401) {
          queryCache.clear();
          await logout();
          // refresh the page for them
          window.location.assign(window.location);

          return Promise.reject({ message: "Please re-authenticate." });
        } else if (err.response.status === 403) {
          window.location.assign(getLocalPath("403"));
        }

        return Promise.reject(err.response.data);
      }

      return Promise.reject(err);
    });
}

export { request };
