// @flow
import React from "react";
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import type { User } from "../models/user";
import type { Consultation, Category, Reason } from "../models";
import { formatTimeDistance, getLocalPath } from "../common/utils";
import { Link } from "react-router-dom";

type Column = {
  id: string,
  label: string,
  minWidth: number,
  format: Function,
};

const getColumns = (t) => [
  {
    id: "creator",
    label: t("patient"),
    minWidth: 100,
    format: (value: User) => value.displayName,
  },
  {
    id: "assignee",
    label: t("doctor"),
    minWidth: 100,
    format: (value?: User) => {
      return value ? value.displayName : t('unassigned');
    },
  },
  {
    id: "reason",
    label: t("reason"),
    minWidth: 100,
    format: (value: Reason) => value.name,
  },
  {
    id: "category",
    label: t("category"),
    minWidth: 100,
    format: (value: Category) => value.name,
  },
  {
    id: "updatedAt",
    label: t("updatedAt"),
    minWidth: 170,
    format: (value: string) => formatTimeDistance(value),
  },
  {
    id: "createdAt",
    label: t("createdAt"),
    minWidth: 170,
    format: (value: string) => formatTimeDistance(value),
  },
  {
    id: "id",
    label: "Link",
    minWidth: 170,
    format: (value: string) => {
      return <Link to={getLocalPath(`/consultations/${value}`)}>{t("details")}</Link>;
    },
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

type Props = {
  consultations: Array<Consultation>,
  selectable?: boolean,
  onSelect?: Function,
};

export default function ConsultationList(props: Props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { t } = useTranslation();
  const consultations = props.consultations || [];
  const selectable = props.selectable || false;
  const rowCount = consultations.length;
  const numSelected = selected.length;

  const columns: Array<Column> = getColumns(t);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = consultations.map((c) => c.id);
      setSelected(newSelecteds);

      if (props.onSelect) {
        props.onSelect(newSelecteds);
      }
      return;
    }
    setSelected([]);

    if (props.onSelect) {
      props.onSelect([]);
    }
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);

    if (props.onSelect) {
      props.onSelect(newSelected);
    }
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {selectable ? (
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={handleSelectAllClick}
                    inputProps={{ "aria-label": "select all desserts" }}
                  />
                </TableCell>
              ) : null}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(consultations || [])
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((consultation, index) => {
                const isItemSelected = isSelected(consultation.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, consultation.id)}
                    role="checkbox"
                    tabIndex={-1}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                    key={consultation.id}
                  >
                    {selectable ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                    ) : null}
                    {columns.map((column) => {
                      const value = consultation[column.id];
                      return (
                        <TableCell key={column.id}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={consultations.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
