// @flow
import {
  Button,
  Grid,
  makeStyles,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getLanguageCode } from "../common/utils";
import { useAuth } from "../providers/AuthProvider";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import EmailIcon from "@material-ui/icons/Email";
import { LANDING_URL, SUPPORT_EMAIL } from "../constants";
import HomeIcon from "@material-ui/icons/Home";

const useStyles = makeStyles((theme) => ({
  helpContainer: {
    padding: theme.spacing(6),
  },
  helpTitle: {
    marginBottom: theme.spacing(6),
  },
  close: {
    marginTop: theme.spacing(4),
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

type Props = {
  disableHome?: boolean,
};

export function MenuBar({ disableHome }: Props) {
  const { user, logout } = useAuth();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const helpId = open ? "menu-bar-help-id" : undefined;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallPage = useMediaQuery(theme.breakpoints.down("xs"));

  const handleCloseHelp = () => {
    setAnchorEl(null);
  };

  const handleOpenHelp = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = async () => {
    await logout();

    window.location.assign(`/${getLanguageCode()}`);
  };

  return (
    <div>
      <Grid container direction="row">
        {disableHome ? null : (
          <Grid item style={{ marginRight: 10 }}>
            <Button
              color="primary"
              variant="outlined"
              disableElevation
              size="small"
              onClick={() => { window.location.assign(LANDING_URL)}}
              endIcon={<HomeIcon />}
            >
              {t("home")}
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            size="small"
            onClick={handleOpenHelp}
            endIcon={<HelpOutlineIcon />}
          >
            {t("help")}
          </Button>
        </Grid>
        {user && !isSmallPage ? (
          <Grid item>
            <Button
              color="primary"
              variant="text"
              disableElevation
              size="small"
              onClick={handleLogout}
            >
              {t("logout")}
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <Popover
        id={helpId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseHelp}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Grid className={classes.helpContainer}>
          <Typography
            variant="h4"
            component="div"
            className={classes.helpTitle}
          >
            {t("help.title")}
          </Typography>
          <Grid container direction="row" spacing={4}>
            <Grid item>
              <EmailIcon fontSize="small" color="primary" />
            </Grid>
            <Grid item>
              <Typography variant="body2" component="div">
                <Trans
                  i18nKey="help.email"
                  values={{ supportEmail: SUPPORT_EMAIL }}
                >
                  Email us anytime at
                  <a
                    href={`mailto:${SUPPORT_EMAIL}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    {SUPPORT_EMAIL}
                  </a>
                </Trans>
              </Typography>
            </Grid>
          </Grid>
          <Button
            onClick={handleCloseHelp}
            variant="outlined"
            size="small"
            className={classes.close}
          >
            {t("close")}
          </Button>
        </Grid>
      </Popover>
    </div>
  );
}
