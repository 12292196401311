// @flow

import * as React from "react";
import { useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Container,
  makeStyles,
} from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import DefaultLayout from "../components/DefaultLayout";
import { getLocalPath, validateEmail, validatePassword } from "../common/utils";
import type { RegisterAuthRequest } from "../models";
import SubmitButton from "../components/SubmitButton";
import { useMutation } from "react-query";
import { useAuth } from "../providers/AuthProvider";
import usePopNotifications from "../hooks/usePopNotifications";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(6),
  },
  inputfield: {
    marginBottom: theme.spacing(6),
  },
  submit: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  link: {
    color: theme.palette.primary.main
  }
}));

export default function RegisterPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { register } = useAuth();
  const { setNotification } = usePopNotifications();
  const [formErrors, setFormErrors] = useState({});
  const [registerData, setRegisterData] = useState<RegisterAuthRequest>({});
  const [mutate, { isLoading }] = useMutation(
    (registerData) => {
      return register(registerData);
    },
    {
      onError: (err) => {
        setNotification(`${err.message}`, "error");
      },
      onSuccess: () => {
        setNotification(t("account.issuccessfullyregistered"), "success");

        const { from } = location.state || { from: { pathname: getLocalPath("/") } };

        navigate(from);
      },
    }
  );

  const validate = (name: string, value: string): string | null => {
    if (value.length === 0) {
      switch (name) {
        case "email":
          return t("account.emailerror");
        case "password":
          return t("account.passworderror");
        default:
          break;
      }
    }

    if (name === "email" && !validateEmail(value)) {
      return t("account.emailerror");
    }

    if (name === "password" && !validatePassword(value)) {
      return t("account.passworderror");
    }

    return null;
  };

  const handleChange = (e): void => {
    const { name, value } = e.target;

    const error = validate(name, value);

    setFormErrors(Object.assign({}, formErrors, { [name]: error }));

    if (!error) {
      setRegisterData(Object.assign({}, registerData, { [name]: value }));
    }
  };

  const handleSubmit = async (e: Event) => {
    e.preventDefault();

    try {
      return await mutate(registerData);
    } catch (error) {
      console.error(error);
    }
  };

  const emptyErrors = (): boolean => {
    return Object.keys(formErrors).every(
      (key) => !formErrors[key] || !formErrors[key].length
    );
  };

  const isValid = (): boolean => {
    return Boolean(
      emptyErrors() && registerData.email && registerData.password && !isLoading
    );
  };

  return (
    <DefaultLayout title={t("signup")}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="start"
        marginTop="10%"
      >
        <Container maxWidth="sm">
          <form>
            <Box mb={3} className={classes.title}>
              <Typography color="textPrimary" variant="h1">
                {t("signup")}
              </Typography>
            </Box>
            <TextField
              required
              className={classes.inputfield}
              error={Boolean(formErrors.email)}
              fullWidth
              helperText={formErrors.email}
              label={t("account.emaillabel")}
              margin="normal"
              name="email"
              type="email"
              variant="outlined"
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={(e) => {
                handleChange(e);
              }}
            />
            <TextField
              required
              error={Boolean(formErrors.password)}
              className={classes.inputfield}
              fullWidth
              helperText={formErrors.password}
              label={t("account.passwordlabel")}
              margin="normal"
              name="password"
              type="password"
              variant="outlined"
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={(e) => {
                handleChange(e);
              }}
            />
            <Box my={2} className={classes.submit}>
              <SubmitButton
                isLoading={isLoading}
                onClick={handleSubmit}
                text={t("signup")}
                disabled={!isValid()}
                fullWidth={true}
              />
            </Box>
            <Typography variant="body2" component="span">
              <Trans
                i18nKey="account.member"
                defaults="Already a member? <link1>Login here</link1>"
                components={{
                  link1: <Link to={getLocalPath("/login")} variant="body2" className={classes.link} />,
                }}
              ></Trans>
            </Typography>
          </form>
        </Container>
      </Box>
    </DefaultLayout>
  );
}
