// @flow
import React from "react";
import { Typography, makeStyles, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2)
  },
  imgContainer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  imgContainerItem: {
    display: "flex",
    alignItems: "center",
    border: `1px solid ${theme.palette.grey["300"]}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
  description: {
    marginLeft: "auto",
  },
}));

type Props = {
  renderImg?: Function,
  title: string,
  description?: string,
};

export default function SummarySection({
  renderImg,
  title,
  description,
}: Props) {
  const classes = useStyles();

  return (
    <Grid container direction="row" className={classes.root} spacing={2}>
      {renderImg && (
        <Grid className={classes.imgContainerItem} item>
          {renderImg ? renderImg() : null}
        </Grid>
      )}
      <Grid item>
        <Typography variant="body2" component="div">
          {title}
        </Typography>
      </Grid>
      <Grid item className={classes.description}>
        <Typography variant="body2" component="div">
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
}
