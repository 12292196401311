// @flow

import React from "react";
import { useTranslation } from "react-i18next";
import { useConsultation } from "../providers/ConsultationProvider";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import {
  Container,
  Typography,
  makeStyles,
  Grid,
  Avatar,
  useTheme,
  useMediaQuery,
  Popover,
  Button,
} from "@material-ui/core";

import HtmlComponent from "./HtmlComponent";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  avatar: {
    width: theme.spacing(11),
    height: theme.spacing(11),
  },
  doctorButton: {
    padding: theme.spacing(1),
  },
  doctorBioContainer: {
    padding: theme.spacing(6),
  },
  bioTitle: {
    marginBottom: theme.spacing(6),
  },
  close: {
    marginTop: theme.spacing(6)
  }
}));

const defaultAvatar = "http://static.mmens.com.au/images/doctor-default.png";

type Props = {
  isMobile?: boolean,
};

export default function DoctorInfo(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { consultation } = useConsultation();
  const theme = useTheme();
  const isSmallPage = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = props.isMobile || isSmallPage;
  const [anchorEl, setAnchorEl] = React.useState(null);

  if (isMobile && !consultation.assignee) {
    return null;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "doctor-bio-popover" : undefined;

  return (
    <Grid container direction="column">
      {isMobile ? null : (
        <Grid item className={classes.title}>
          <Typography variant="overline" component="div" color="textSecondary">
            {t("doctor.details")}
          </Typography>
        </Grid>
      )}

      <Grid
        item
        container
        alignItems="center"
        direction={isMobile ? "row" : "column"}
        spacing={4}
      >
        {!consultation.assignee ? (
          <Grid item>
            <Typography variant="body1" component="div">
              {t("unassigned")}
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid item>
              <Avatar
                alt={consultation.assignee.displayName}
                src={consultation.assignee.pictureUrl || defaultAvatar}
                className={classes.avatar}
              />
            </Grid>
            <Grid item>
              <Button
                variant="text"
                component="div"
                className={classes.doctorButton}
                endIcon={<ChevronRightIcon />}
                onClick={handleClick}
              >
                {consultation.assignee.displayName}
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
              >
                <Container className={classes.doctorBioContainer} maxWidth="xs">
                  <Typography
                    variant="h4"
                    component="div"
                    className={classes.bioTitle}
                  >
                    {t("doctor.bio")}
                  </Typography>
                  <HtmlComponent htmlStr={consultation.assignee.bio} />
                  <Button
                    onClick={handleClose}
                    variant="outlined"
                    size="small"
                    className={classes.close}
                  >
                    {t("close")}
                  </Button>
                </Container>
              </Popover>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}
