// @flow

import React, { useState } from "react";
import { makeStyles, Grid, Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  findUnassignedConsultations,
  assignConsultations,
} from "../services/api";
import ConsultationList from "../components/ConsultationList";
import AdminActions from "../components/AdminActions";
import type { User } from "../models";
import { useMutation, useQuery, useQueryCache } from "react-query";
import Fallback from "../components/Fallback";
import usePopNotifications from "../hooks/usePopNotifications";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default function UnassignedConsultationView() {
  const classes = useStyles();
  const { setNotification } = usePopNotifications();
  const [selectedConsultations, setSelectedConsultations] = useState([]);
  const cache = useQueryCache();
  const cacheKey = "consultations-unassigned";
  const { data: consultations, status, error } = useQuery(
    cacheKey,
    findUnassignedConsultations
  );

  const [mutate] = useMutation(
    (doctor: User) => {
      if (selectedConsultations.length === 0) {
        return;
      }

      return assignConsultations(selectedConsultations, doctor.id);
    },
    {
      onSuccess: () => {
        cache.removeQueries(cacheKey);

        setNotification("Assign consultation successfully", "success");
      },
      onError: (err) => {
        setNotification(err.message, "error");
      },
    }
  );

  if (status === "error") {
    return <Fallback error={error} />;
  }

  const handleSelect = (selected) => {
    setSelectedConsultations(selected);
  };

  const handleAssign = async (doctor) => {
    await mutate(doctor);
  };

  return (
    <Grid container spacing={2} direction="column" className={classes.root}>
      <Grid item>
        <AdminActions
          disabled={selectedConsultations.length === 0}
          onAssign={handleAssign}
        />
      </Grid>
      <Grid item>
        {status === "loading" ? (
          Array.from(new Array(10)).map((item, idx) => (
            <Box marginBottom={1} key={`skeleton_${idx}`}>
              <Skeleton variant="rect" width="100%" height={20} />
            </Box>
          ))
        ) : (
          <ConsultationList
            consultations={consultations}
            selectable={true}
            onSelect={handleSelect}
          />
        )}
      </Grid>
    </Grid>
  );
}
