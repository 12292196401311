import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Typography, makeStyles } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { getLocalPath } from "../common/utils";
import ResultLayout from "../components/ResultLayout";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  back: {
    marginTop: theme.spacing(6),
  },
}));

/**
 * This is a dummy page. Only to show some entries.
 * TODO: This will be removed before release
 */
export default function PermissionDeniedPage() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ResultLayout title={t("error.403")}>
      <Typography
        variant="h4"
        component="h3"
        color="textSecondary"
        gutterBottom
        className={classes.title}
      >
        {t("error.403")}
      </Typography>
      <Typography variant="body2" component="p" color="textSecondary">
        {t("error.403.details")}
      </Typography>
      <Button
        startIcon={<ArrowBackIcon />}
        className={classes.back}
        variant="outlined"
      >
        <Link to={getLocalPath("/")}>{t("action.gobackhome")}</Link>
      </Button>
    </ResultLayout>
  );
}
