// @flow

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, makeStyles, TextField } from "@material-ui/core";
import DefaultLayout from "../components/DefaultLayout";
import SubmitButton from "../components/SubmitButton";
import { createPaymentSession } from "../services/api";
import FormContainer from "../components/FormContainer";
import { getPriceList, getUnpaidOrder } from "../services/order";
import type { Order, PaymentSession } from "../models";
import PageLoader from "../components/PageLoader";
import Fallback from "../components/Fallback";
import { getCurrentTaskName, hasShippingTask } from "../services/task";
import PriceTable from "../components/PriceTable";
import AddressEditSection from "./AddressEditSection";
import useConsult from "../hooks/useConsult";
import usePopNotifications from "../hooks/usePopNotifications";
import { getBaseUrl, getLocalPath } from "../common/utils";
import { useMutation } from "react-query";
import { useStripe } from "@stripe/react-stripe-js";
import { FallbackPage } from "./FallbackPage";
import { useAddress } from "../hooks/useAddress";
import CheckoutSection from "../components/CheckoutSection";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
  },
  section: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  sectionContent: {
    paddingLeft: 0,
  },
  contentRoot: {
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  coupon: {
    marginTop: theme.spacing(4),
  },
}));

type CheckoutProps = {
  order: Order,
};

export default function CheckoutPage() {
  const { t } = useTranslation();
  const { setNotification } = usePopNotifications();
  const classes = useStyles();
  const { id } = useParams();
  const [address, setAddress] = useState(null);
  const [helper, setHelper] = useState("");
  const stripe = useStripe();
  const [coupon, setCoupon] = useState("");

  const createSession = ({ order }: CheckoutProps) => {
    if (!address || !address.id) {
      return Promise.reject(new Error("Address is empty"));
    }

    const path = getLocalPath(`/consultations/${id}/payment-return`);
    const returnUrl = `${getBaseUrl()}${path}`.replace(/([^:]\/)\/+/g, "$1");

    return createPaymentSession(order.id, address.id, returnUrl, coupon);
  };

  const { data: consultation, status, error } = useConsult(id);

  const {
    data: defaultAddress,
    status: addressStatus,
    error: addressError,
  } = useAddress({
    onSuccess: (data) => {
      setAddress(data);
    },
  });

  const [mutate, mutateState] = useMutation(createSession, {
    onSuccess: async (newSession: PaymentSession) => {
      console.log("Start payment session: ", newSession.sessionId);

      // When the customer clicks on the button, redirect them to Checkout.
      const result = await stripe.redirectToCheckout({
        sessionId: newSession.sessionId,
      });

      if (result.error) {
        console.error(result.error);
      }

      console.log(result);
    },
    onError: (err) => {
      setNotification(err.message, "error");
    },
  });

  if (status === "loading" || addressStatus === "loading") {
    return <PageLoader />;
  }

  if (status === "error" || addressStatus === "error") {
    return <FallbackPage error={error || addressError} />;
  }

  const order: ?Order = getUnpaidOrder(consultation.orders);

  if (!order) {
    return <Fallback error={new Error("Order does not exist")} />;
  }

  const handleChangeAddress = (result) => {
    if (result !== address) {
      setAddress(result);
    }
  };

  const isValid = () => {
    return !mutateState.isLoading && address;
  };

  const handleSubmit = async () => {
    await mutate({ order });
  };

  return (
    <DefaultLayout title={t("checkout")}>
      <Container className={classes.container}>
        <FormContainer title={getCurrentTaskName(consultation)}>
          <CheckoutSection title={t("order.summary")}>
            <PriceTable
              items={getPriceList(order, {
                consultation: t("consultation.name"),
              })}
            />
            <TextField
              className={classes.coupon}
              id="coupon"
              label={t("discount.code")}
              variant="outlined"
              defaultValue={coupon}
              helperText={helper}
              size="small"
              name="coupon"
              onChange={(e) => {
                const value = e.target.value;

                setHelper(value ? t("discount.checkout") : "");
                setCoupon(value);
              }}
            />
          </CheckoutSection>
          {hasShippingTask(consultation) ? (
            <CheckoutSection title={t("shipping.title")}>
              <AddressEditSection
                onSubmit={handleChangeAddress}
                address={address || defaultAddress}
              />
            </CheckoutSection>
          ) : null}
          <SubmitButton
            text={t("order.continue.checkout")}
            disabled={!isValid()}
            isLoading={mutateState.isLoading}
            onClick={handleSubmit}
          />
        </FormContainer>
      </Container>
    </DefaultLayout>
  );
}
