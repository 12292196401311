// @flow
import React, { useState } from "react";
import {
  Grid,
  Button,
  Popover,
  makeStyles
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import AssigneePicker from "./AssigneePicker";
import type { User } from "../models/user";

const useStyles = makeStyles((theme) => ({
  actions: {
    marginTop: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(2),
    },
  },
}));

type Props = {
  disabled?: boolean,
  onAssign: Function,
};

export default function AdminActions(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAssignee = (doctor: User) => {
    setAnchorEl(null);

    props.onAssign(doctor);
  };

  const open = Boolean(anchorEl);

  return (
    <Grid className={classes.actions}>
      <Button
        variant="contained"
        disabled={props.disabled}
        onClick={handleClick}
      >
        {t("admin.assign")}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <AssigneePicker onSelect={handleSelectAssignee} />
      </Popover>
    </Grid>
  );
}
