import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  Container,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DefaultLayout from "../components/DefaultLayout";
import { getLocalPath, validateNewPassword } from "../common/utils";
import type { ResetPasswordRequest } from "../models";
import SubmitButton from "../components/SubmitButton";
import { useMutation } from "react-query";
import { changePassword } from "../services/auth";
import usePopNotifications from "../hooks/usePopNotifications";
import { useNavigate, useSearchParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(6),
  },
  submit: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  description: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
}));

export default function ChangePasswordPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const passwordRef = useRef("");
  const confirmPasswordRef = useRef("");
  const [success, setSucess] = useState(false);
  const { setNotification } = usePopNotifications();
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const token = query.get("token");

  const getValues = (): ResetPasswordRequest => {
    const password = passwordRef.current.value;

    return { newPassword: password, token };
  };

  const [mutate, { isLoading }] = useMutation(changePassword, {
    onSuccess: () => {
      setSucess(true);
    },
    onError: (err) => setNotification(err.message, "error"),
  });

  const validate = () => {
    const password = passwordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;
    const newErrors = {};

    if (!validateNewPassword(password)) {
      newErrors.password = t("account.newpassworderror");
    }

    if (confirmPassword !== password) {
      newErrors.confirmPassword = t("account.confirmpassworderror");
    }

    setErrors(newErrors);
  };

  const isValid = () => {
    return (
      passwordRef.current &&
      passwordRef.current.value &&
      confirmPasswordRef.current &&
      confirmPasswordRef.current.value
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (success) {
      return navigate(getLocalPath("/login"));
    }

    if (!isValid()) {
      validate();
    } else {
      await mutate(getValues());
    }
  };

  const handleBlur = (e) => {
    validate();
  };

  return (
    <DefaultLayout title={t("resetpassword")}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="start"
        marginTop="10%"
      >
        <Container maxWidth="sm">
          <form>
            <Box mb={3} className={classes.title}>
              <Typography color="textPrimary" variant="h1">
                {success
                  ? t("login.resetpassword.success")
                  : t("resetpassword")}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              component="p"
              className={classes.description}
            >
              {success
                ? t("login.resetpassword.description")
                : t("login.resetpassword.hint")}
            </Typography>
            {success ? null : (
              <>
                <TextField
                  error={Boolean(errors.password)}
                  fullWidth
                  helperText={errors.password}
                  label={t("login.resetpassword.create")}
                  margin="normal"
                  name="newpassword"
                  type="password"
                  variant="outlined"
                  inputRef={passwordRef}
                  onBlur={handleBlur}
                />
                <TextField
                  error={Boolean(errors.password)}
                  fullWidth
                  helperText={errors.confirmPassword}
                  label={t("login.resetpassword.confirm")}
                  margin="normal"
                  name="password"
                  type="password"
                  variant="outlined"
                  inputRef={confirmPasswordRef}
                  onBlur={handleBlur}
                />
              </>
            )}
            <Box my={2} className={classes.submit}>
              <SubmitButton
                isLoading={isLoading}
                onClick={handleSubmit}
                text={success ? t("login") : t("resetpassword")}
                disabled={Boolean(errors.email)}
                fullWidth={true}
              />
            </Box>
          </form>
        </Container>
      </Box>
    </DefaultLayout>
  );
}
