// @flow
import React, { useState } from "react";
import type { Node } from "react";
import {
  Grid,
  Box,
  Tabs,
  Tab,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DefaultLayout from "../components/DefaultLayout";
import ConsultationsListView from "./ConsultationsListView";
import { Status } from "../models/enums";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}));

function tabProps(index) {
  return {
    id: `consultation-list-tab-${index}`,
    "aria-controls": `consultation-list-tabpanel-${index}`,
  };
}

type TabPanelProps = {
  children: Node,
  index: number,
  value: number,
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default function DoctorHomePage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DefaultLayout title={t("page.doctorhome")}>
      <Grid className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label={t("consultations.todo")} {...tabProps(0)} />
          <Tab label={t("consultations.inprogress")} {...tabProps(1)} />
          <Tab label={t("consultations.done")} {...tabProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <ConsultationsListView consultationStatus={Status.TODO} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ConsultationsListView consultationStatus={Status.INPROGRESS} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ConsultationsListView consultationStatus={Status.DONE} />
        </TabPanel>
      </Grid>
    </DefaultLayout>
  );
}
