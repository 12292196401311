// @flow

import React from "react";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import type { PriceItem } from "../models";
import { formatCurrency } from "../common/utils";

type Props = {
  items: Array<PriceItem>,
};

const useStyles = makeStyles((theme) => ({
  table: {},
  cellroot: {
    padding: theme.spacing(2),
  },
  value: {
    paddingLeft: 0,
    paddingRight: 0,
    borderBottom: 0,
    fontSize: theme.typography.body2.fontSize,
  },
  total: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export default function PriceTable({ items }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const getItemName = (item: PriceItem): string =>
    item.isTotal ? t("total") : item.name;

  return (
    <Table>
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.name}>
            <TableCell
              classes={{ root: classes.cellroot }}
              className={clsx(classes.value, {
                [classes.total]: item.isTotal,
              })}
            >
              {getItemName(item)}
            </TableCell>
            <TableCell
              classes={{ root: classes.cellroot }}
              className={clsx(classes.value, {
                [classes.total]: item.isTotal,
              })}
              align="right"
            >
              {formatCurrency(item.price)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
