// @flow

import * as React from "react";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { AuthProvider } from "./AuthProvider";
import { StateProvider } from "./StateProvider";
import { PopNotificationProvider } from "./PopNotificationProvider";
import theme from "../theme";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

type Props = {
  children: React.Node,
};

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function AppProviders({ children }: Props) {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ThemeProvider theme={theme}>
        <Elements stripe={stripePromise}>
          <CssBaseline />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <StateProvider>
              <PopNotificationProvider>
                <AuthProvider>{children}</AuthProvider>
              </PopNotificationProvider>
            </StateProvider>
          </MuiPickersUtilsProvider>
        </Elements>
      </ThemeProvider>
    </ReactQueryCacheProvider>
  );
}

export { AppProviders };
