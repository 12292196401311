// @flow
import React from "react";
import {
  Backdrop,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  buttonProgress: {
    color: theme.palette.grey,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function PageLoader() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress
          size={24}
          color="inherit"
          className={classes.buttonProgress}
        />
      </Backdrop>
    </React.Fragment>
  );
}
