import React from "react";
import { Navigate, Route, useLocation } from "react-router-dom";
import { hasRole } from "../models/helpers";
import { useAuth } from "../providers/AuthProvider";
import { getLocalPath } from "./utils";

export default function PrivateRoute({ element, roles, ...rest }) {
  const location = useLocation();
  const { user } = useAuth();

  if (!user) {
    const to =
      location.pathname === "/consultations/new" ? "/register" : "/login";

    return <Navigate replace={true} to={getLocalPath(to)} state={{ from: location }} />;
  }

  if (!hasRole(user, roles)) {
    return <Navigate to={getLocalPath('403')} />;
  }

  return <Route {...rest} element={element} />;
}
