// @flow
// showing to patient the consultation and conversation with doctor

import React from "react";
import CommentListView from "../views/CommentListView";

export default function ConsultingPanel() {
  return (
    <React.Fragment>
      <CommentListView />
    </React.Fragment>
  );
}
