// @flow
// This task form is only for patient

import { Grid, makeStyles } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import * as React from "react";
import { useConsultation } from "../providers/ConsultationProvider";
import type { TaskModel, ConsultationTask } from "../models";
import { getCurrentTask, getTask } from "../services/task";
import Tasks from "../models/tasks";
import ConsultationCompletePanel from "./ConsultationCompletePanel";
import CommentListView from "../views/CommentListView";
import { useTranslation } from "react-i18next";
import PrimaryLinkButton from "./PrimaryLinkButton";
import ConsultationRejectPanel from "./ConsultationRejectPanel";
import ConsultationTaskContent from "../views/ConsultationTaskContent";
import { getLocalPath } from "../common/utils";
import TreatmentReviewPanel from "./TreatmentReviewPanel";
import DoctorInfo from "./DoctorInfo";
import ConsultingPanel from "./ConsultingPanel";
import ConsultationNoChangePanel from "./ConsultationNoChangePanel";

const useStyles = makeStyles((theme) => ({
  root: {},
  doctorInfo: {
    marginBottom: theme.spacing(6),
  },
  description: {
    marginBottom: theme.spacing(6),
  },
  action: {
    marginTop: theme.spacing(7),
  },
  wrapper: {
    marginTop: theme.spacing(6),
  },
}));

export default function ConsultationTaskForm() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { consultation } = useConsultation();
  const task: TaskModel = getTask(consultation);
  const currentTask: ConsultationTask = getCurrentTask(consultation);

  const ContentWrapper = ({ children }) => (
    <div className={classes.wrapper}>{children}</div>
  );

  const Content = () => {
    if (task === Tasks.ACCEPT_QUOTA) {
      return (
        <ContentWrapper>
          <TreatmentReviewPanel />
        </ContentWrapper>
      );
    }

    if (task === Tasks.COMPLETED) {
      return (
        <ContentWrapper>
          <ConsultationCompletePanel />
        </ContentWrapper>
      );
    }

    if (task === Tasks.ANSWER_QUESTIONS) {
      return (
        <ContentWrapper>
          <CommentListView />
        </ContentWrapper>
      );
    }

    if (task === Tasks.REJECTED) {
      return (
        <ContentWrapper>
          <ConsultationRejectPanel />
        </ContentWrapper>
      );
    }

    if (task === Tasks.NOCHANGE) {
      return (
        <ContentWrapper>
          <ConsultationNoChangePanel />
        </ContentWrapper>
      );
    }

    if (task === Tasks.CONSULTATION) {
      return (
        <ContentWrapper>
          <ConsultingPanel />
        </ContentWrapper>
      );
    }

    return null;
  };

  const Action = () => {
    if (
      task !== Tasks.ACCEPT_QUOTA &&
      task !== Tasks.COMPLETED &&
      task !== Tasks.ANSWER_QUESTIONS
    ) {
      return (
        <PrimaryLinkButton
          startIcon={<HomeIcon />}
          text={t("action.gobackhome")}
          url={getLocalPath("home")}
        />
      );
    }

    return null;
  };

  const hasFAQ = () => {
    if (task === Tasks.ACCEPT_QUOTA) {
      return false;
    }

    return true;
  };

  return (
    <Grid container direction="column" className={classes.root}>
      {consultation.assignee ? (
        <Grid item className={classes.doctorInfo}>
          <DoctorInfo isMobile={true} />
        </Grid>
      ) : null}
      <ConsultationTaskContent
        taskId={currentTask.id}
        type="PARAGRAPH"
        title={currentTask.description}
        alternative={currentTask.description}
      />
      <Content />
      {hasFAQ() ? (
        <ConsultationTaskContent
          taskId={currentTask.id}
          type="FAQ"
          seperator={true}
        />
      ) : null}
      <Grid item className={classes.action}>
        <Action />
      </Grid>
    </Grid>
  );
}
