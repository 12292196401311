// @flow

import React from "react";
import type { Node } from "react";
import { Button, Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

type Props = {
  text: string,
  url: string,
  startIcon?: Node
};

export default function PrimaryLinkButton({ text, url, startIcon }: Props) {
  const navigate = useNavigate();

  return (
    <Grid container justify="flex-start">
      <Button
        onClick={() => navigate(url)}
        disableElevation
        color="primary"
        variant="text"
        fullWidth={false}
        startIcon={startIcon}
      >
        {text}
      </Button>
    </Grid>
  );
}
