// @flow

import React from "react";
import { useTranslation } from "react-i18next";
import ConsultationSection from "../components/ConsultationSection";
import { useConsultation } from "../providers/ConsultationProvider";
import Letter from "../components/Letter";

export default function DoctorLetterView() {
  const { t } = useTranslation();
  const { consultation } = useConsultation();

  if (!consultation.note) {
    return null;
  }

  return (
    <ConsultationSection
      title={t("consultation.letter")}
      defaultExpanded={true}
    >
      <Letter note={consultation.note} />
    </ConsultationSection>
  );
}
