// @flow
import axios from "axios";
import { apiUrl } from "../common/globals";
import type {
  LoginAuthRequest,
  RegisterAuthRequest,
  ResetPasswordRequest,
  ChangePasswordRequest,
  User,
} from "../models";
import type { AxiosRequestConfig } from "axios";
import { getLanguageCode } from "../common/utils";
import { AppLanguage } from "../models/enums";

const localStorageKey = "__auth_token__";

async function getToken() {
  return window.localStorage.getItem(localStorageKey);
}

function handleUserResponse({ token, user }) {
  window.localStorage.setItem(localStorageKey, token);
  return user;
}

function login({ email, password }: LoginAuthRequest) {
  return client("login", { email, password }).then(handleUserResponse);
}

function register(registerData: RegisterAuthRequest) {
  return client("register", registerData).then(handleUserResponse);
}

function resetPassword({ email }: ResetPasswordRequest) {
  return client(`reset-password?email=${email}`);
}

function changePassword(data: ChangePasswordRequest) {
  return client(`change-password`, data);
}

async function logout() {
  window.localStorage.removeItem(localStorageKey);
}

async function client(endpoint: string, data) {
  const config: AxiosRequestConfig = {
    method: "post",
    url: `${apiUrl}/${endpoint}`,
    data,
    headers: {
      "Accept-Language": getLanguageCode()
    }
  }

  return axios(config)
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        return response.data;
      } else {
        return Promise.reject(response.data);
      }
    })
    .catch((err) => {
      const error = err.response.data;
      // FIXME: find a better solution to deal with ValidPassword response
      if (error.errors && error.errors.length) {
        error.message = error.errors[0].defaultMessage;
      }

      return Promise.reject(error);
    });
}

function getHomeUrl(user: User) {
  const language = getLanguageCode() || user.language || AppLanguage.English;
  
  if (user.patient) {
    return `/${language}/home`;
  }

  if (user.doctor) {
    return `/${language}/doctor`;
  }

  if (user.admin) {
    return `/${language}/admin`;
  }

  return `/${language}`;
}

export {
  getToken,
  login,
  register,
  logout,
  resetPassword,
  changePassword,
  getHomeUrl,
  localStorageKey,
};
