import React from "react";

import { makeStyles, Box } from "@material-ui/core";
import { mobileCheck } from "../../common/utils";

const isMobile = mobileCheck();

const useStyles = makeStyles((theme) => ({
  imageContainer: ({ width, height }) => ({
    margin: "auto",
    textAlign: "center",
    padding: theme.spacing(2),
    width: width || "100%",
    height: height || "100%",
  }),
  image: {
    width: isMobile ? "75%": "50%",
    objectFit: "contain",
  },
}));

type Props = {
  imageUrl: string,
  description: string,
  width: ?number,
  height: ?number,
};

export default function ImagePreview(props: Props) {
  const classes = useStyles(props);

  return (
    <Box className={classes.imageContainer}>
      <img
        className={classes.image}
        src={props.imageUrl}
        alt={props.description}
      />
    </Box>
  );
}
