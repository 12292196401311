// @flow
import React, { useState } from "react";
import DefaultLayout from "../components/DefaultLayout";
import { Trans, useTranslation } from "react-i18next";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { makeStyles, Grid, Typography, Container } from "@material-ui/core";
import SteppersView from "./SteppersView";
import SubmitButton from "../components/SubmitButton";
import { getLocalPath } from "../common/utils";
import usePopNotifications from "../hooks/usePopNotifications";
import { PRIVACY_URL, TERM_URL } from "../constants";
import { useGlobalState } from "../providers/StateProvider";
import { getCategory } from "../services/api";
import PageLoader from "../components/PageLoader";
import { FallbackPage } from "./FallbackPage";
import { useQuery } from "react-query";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  title: {
    marginTop: theme.spacing(10),
  },
  subtitle: {
    marginTop: theme.spacing(6),
  },
  stepper: {
    marginTop: theme.spacing(6),
    width: "100%",
  },
  agreement: {
    marginTop: theme.spacing(7),
    padding: `0 ${theme.spacing(6)}px`,
  },
  submit: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    width: "80%",
    margin: "0 auto",
  },
  link: {
    color: theme.palette.primary.main
  }
}));

export default function AgreementPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { type } = useParams();
  const [query] = useSearchParams();
  const [startConsultation, setStartConsultation] = useState(false);
  const reason = query.get("reason") || "initial";
  const parent = query.get("parent");
  const { setNotification } = usePopNotifications();
  const { setGlobalState } = useGlobalState();

  const handleStepperError = (err) => {
    setNotification(err.message, "error");
  };

  const { error, status, data: category } = useQuery(`category-${type}`, () =>
    getCategory(type)
  );

  if (status === "loading") {
    return <PageLoader />;
  }

  if (status === "error") {
    return <FallbackPage error={error} />;
  }

  if (startConsultation) {
    let queryString = `category=${type}&reason=${reason}`;
    if (parent) {
      queryString += `&parent=${parent}`;
    }

    // start a new consultation
    return (
      <Navigate push to={getLocalPath(`consultations/new?${queryString}`)} />
    );
  }

  return (
    <DefaultLayout title={t("page.agreement")}>
      <Container maxWidth="md">
        <Grid
          container
          direction="column"
          alignContent="center"
          className={classes.root}
        >
          <Grid item className={classes.title}>
            <Typography variant="h2" component="h2" gutterBottom>
              {t("agreement.hello")}
            </Typography>
            <Typography variant="h2" component="h2">
              <Trans
                i18nKey={
                  reason === "followup"
                    ? "agreement.followup.greeting"
                    : "agreement.greeting"
                }
                values={{ category: category.name }}
              >
                {`Welcom to our ${category} service`}
              </Trans>
            </Typography>
          </Grid>
          <Grid item className={classes.subtitle}>
            <Typography variant="body1" component="div">
              {reason === "followup"
                ? t("agreement.howfollwoupworks")
                : t("agreement.howitworks")}
            </Typography>
          </Grid>
          <Grid item className={classes.stepper}>
            <SteppersView
              reason={reason}
              orientation="horizontal"
              onError={handleStepperError}
            />
          </Grid>
          <Grid item className={classes.agreement}>
            <Typography variant="body2" component="span">
              <Trans i18nKey="agreement.message">
                By clicking \"Let’s get started\" below you confirm you have
                read our
                <a href={TERM_URL} target="_blank" rel="noopener noreferrer" className={classes.link}>
                  Terms & Conditions
                </a>
                and
                <a href={PRIVACY_URL} target="_blank" rel="noopener noreferrer" className={classes.link}>
                  Privacy Policy
                </a>
              </Trans>
            </Typography>
          </Grid>
          <Grid item className={classes.submit}>
            <SubmitButton
              text={t("action.startsurvey")}
              onClick={() => {
                setGlobalState({ questionIndex: 0 });
                setStartConsultation(true);
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </DefaultLayout>
  );
}
