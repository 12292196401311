// @flow

import * as React from "react";
import { Navigate } from "react-router-dom";
import { getLocalPath } from "../common/utils";
import Fallback from "../components/Fallback";
import PageLoader from "../components/PageLoader";
import useConsult from "../hooks/useConsult";
import type { TaskModel, Item } from "../models";
import { Tasks } from "../models/enums";
import { getTask, hasCheckoutTask } from "../services/task";

/**
 * Manage consultaton status in one context
 */

export const ConsultationContext: React.Context<any> = React.createContext();
ConsultationContext.displayName = "ConsultationContext";

type Props = {
  children: React.Node,
  consultationId: string,
  parent?: string,
};

function ConsultationProvider(props: Props) {
  const { consultationId } = props;
  const [prescribDialogOpen, setPrescribDialogOpen] = React.useState<boolean>(
    false
  );
  const [rejectDialogOpen, setRejectDialogOpen] = React.useState<boolean>(
    false
  );
  const [nochangeDialogOpen, setNochangeDialogOpen] = React.useState<boolean>(
    false
  );
  const [selectedTreatment, setSelectedTreatment] = React.useState<Item | null>(
    null
  );
  const [focusCommentEdit, setFocusCommentEdit] = React.useState<boolean>(
    false
  );
  const [
    focusCommentAnswerEdit,
    setFocusCommentAnswerEdit,
  ] = React.useState<boolean>(false);
  const [updated, setUpdated] = React.useState<boolean>(false);

  const { data: consultation, status, error } = useConsult(consultationId);

  const getParentId = () => {
    if (consultation && consultation.parent) {
      return consultation.parent;
    }

    return "invalid";
  };

  const {
    data: parentConsultation,
    status: parentStatus,
    error: parentError,
  } = useConsult(getParentId());

  if (status === "loading" || parentStatus === "loading") {
    // TODO: skeleton or loading page
    return <PageLoader />;
  }

  if (status === "error" || parentError === "error") {
    if (error) {
      return <Fallback error={error || parentError} />;
    }
  }

  const value = {
    selectedTreatment,
    setSelectedTreatment,
    prescribDialogOpen,
    setPrescribDialogOpen,
    rejectDialogOpen,
    setRejectDialogOpen,
    nochangeDialogOpen,
    setNochangeDialogOpen,
    consultation,
    parentConsultation,
    focusCommentEdit,
    setFocusCommentEdit,
    focusCommentAnswerEdit,
    setFocusCommentAnswerEdit,
    updated,
    setUpdated,
  };

  const task: TaskModel = getTask(consultation);

  if (hasCheckoutTask(consultation)) {
    return (
      <Navigate
        replace
        to={getLocalPath(
          `consultations/${consultation.id}/checkout?task=${task}`
        )}
      />
    );
  }

  // TODO: restore the questionnaire if user stop in the middle of it.
  if (task === Tasks.QUESTIONNAIRE) {
    return (
      <Navigate
        replace
        to={getLocalPath(
          `consultations/${consultation.id}/assessment?reason=${consultation.reason.id}&category=${consultation.category.id}`
        )}
      />
    );
  }

  if (task === Tasks.VERIFY_ID) {
    return (
      <Navigate
        replace
        to={getLocalPath(`consultations/${consultation.id}/verifyid`)}
      />
    );
  }

  return (
    <ConsultationContext.Provider value={value}>
      {props.children}
    </ConsultationContext.Provider>
  );
}

function useConsultation() {
  return React.useContext(ConsultationContext);
}

export { ConsultationProvider, useConsultation };
