// @flow

import { useMutation, useQuery, useQueryCache } from "react-query";
import { findComments, createComment, updateComment } from "../services/api";

import type { Comment, MutationAction } from "../models";

type Options = {
  onSuccess?: Function,
};

export default function useComments(consultationId: string) {
  const fetch = async () => {
    return await findComments(consultationId);
  };

  return useQuery(`comments-${consultationId}`, fetch);
}

export function useCommentMutation(
  consultationId: string,
  action: MutationAction,
  opts?: Options
) {
  const cache = useQueryCache();
  const options = opts || {};

  if (action !== "post" && action !== "put" && action.length !== 0) {
    throw new Error(`Unsupported action '${action}'`);
  }

  return useMutation(
    action === "post" ? createComment : action === "put" ? updateComment : null,
    {
      onSuccess: (newComment: Comment) => {
        const key = `comments-${consultationId}`;

        cache.setQueryData(key, (comments) => {
          const newComments = [...comments];

          if (action === "put") {
            const idx = newComments.findIndex((a) => a.id === newComment.id);

            if (idx > -1) {
              newComments[idx] = newComment;
            } else {
              throw new Error("Comment not found");
            }
          } else if (action === "post") {
            newComments.push(newComment);
          }

          return newComments;
        });

        if (options.onSuccess) {
          options.onSuccess(newComment);
        }
      },
    }
  );
}
