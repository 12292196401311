// @flow

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useConsultation } from "../providers/ConsultationProvider";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import type { Order, Item, TaskRequest, Comment } from "../models";
import { getMedicineItem, getUnpaidOrder } from "../services/order";
import {
  AcceptQuotaTaskRequest,
  AskForChangeTaskRequest,
} from "../models/tasks";
import TreatmentDetails from "./TreatmentDetails";
import OrderSummary from "./OrderSummary";
import { useMutation, useQuery } from "react-query";
import { createComment, findOrders, submitTask } from "../services/api";
import PageLoader from "./PageLoader";
import Fallback from "./Fallback";
import useConsultMutation from "../hooks/useConsultMutation";
import SubmitButton from "./SubmitButton";
import CommentEdit from "./CommentEdit";
import usePopNotifications from "../hooks/usePopNotifications";
import DoctorNote from "./DoctorNote";
import Seperator from "./Seperator";
import { getCurrentTask } from "../services/task";
import ConsultationTaskContent from "../views/ConsultationTaskContent";
import type { ConsultationTask } from "../models";

const useStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(4),
  },
  actions: {
    display: "flex",
    marginTop: theme.spacing(6),
  },
  section: {
    marginTop: theme.spacing(2),
  },
  doctornote: {
    marginBottom: theme.spacing(7),
  },
}));

type Props = {
  description?: string,
};

export default function TreatmentReviewPanel({ description }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { consultation, updated, setUpdated } = useConsultation();
  const currentTask: ConsultationTask = getCurrentTask(consultation);
  const { setNotification } = usePopNotifications();
  const [message, setMessage] = useState(t("message.treatment.confirm"));

  const { data: orders, status, error } = useQuery(
    `orders-${consultation.id}`,
    () => findOrders(consultation.id)
  );

  const handleSubmitTask = (request: TaskRequest) => {
    return submitTask(consultation.id, request);
  };

  const [mutateConsult, { isLoading }] = useConsultMutation(
    consultation.id,
    handleSubmitTask,
    {
      successText: message,
      onSuccess: () => setUpdated(!updated),
    }
  );

  const [mutateComment, mutateCommentState] = useMutation(createComment, {
    onSuccess: (newComment: Comment) => {
      if (!newComment || !newComment.id) {
        return;
      }

      setMessage(t("message.message.confirm"));

      mutateConsult(AskForChangeTaskRequest(newComment.id, newComment.text));
    },
    onError: (err) => {
      setNotification(err.message, "error");
    },
  });

  if (status === "loading" || mutateCommentState.isLoading) {
    return <PageLoader />;
  }

  if (status === "error") {
    return <Fallback error={error} />;
  }

  const order: ?Order = getUnpaidOrder(orders);

  if (!order || !order.items || !order.items.length) {
    return null;
  }

  const item: ?Item = getMedicineItem(order);

  if (!item) {
    return null;
  }

  const handleAccept = async () => {
    try {
      await mutateConsult(AcceptQuotaTaskRequest());
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = async (text: string) => {
    const newComment: Comment = {
      consultationId: consultation.id,
      type: "COMMENT",
      text: text,
      parentId: null,
    };

    return await mutateComment(newComment);
  };

  return (
    <React.Fragment>
      <Box className={classes.doctornote}>
        <DoctorNote note={consultation.note || ""} />
      </Box>
      <TreatmentDetails consultation={consultation} />
      <Seperator />
      <OrderSummary order={order} showSlogan={true} />
      <Grid
        container
        spacing={6}
        direction="column"
        className={classes.actions}
      >
        <Grid item>
          <SubmitButton
            text={t("order.accept.continue")}
            disabled={isLoading}
            onClick={handleAccept}
          />
        </Grid>
        <Grid item>
          <ConsultationTaskContent
            taskId={currentTask.id}
            type="FAQ"
            seperator={true}
            disableExtra={true}
          />
        </Grid>
        <Seperator />
        <Grid item className={classes.asktochange}>
          <Typography variant="body2" component="p">
            {t("consultation.review.askquestion")}
          </Typography>
        </Grid>
        <Grid item>
          <CommentEdit
            primaryText={t("submit")}
            labelText={t("submit")}
            onSaveEdit={handleChange}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
