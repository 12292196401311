import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  Container,
  makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DefaultLayout from "../components/DefaultLayout";
import { getLocalPath, validateEmail } from "../common/utils";
import type { ResetPasswordRequest } from "../models";
import SubmitButton from "../components/SubmitButton";
import { useMutation } from "react-query";
import { resetPassword } from "../services/auth";
import usePopNotifications from "../hooks/usePopNotifications";
import PrimaryLinkButton from "../components/PrimaryLinkButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(6),
  },
  submit: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  description: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
}));

export default function ForgetPasswordPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const emailRef = useRef("");
  const [success, setSucess] = useState(false);
  const { setNotification } = usePopNotifications();

  const getValues = (): ResetPasswordRequest => {
    const email = emailRef.current.value;

    return { email };
  };

  const [mutate, { isLoading }] = useMutation(resetPassword, {
    onSuccess: () => {
      setSucess(true);
    },
    onError: (err) => setNotification(err.message, "error"),
  });

  const validate = () => {
    const email = emailRef.current.value;
    const newErrors = {};

    if (!validateEmail(email)) {
      newErrors.email = t("account.emailerror");
    }

    if (!email || !email.length) {
      newErrors.email = t("account.emptyemail");
    }

    setErrors(newErrors);
  };

  const isValid = () => {
    return (
      emailRef &&
      emailRef.current &&
      emailRef.current.value &&
      emailRef.current.value.length
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValid()) {
      validate();
    } else {
      await mutate(getValues());
    }
  };

  const handleEmailBlur = (e) => {
    validate();
  };

  const SuccessMessages = () => (
    <>
      <Typography variant="body1" component="p" className={classes.description}>
        {t("login.forgetpassword.description")}
      </Typography>
    </>
  );

  return (
    <DefaultLayout title={t("page.forgetpassword")}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="start"
        marginTop="10%"
      >
        <Container maxWidth="sm">
          <form>
            <Box mb={3} className={classes.title}>
              <Typography color="textPrimary" variant="h1">
                {success
                  ? t("login.forgetpassword.check")
                  : t("page.forgetpassword")}
              </Typography>
            </Box>
            {success ? (
              <SuccessMessages />
            ) : (
              <>
                <Typography
                  variant="body1"
                  color="textSecondary"
                  component="p"
                  className={classes.description}
                >
                  {t("login.forgetpassword.hint")}
                </Typography>
                <TextField
                  className={classes.inputfield}
                  error={Boolean(errors.email)}
                  fullWidth
                  helperText={errors.email}
                  label={t("account.emaillabel")}
                  margin="normal"
                  name="email"
                  type="email"
                  variant="outlined"
                  inputRef={emailRef}
                  onBlur={handleEmailBlur}
                />
                <Box my={2} className={classes.submit}>
                  <SubmitButton
                    isLoading={isLoading}
                    onClick={handleSubmit}
                    text={t("continue")}
                    disabled={Boolean(errors.email)}
                    fullWidth={true}
                  />
                </Box>
              </>
            )}
            <PrimaryLinkButton
              text={t("action.gobacklogin")}
              url={getLocalPath("login")}
              startIcon={<ArrowBackIosIcon />}
            />
          </form>
        </Container>
      </Box>
    </DefaultLayout>
  );
}
