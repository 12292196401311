// @flow

import React from "react";

import { makeStyles, Avatar } from "@material-ui/core";
import { deepPurple } from "@material-ui/core/colors";
import { acronym } from "../common/utils";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  small: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    fontSize: theme.spacing(3)
  },
}));

type Props = {
  name: string,
  small?: boolean,
};

export default function UserAvatar({ name, small }: Props) {
  const classes = useStyles();

  return (
    <Avatar
      className={clsx(classes.purple, {
        [classes.small]: small,
      })}
    >
      {acronym(name)}
    </Avatar>
  );
}
