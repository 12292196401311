// @flow

import type { PriceItem, Order, Item, Consultation } from "../models";

type Trans = {
  consultation: string,
};

export function getPriceList(order: Order, trans: Trans): Array<PriceItem> {
  if (!order.items || !order.items.length) {
    return [];
  }

  const getItemName = (item: Item) => {
    if (item.type === "consultation") {
      return trans.consultation;
    }

    return item.displayName || item.name;
  };

  const getItemsPrice = (): Array<PriceItem> =>
    order.items.map((item) => ({ name: getItemName(item), price: item.total }));

  return getItemsPrice().concat({
    name: "Total",
    isTotal: true,
    price: order.total,
  });
}

export function getUnpaidOrder(orders: Array<Order>) {
  return orders.find((o) => !o.invalid && o.status === "UNPAID");
}

export function getLastPaidOrder(orders: Array<Order>) {
  return orders
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    .find((o) => !o.invalid && o.status === "PAID");
}

export function getPaidOrders(orders: Array<Order>): Array<Order> {
  return orders
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    .filter((o) => !o.invalid && o.status === "PAID");
}

export function getMedicineItem(order: Order) {
  return order.items.find((item) => item.type === "medicine");
}

export function getTreatmentFromOrders(orders: Array<Order>): ?Item {
  const medicineOrder = findTreatmentOrder(orders);

  if (!medicineOrder) {
    return null;
  }

  return getMedicineItem(medicineOrder);
}

export function findTreatmentOrder(orders: Array<Order>): ?Order {
  const order = orders
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    .find(
      (o) =>
        !o.invalid && o.items.findIndex((item) => item.type === "medicine") > -1
    );

  return order;
}

export function getAvailableOrders(
  consultation: Consultation,
  parent: Consultation
) {
  if (!parent) {
    return consultation.orders;
  }

  return consultation.orders.length > 0 ? consultation.orders : parent.orders;
}

export function getTreatmentOrder(consultation: Consultation): ?Order {
  if (!consultation.orders || !consultation.orders.length) {
    return null;
  }

  return findTreatmentOrder(consultation.orders);
}
