/* eslint-disable react-hooks/exhaustive-deps */
// @flow

import React, { useEffect, useRef } from "react";

type Props = {
  onSuccess: Function,
  onFailed: Function,
};

export function Verify({ onSuccess, onFailed }: Props) {
  const idRef = useRef();

  useEffect(() => {
    const script = document.createElement("script");

    script.src = process.env.REACT_APP_DIGITAL_ID_URL || "https://digitalid-sandbox.com/sdk/app.js";
    script.async = true;

    if (!document.body) {
      return;
    }

    document.body.appendChild(script);

    script.onload = () => {
      /* Verify with Digital iD */
      window.digitalId.init({
        clientId: process.env.REACT_APP_DIGITAL_ID_CLIENT_ID,
        uxMode: "popup",
        onLoadComplete: function () {},
        onComplete: function (response) {
          console.log(`Grant code: ${response.code}`);
          // Transaction id can be sent your backend for tracking/auditing purposes, or else can be ignored if not required.
          console.log(`Transaction id: ${response.transaction_id}`);

          if (!response.error) {
            onSuccess({
              code: response.code,
              transactionId: response.transaction_id,
            });
          } else {
            const { error, error_description } = response;

            onFailed(new Error(`${error}: ${error_description}`));
          }
        },
        onClick: function (opts) {},
        onKeepAlive: function () {},
      });
    };

    return () => {
      document.body && document.body.removeChild(script);
    };
  }, []);

  return <div ref={idRef} id="digitalid-verify"></div>;
}
