// @flow

import React, { useState, useEffect } from "react";

import { FormControl, FormHelperText, InputLabel, makeStyles, Select } from "@material-ui/core";

import type { SelectOption } from "../models";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

type Props = {
  required: boolean,
  onChange: Function,
  value?: string,
  label: string,
  name: string,
  options: Array<SelectOption>,
  errorText?: string,
  autoComplete?: string
};

export default function CustomSelect(props: Props) {
  const classes = useStyles();
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleChange = (e) => {
    setValue(e.target.value);

    props.onChange(e);
  };

  return (
    <FormControl
      required={props.required}
      variant="outlined"
      className={classes.root}
    >
      <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
      <Select
        autoComplete={props.autoComplete}
        native
        label={props.label}
        value={value}
        onChange={handleChange}
        inputProps={{ name: props.name, id: props.name }}
      >
        <option aria-label="None" value="" />
        {props.options.map((opt, index) => (
          <option value={opt.value} key={`option_${index}`}>
            {opt.label}
          </option>
        ))}
      </Select>
      <FormHelperText>{props.errorText}</FormHelperText>
    </FormControl>
  );
}
