// @flow

import * as React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import type {
  TaskModel,
  ConsultationTask,
  ConsultationActionModel,
} from "../models";
import { Tasks } from "../models/enums";
import { getTask, getCurrentTask } from "../services/task";
import { useConsultation } from "../providers/ConsultationProvider";
import TreatmentReviewPanel from "./TreatmentReviewPanel";
import { getUnpaidOrder } from "../services/order";
import { findOrders } from "../services/api";
import PageLoader from "./PageLoader";
import Fallback from "./Fallback";
import RejectedConsultationPanel from "./RejectedConsultationPanel";
import { fade, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import PrescribePanel from "./PrescribePanel";
import TreatmentDetails from "./TreatmentDetails";
import ConsultationTaskFormAction from "./ConsultationTaskFormAction";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
    background: fade(theme.palette.warning.main, 0.2),
    border: `1px solid ${theme.palette.warning.main}`,
    marginBottom: theme.spacing(6),
  },
  title: {
    marginBottom: theme.spacing(6),
  },
  description: {
    marginBottom: theme.spacing(6),
  },
}));

export default function ConsultationTaskPanel() {
  const { consultation, setFocusCommentAnswerEdit } = useConsultation();
  const { t } = useTranslation();
  const classes = useStyles();
  const task: TaskModel = getTask(consultation);
  const currentTask: ConsultationTask = getCurrentTask(consultation);

  const fetchData = () => {
    if (task === Tasks.ACCEPT_QUOTA) {
      return findOrders(consultation.id);
    }

    return Promise.resolve();
  };

  const { data, status, error } = useQuery(
    `${task}-${consultation.id}`,
    fetchData
  );

  if (status === "loading") {
    return <PageLoader />;
  }

  if (status === "error") {
    return <Fallback error={error} />;
  }

  const DescriptionRenderer = () => {
    switch (task) {
      case Tasks.ACCEPT_QUOTA:
        return (
          <TreatmentReviewPanel
            order={getUnpaidOrder(data)}
            description={currentTask.description}
          />
        );

      case Tasks.APPROVED:
        return <TreatmentDetails consultation={consultation} />;

      case Tasks.REJECTED:
        return <RejectedConsultationPanel />;

      default:
        return (
          <Typography variant="body2" component="div" color="textSecondary">
            {currentTask.description}
          </Typography>
        );
    }
  };

  const getConsultationAction = (): ConsultationActionModel => {
    switch (task) {
      case Tasks.ANSWER_QUESTIONS:
        return {
          type: "default",
          actions: [
            {
              label: t("consultation.answer.button"),
              primary: true,
              onClick: () => setFocusCommentAnswerEdit(true),
            },
          ],
        };

      case Tasks.ASSIGN_CONSULTATION:
        return {
          type: "default",
          actions: [
            {
              label: t("admin.assign"),
              primary: true,
              onClick: () => {},
            },
          ],
        };

      case Tasks.REVIEW_CONSULTATION:
        return {
          type: "custom",
          render: () => <PrescribePanel />,
        };

      default:
        return { type: "none" };
    }
  };

  if (!currentTask) {
    return null;
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container direction="column">
        <Grid item container direction="row" className={classes.title}>
          <Grid item xs={12} sm={2}>
            <Typography variant="h4" component="h2">
              {t("consultation.status")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography variant="body1" component="div">
              {currentTask.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" className={classes.description}>
          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={12} sm={10}>
            <DescriptionRenderer />
          </Grid>
        </Grid>
        <Grid item container direction="row" alignItems="baseline">
          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={12} sm={10}>
            <ConsultationTaskFormAction
              isLoading={false}
              consultationAction={getConsultationAction()}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
