// @flow
import React from "react";

import { useTranslation } from "react-i18next";
import type { User } from "../models/user";
import { findUsers } from "../services/api";
import ItemList from "./ItemList";
import { useQuery } from "react-query";

type Props = {
  onSelect: Function,
};

export default function AssigneePicker(props: Props) {
  const { t } = useTranslation();
  const { error, status, data: users } = useQuery("list-doctors", () => findUsers("doctor"));

  return (
    <ItemList
      onSelect={props.onSelect}
      searchPlaceHolder={t("searchdoctors")}
      title={t("selectdoctor")}
      errorMessage={error && error.message}
      loading={status === "loading"}
      items={users ? users.map((user: User) => ({name: user.displayName, object: user})) : []}
    />
  );
}
