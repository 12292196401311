// @flow

import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(2)
  }
}));

type Props = {
  name: string,
  text: string,
};

export default function TreatmentSection({ name, text }: Props) {
  const classes = useStyles();

  return (
    <Grid
      item
      container
      direction="column"
      className={classes.section}
      spacing={2}
    >
      <Grid item className={classes.title}>
        <Typography variant="h3" component="h4">
          {name}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" component="p">
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
}
