// @flow
import * as React from "react";
import {
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import PageviewIcon from "@material-ui/icons/Pageview";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import EmailIcon from "@material-ui/icons/Email";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import type { TaskContent } from "../models";
import ConsultationTaskContentAction from "./ConsultationTaskContentAction";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

type Props = {
  content: TaskContent,
};

type IconProps = {
  iconName: string,
};

export default function ConsultationTaskContentListItem({ content }: Props) {
  const { title, text, expandable, defaultExpand, highlight } = content;
  const [open, setOpen] = React.useState(defaultExpand || highlight);
  const classes = useStyles();

  const Icon = ({ iconName }: IconProps) => {
    switch (iconName) {
      case "hourglass_empty":
        return <HourglassEmptyIcon fontSize="large" color="primary" />;
      case "view":
        return <PageviewIcon fontSize="large" color="primary" />;
      case "live_help":
        return <LiveHelpIcon fontSize="large" color="primary" />;
      case "email":
        return <EmailIcon fontSize="large" color="primary" />;
      default:
        return null;
    }
  };

  const Title = () => (
    <Typography variant="body1" component="div" className={classes.title}>
      {title}
    </Typography>
  );

  const Content = () => {
    if (!title) {
      return <ListItemText primary={text} />;
    }

    if (!expandable) {
      return <ListItemText primary={<Title />} secondary={text} />;
    }

    return <ListItemText primary={<Title />} />;
  };

  const ExpandIcon = () => {
    if (!expandable) {
      return null;
    }

    return open ? <ExpandLess /> : <ExpandMore />;
  };

  return (
    <React.Fragment>
      <ListItem
        disableGutters={true}
        alignItems="center"
        button={expandable}
        onClick={() => (expandable ? setOpen(!open) : {})}
      >
        {content.iconName ? (
          <ListItemIcon>
            <Icon iconName={content.iconName} />
          </ListItemIcon>
        ) : null}
        <Content content={content} />
        <ExpandIcon />
      </ListItem>
      {expandable ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Typography variant="body1" component="p">
            {text}
          </Typography>
        </Collapse>
      ) : null}
      {content.action ? (
        <ConsultationTaskContentAction
          action={content.action}
          actionName={content.actionName}
        />
      ) : null}
    </React.Fragment>
  );
}
