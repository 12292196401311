// @flow
import React, { useState, useEffect } from "react";

import {
  Grid,
  makeStyles,
  fade,
  InputBase,
  Typography,
  List,
  ListItem,
  ListItemText,
  Container,
  Box,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import ErrorTypography from "./ErrorTypography";
import type { SelectItem } from "../models";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "18ch",
    },
  },
  selecttitle: {
    marginTop: theme.spacing(2),
  },
  list: {
    maxHeight: 250,
    overflow: "auto",
  },
}));

type Props = {
  onSelect: Function,
  searchPlaceHolder: string,
  title: string,
  errorMessage?: string,
  loading: boolean,
  items?: Array<SelectItem>,
};

export default function ItemList(props: Props) {
  const classes = useStyles();
  const [ selected, setSelected ] = useState("");
  const [ filterItems, setFilterItems ] = useState(props.items);

  useEffect(() => {
    setFilterItems(props.items);
  }, [props.items])

  const handleClick = (item: SelectItem) => {
    setSelected(item.name);

    props.onSelect(item.object);
  };

  const handleOnSearchChange = (text: string) => {
    const _items = (props.items || []).filter(item => item.name.indexOf(text) > -1);

    setFilterItems(_items);
  };

  return (
    <Container className={classes.root}>
      <Grid container direction="column" justify="center">
        <Grid item className={classes.search}>
          <Box border={2} borderColor="grey.400" borderRadius="borderRadius">
            <InputBase
              placeholder={props.searchPlaceHolder}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={e => handleOnSearchChange(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item className={classes.selecttitle}>
          <Typography variant="overline" component="div">
            {props.title}
          </Typography>
        </Grid>
        <Grid item>
          {props.errorMessage ? (
            <ErrorTypography message={props.errorMessage} />
          ) : (
            <List className={classes.list}>
              {props.loading
                ? Array.from(new Array(5)).map((item, idx) => (
                    <ListItem button disableGutters key={`skeleton_${idx}`}>
                      <Skeleton variant="rect" width="100" height={20} />
                    </ListItem>
                  ))
                : (filterItems || []).map((item: SelectItem, idx) => (
                    <ListItem
                      button
                      selected={item.name === selected}
                      disableGutters
                      key={`item_${idx}`}
                      onClick={() => handleClick(item)}
                    >
                      <ListItemText primary={item.name} />
                    </ListItem>
                  ))}
            </List>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
