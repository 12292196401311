// @flow

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";

import DialogContainer from "./DialogContainer";
import { useConsultation } from "../providers/ConsultationProvider";
import { reviewConsult } from "../services/api";
import useConsultMutation from "../hooks/useConsultMutation";
import TreatmentSection from "./TreatmentSection";
import { getTreatmentOrder } from "../services/order";
import { useNavigate } from "react-router-dom";
import { getLocalPath } from "../common/utils";
import RichTextField from "./RichTextField";
import { ReviewType } from "../models/enums";

const useStyles = makeStyles((theme) => ({
  controls: {
    marginBottom: theme.spacing(2),
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
  },
  noteTitle: {
    marginBottom: theme.spacing(4),
  },
  editbox: {},
}));

type Props = {};

export default function PrescribeDialog(props: Props) {
  const {
    consultation,
    parentConsultation,
    selectedTreatment,
    prescribDialogOpen,
    setPrescribDialogOpen,
  } = useConsultation();
  const { t } = useTranslation();
  const classes = useStyles();
  const order = getTreatmentOrder(consultation);
  const navigate = useNavigate();

  const getExistingNote = () => {
    if (consultation.note) {
      return consultation.note;
    }

    if (parentConsultation && parentConsultation.note) {
      return parentConsultation.note;
    }

    return "";
  };

  const [note, setNote] = useState(getExistingNote());

  const createPrescription = () => {
    return reviewConsult(consultation.id, {
      type: ReviewType.APPROVE,
      text: note,
      itemId: selectedTreatment.id,
      orderId: order ? order.id : undefined
    });
  };

  const [mutate, { error, isLoading }] = useConsultMutation(
    consultation.id,
    createPrescription,
    {
      onSuccess: (data) => {
        handleClose(true);
      },
    }
  );

  const handleClose = (backToHome: boolean) => {
    setPrescribDialogOpen(false);

    if (backToHome) {
      navigate(getLocalPath("doctor"));
    }
  };

  const handlePrescribe = async () => {
    try {
      await mutate();
    } catch (err) {
      console.error(err);
    }
  };

  const handleNoteChange = (value) => {
    setNote(value);
  };

  const ActionsRenderer = () => {
    return (
      <React.Fragment>
        <Button onClick={() => handleClose(false)} disableElevation>
          {t("cancel")}
        </Button>
        <Button
          onClick={handlePrescribe}
          color="primary"
          variant="contained"
          disabled={isLoading || !note.length}
        >
          {t("consultation.prescribe")}
        </Button>
      </React.Fragment>
    );
  };

  if (!selectedTreatment) {
    return null;
  }

  return (
    <DialogContainer
      error={error}
      title={t("consultation.prescription")}
      open={prescribDialogOpen}
      actionRenderer={ActionsRenderer}
    >
      <Grid
        container
        spacing={2}
        className={classes.controls}
        direction="column"
      >
        <TreatmentSection name={t("treatment")} text={selectedTreatment.name} />
        <TreatmentSection
          name={t("usage")}
          text={selectedTreatment.description}
        />
        <Grid item className={classes.editbox}>
          <Typography
            variant="overline"
            component="div"
            className={classes.noteTitle}
          >
            {`${t("consultation.doctornotes")}: `}
          </Typography>
          <RichTextField
            defaultValue={consultation.note}
            height={300}
            onChange={handleNoteChange}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
}
