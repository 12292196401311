// @flow
import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Modal,
  Grid,
  Button,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import type { Question } from "../../models";
import ImagePreview from "./ImagePreview";
import CheckIcon from "@material-ui/icons/Check";
import { imageURItoBlob, mobileCheck } from "../../common/utils";
import OpenFileButton from "../OpenFileButton";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  inputWrapper: {
    padding: `${theme.spacing(2)}px 0`,
  },
  inputLabel: {
    width: "100%",
  },
  imageButton: {
    width: "100%",
  },
  imageInput: {
    display: "none",
  },
  imageButtonSmall: {},
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actions: {
    marginBottom: theme.spacing(4),
  },
}));

type Props = {
  onChange: Function,
  question: Question,
};

const isMobile = mobileCheck();

export default function ImagePicker(props: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [preview, setPreview] = useState("");
  const { imageUrl, description, id } = props.question;

  useEffect(() => {
    setPreview("");
  }, [id]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    // convert file to a preview blob url
    const preview = URL.createObjectURL(files[0]);

    setPreview(preview);

    props.onChange(files[0]);
  };

  const handleTakePhoto = (uri) => {
    setPreview(uri);
    setOpen(false);

    const blob = imageURItoBlob(uri);

    props.onChange(blob);
  };

  const getImageUrl = () => {
    if (preview.length > 0) {
      return preview;
    } else if (imageUrl) {
      return imageUrl;
    }

    return null;
  };

  const SelectFileButton = (reselect?: boolean) => {
    return (
      <OpenFileButton
        fullSize={!reselect}
        text={reselect ? t("survey.reselect") : t("survey.selectphoto")}
        onChange={handleFileSelect}
      />
    );
  };

  const TakePhotoButton = (retake?: boolean) => {
    return (
      <Button
        className={classes.imageButton}
        variant="outlined"
        component="label"
        color="primary"
        onClick={handleOpen}
        startIcon={<CameraAltIcon />}
      >
        {retake ? t("survey.retake") : t("survey.takephoto")}
      </Button>
    );
  };

  const imgUrl = getImageUrl();

  const tips = [
    t("survey.phototip1"),
    t("survey.phototip2"),
    t("survey.phototip3"),
  ];

  const Actions = () => {
    return (
      <React.Fragment>
        <Grid
          container
          spacing={3}
          justify="space-between"
          className={classes.actions}
        >
          <Grid item xs={isMobile ? 12 : 6}>
            {SelectFileButton()}
          </Grid>
          {isMobile ? null : (
            <Grid item xs={6}>
              {TakePhotoButton()}
            </Grid>
          )}
        </Grid>
        <Typography variant="body2" component="p" gutterBottom>
          {t("survey.phototip")}
        </Typography>
        <List dense={true}>
          {tips.map((tip, idx) => (
            <ListItem key={`item_${idx}`}>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText primary={tip} />
            </ListItem>
          ))}
        </List>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {imgUrl ? (
        <ImagePreview imageUrl={imgUrl} description={description} />
      ) : null}
      <Actions />
      <Modal
        className={classes.modal}
        open={open}
        aria-labelledby="picture-camera"
        aria-describedby="The camera to take picture"
      >
        <Camera onTakePhotoAnimationDone={handleTakePhoto} />
      </Modal>
    </React.Fragment>
  );
}
