// @flow
import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Letter from "./Letter";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
}));

type Props = {
  note: string,
};

export default function DoctorNote({ note }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Typography
        variant="h4"
        component="h5"
        gutterBottom
        className={classes.title}
      >
        {t("consultation.doctornotes")}
      </Typography>
      <Letter note={note} />
    </React.Fragment>
  );
}
