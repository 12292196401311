// @flow
import { Button } from "@material-ui/core";
import * as React from "react";
import { getMedicineItem, getLastPaidOrder } from "../services/order";
import type { Order } from "../models";
import { useNavigate } from "react-router-dom";
import { useConsultation } from "../providers/ConsultationProvider";
import { getLocalPath } from "../common/utils";

type Props = {
  action?: string,
  actionName?: string,
};

export default function ConsultationTaskContentAction({
  action,
  actionName,
}: Props) {
  // this might not work... find a better way to get consultation
  const { consultation } = useConsultation();
  const order: ?Order = getLastPaidOrder(consultation.orders);
  const item = order ? getMedicineItem(order) : null;
  const navigate = useNavigate();

  if (!action || !actionName) {
    return null;
  }

  const canRefill = () => {
    return item && item.repeat > 0;
  };

  const isActionValid = () => {
    if (action === "refill") {
      return canRefill();
    }

    return true;
  };

  const getActionName = () => {
    if (action === "refill") {
      const repeat = item ? item.repeat : 0;

      return `${actionName} (RPT: ${repeat})`;
    }

    return actionName;
  };

  const handleAction = () => {
    if (action === "refill") {
      return navigate(getLocalPath(`/consultations/${consultation.id}/refill`));
    }

    if (action === "followup") {
      return navigate(
        getLocalPath(
          `entry/${consultation.category.id}?reason=followup&parent=${consultation.id}`
        )
      );
    }
  };

  return (
    <Button
      color="primary"
      variant="contained"
      disableElevation
      disabled={!isActionValid()}
      onClick={handleAction}
    >
      {getActionName()}
    </Button>
  );
}
