import React from "react";

import RouteConfig from "./routes";
import GlobalStyle from "./components/GlobalStyle";
import PageLoader from "./components/PageLoader";
import PopNotifier from "./components/PopNotifier";
import { BrowserRouter as Router } from "react-router-dom";

export default function App() {
  return (
    <React.Suspense fallback={<PageLoader />}>
      <GlobalStyle />
      <Router>
        <PopNotifier />
        <RouteConfig />
      </Router>
    </React.Suspense>
  );
}
