// @flow
import { Grid, makeStyles } from "@material-ui/core";
import * as React from "react";
import DefaultLayout from "./DefaultLayout";

// used by displaing error or results

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
}));

type Props = {
  title: string,
  children: React.Node,
};

export default function ResultLayout({ title, children }: Props) {
  const classes = useStyles();
  return (
    <DefaultLayout title={title} maxHeight={true}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.root}
      >
        {children}
      </Grid>
    </DefaultLayout>
  );
}
