// @flow
import React, { useState, useEffect } from "react";

import { Navigate } from "react-router-dom";
import { getCategories } from "../services/api";
import { useTranslation } from 'react-i18next';
import useAPIError from "../hooks/useAPIError";
import CategoryList from "../components/CategoryList";
import DefaultLayout from "../components/DefaultLayout";
import { getLocalPath } from "../common/utils";

export default function EntryPage() {
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(true);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(null);

  const { addError } = useAPIError();

  const handleSelectCategory = (catId) => {
    setCategoryId(catId);
  }

  useEffect(() => {
    async function fetch() {
      try {
        const data = await getCategories();

        setCategories(data);
      } catch (err) {
        addError(`Load categories error: ${err}`, 451);
      }

      setFetching(false);
    }

    fetch();
  }, [addError]);

  if (categoryId) {
    return (
      <Navigate push to={getLocalPath(`/entry/${categoryId}`)} />
    );
  }

  if (fetching) {
    return (<div>Fetching categories...</div>);
  }

  return (
    <DefaultLayout title={t('page.entry')}>
      <CategoryList categories={categories} onCategoryChange={handleSelectCategory} />
    </DefaultLayout>
  );
}