
import React from "react";
import { Grid, Typography, makeStyles } from '@material-ui/core';

type Props = {
  title: string,
  children: React.Node
};

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
  },
  title: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(6)
  }
}));

export default function FormContainer({ title, children }: Props) {
  const classes = useStyles();

  return (
    <Grid item sm={12} md={6} className={classes.container}>
      <Typography variant="h2" component="h2" className={classes.title}>
        {title}
      </Typography>
      {children}
    </Grid>
  )
}


