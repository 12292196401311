// @flow

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";

import DialogContainer from "./DialogContainer";
import { useConsultation } from "../providers/ConsultationProvider";
import { reviewConsult } from "../services/api";
import useConsultMutation from "../hooks/useConsultMutation";
import { useNavigate } from "react-router-dom";
import { getLocalPath } from "../common/utils";
import RichTextField from "./RichTextField";
import { ReviewType } from "../models/enums";

const useStyles = makeStyles((theme) => ({
  controls: {
    marginBottom: theme.spacing(2),
  },
  editbox: {},
}));

type Props = {};

export default function NoChangeDialog(props: Props) {
  const {
    consultation,
    nochangeDialogOpen,
    setNochangeDialogOpen,
  } = useConsultation();
  const { t } = useTranslation();
  const classes = useStyles();
  const [note, setNote] = useState("");
  const navigate = useNavigate();

  const keepPrescription = () => {
    return reviewConsult(consultation.id, {
      type: ReviewType.NOCHANGE,
      text: note,
    });
  };

  const [mutate, { error, isLoading }] = useConsultMutation(
    consultation.id,
    keepPrescription,
    {
      onSuccess: () => {
        handleClose();
      }
    }
  );

  const handleClose = () => {
    setNochangeDialogOpen(false);

    navigate(getLocalPath("doctor"));
  };

  const handleReject = async () => {
    await mutate();
  };

  const handleNoteChange = (value) => {
    setNote(value);
  };

  const ActionsRenderer = () => {
    return (
      <React.Fragment>
        <Button onClick={handleClose} disableElevation>
          {t("cancel")}
        </Button>
        <Button
          onClick={handleReject}
          color="primary"
          variant="contained"
          disabled={isLoading || !note.length}
        >
          {t("consultation.nochange")}
        </Button>
      </React.Fragment>
    );
  };

  return (
    <DialogContainer
      error={error}
      title={t("consultation.nochange")}
      open={nochangeDialogOpen}
      actionRenderer={ActionsRenderer}
    >
      <Grid
        container
        spacing={2}
        className={classes.controls}
        direction="column"
      >
        <Grid item className={classes.editbox}>
          <Typography variant="h5" component="h4" gutterBottom>
            {`${t("consultation.doctornotes")}: `}
          </Typography>
          <RichTextField
            defaultValue={consultation.note}
            height={300}
            onChange={handleNoteChange}
          />
        </Grid>
      </Grid>
    </DialogContainer>
  );
}
