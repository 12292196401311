import React, { useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Box,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Logo from "./Logo";
import { stateContext } from "../providers/StateProvider";
import { isAssessmentPage } from "../common/utils";
import { MenuBar } from "./MenuBar";
import { useAuth } from "../providers/AuthProvider";
import { LANDING_URL } from "../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.paper,
  },
  toolbar: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    height: 80,
    justifyContent: "space-between",
  },
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const { globalState } = useContext(stateContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { questionIndex } = globalState;
  const isAssessment = isAssessmentPage(location.pathname);
  const isBackButtonVisable = isAssessment && questionIndex > 0;
  const { user } = useAuth();

  const BackButton = () => {
    if (!isBackButtonVisable) {
      return null;
    }

    return (
      <Box>
        <IconButton
          edge="start"
          className={classes.backButton}
          color="primary"
          aria-label="back"
          onClick={() => navigate(-1)}
        >
          <ArrowBackIosIcon />
        </IconButton>
      </Box>
    );
  };

  const LogoRoute = ({ children }) => {
    if (user) {
      return <Link to="/">{children}</Link>;
    }

    return <a href={LANDING_URL}>{children}</a>;
  };

  const LogoLink = () => (
    <LogoRoute>
      <Logo />
    </LogoRoute>
  );

  return (
    <AppBar className={clsx(classes.root, className)} elevation={1} {...rest}>
      <Toolbar className={classes.toolbar}>
        <BackButton />
        <LogoLink />
        <MenuBar disableHome={isBackButtonVisable} />
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
