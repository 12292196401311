// @flow

import { useMutation, useQuery, useQueryCache } from "react-query";
import { findNotes } from "../services/api";
import usePopNotifications from "./usePopNotifications";

const getKey = (userId) => `notes-${userId}`;

export default function useNotes(userId: string) {
  return useQuery(getKey(userId), () => findNotes(userId));
}

export function useNotesMutation(mutate: Function, userId: string) {
  const cache = useQueryCache();
  const { setNotification } = usePopNotifications();

  return useMutation(mutate, {
    onSuccess: (newNote) => {
      const key = getKey(userId);

      cache.setQueryData(key, (notes) => {
        const newNotes = [...notes];

        const idx = newNotes.findIndex((n) => n.id === newNote.id);

        if (idx > -1) {
          newNotes[idx] = newNote;
        } else {
          newNotes.push(newNote);
        }

        return newNotes;
      });

      setNotification("Create note successfully", "success");
    },
    onError: (err) => {
      setNotification(err.message, "error");
    },
  });
}
