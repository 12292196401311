// @flow

import * as React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Container,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Box,
} from "@material-ui/core";
import type { Category } from "../models";

type Props = {
  categories: Array<Category>,
  onCategoryChange: Function,
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    color: theme.palette.text.primary,
  },
  container: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(7),
  },
  cardMedia: {
    backgroundSize: "contain",
    paddingTop: "67.25%",
  },
  cardContent: {
    flexGrow: 1,
    textAlign: "center",
  },
}));

export default function CategoryList(props: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const categoryItems = props.categories.map((category, i) => (
    <Grid item key={`category_${i}`} xs={12} sm={6} md={4}>
      <Card key={i} className={classes.root}>
        <CardActionArea
          onClick={() => {
            props.onCategoryChange(category.id);
          }}
        >
          <CardMedia
            className={classes.cardMedia}
            image={category.imageUrl}
            title={category.description}
          />
          <CardContent className={classes.cardContent}>
            <Typography variant="h4" component="h2">
              {category.name}
            </Typography>
            <Box display="none">
              <Typography variant="body2" component="p">
                {category.description}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  ));

  return (
    <Container fixed className={classes.container}>
      <Typography variant="h2" component="h1">
        {t("categorylist.title")}
      </Typography>
      <Typography
        className={classes.subtitle}
        variant="h4"
        component="h2"
      >
        {t("categorylist.subtitle")}
      </Typography>
      <Grid container spacing={4}>
        {categoryItems}
      </Grid>
    </Container>
  );
}
