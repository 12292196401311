import React from "react";
import {
  Grid,
  Button,
  makeStyles,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    padding: "1rem 2rem",
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: 8,
  },
  buttonProgress: {
    color: theme.palette.grey,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

type Props = {
  text: string,
  isValid: Boolean,
  onClick: Function,
  disabled: Boolean,
  fullWidth?: Boolean,
  className?: string
};

export default function SubmitButton(props: Props) {
  const classes = useStyles();
  const fullWidth = props.fullWidth === undefined ? true : props.fullWidth;

  return (
    <Grid item xs={12} className={clsx(classes.root, props.className)}>
      <Button
        color="primary"
        className={classes.button}
        variant="contained"
        type="submit"
        fullWidth={fullWidth}
        disableElevation
        disabled={props.disabled || props.isLoading}
        onClick={(e) => props.onClick(e)}
      >
        <Typography variant="body1" component="span">
          {props.text}
        </Typography>
      </Button>
      {props.isLoading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Grid>
  );
}
