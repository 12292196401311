import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import DefaultLayout from "../components/DefaultLayout";
import AddressForm from "../components/AddressForm";
import { saveAddress, updateAddress } from "../services/api";
import FormContainer from "../components/FormContainer";
import { useMutation } from "react-query";
import type { Address } from "../models";
import { getLocalPath } from "../common/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
  },
  checkbox: {
    marginTop: theme.spacing(2),
  },
  alert: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  onSubmit: Function,
  defaultAddress: Address,
};

export default function ShippingDetailsPage({
  onSubmit,
  defaultAddress,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id } = useParams();

  const [mutate, { isLoading, isSuccess, error }] = useMutation((addressData) => {
    if (defaultAddress) {
      return updateAddress(defaultAddress.id, addressData);
    }

    return saveAddress(addressData);
  });

  const handleSubmit = async (addressData: Address) => {
    try {
      const address = await mutate(addressData);

      onSubmit(address);
    } catch (err) {
      console.error(err);
    }
  };

  if (isSuccess) {
    return <Navigate push to={getLocalPath(`/consultations/${id}`)} />;
  }

  return (
    <DefaultLayout title={t("page.shipping")}>
      <Container className={classes.root}>
        <FormContainer title={t("address.title")}>
          <AddressForm onSubmit={handleSubmit} isLoading={isLoading} />
        </FormContainer>
      </Container>
      {error ? (
        <Alert className={classes.alert} severity="error">
          {`${error.status} ${error.error} - ${error.message}`}
        </Alert>
      ) : null}
    </DefaultLayout>
  );
}
