// @flow

import React from "react";
import { makeStyles, Grid, Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useQuery } from "react-query";
import { findConsultations } from "../services/api";
import ConsultationList from "../components/ConsultationList";
import ErrorTypography from "../components/ErrorTypography";
import type { StatusModel } from "../models";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

type Props = {
  consultationStatus: StatusModel,
};

export default function ConsultationsListView({ consultationStatus }: Props) {
  const classes = useStyles();
  const {
    data: consultations,
    status,
    error,
  } = useQuery(`consultations-${consultationStatus}`, () => findConsultations(consultationStatus));

  if (status === "error") {
    return <ErrorTypography message={error.message} />;
  }

  return (
    <Grid container spacing={2} direction="column" className={classes.root}>
      <Grid item>
        {status === "loading" ? (
          Array.from(new Array(10)).map((item, idx) => (
            <Box marginBottom={1} key={`skeleton_${idx}`}>
              <Skeleton variant="rect" width="100%" height={20} />
            </Box>
          ))
        ) : (
          <ConsultationList consultations={consultations} selectable={false} />
        )}
      </Grid>
    </Grid>
  );
}
