// @flow
import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Box,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Field from "./Field";
import type { Question, Answer } from "../../models";
import { useTranslation } from "react-i18next";
import SubmitButton from "../SubmitButton";
import ImagePreview from "./ImagePreview";
import HtmlComponent from "../HtmlComponent";

type Props = {
  onChange: Function,
  question: Question,
  answer: Answer,
  isLoading?: boolean,
  isLastStep: boolean,
  currentIndex: number,
  totalQuestions: number,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 600,
    height: "50%",
  },
  form: {
    height: "100%",
  },
  container: {
    height: "100%",
  },
  progress: {
    marginBottom: theme.spacing(4),
  },
  label: {
    width: "100%",
  },
  title: {
    marginBottom: theme.spacing(7),
  },
  hint: {
    marginBottom: theme.spacing(6),
  },
  buttonContainer: {
    marginTop: "2rem",
  },
  button: {
    width: "100%",
    padding: "1rem 2rem",
  },
  submit: {
    marginTop: theme.spacing(7),
  },
}));

const Assessment = React.forwardRef<Props, Paper>((props, ref) => {
  const classes = useStyles();
  const {
    question,
    isLoading,
    isLastStep,
    currentIndex,
    totalQuestions,
  } = props;
  const [answer, setAnswer] = useState(Object.assign({}, props.answer));

  const { t } = useTranslation();

  const getAnswer = () => {
    if (answer.questionId === -1 && !question.required) {
      return {
        consultationId: "",
        questionId: question.id,
        numAnswer: 0,
        textAnswer: "",
        booleanAnswer: false,
        choices: [],
      };
    }

    return answer.questionId === question.id
      ? answer
      : Object.assign({}, props.answer);
  };

  const isAnswerValid = (newAnswer) => {
    if (!question.required) {
      return true;
    }

    const val = newAnswer || getAnswer();

    if (question.inputType === "PICTURE") {
      return val.blob || val.textAnswer;
    }

    if (question.inputType === "DATE" || question.inputType === "TEXT") {
      return val.textAnswer && val.textAnswer.length > 0;
    }

    if (question.inputType === "NUMBER") {
      return val.numAnswer;
    }

    if (
      question.inputType === "SINGLE_SELECTION" ||
      question.inputType === "MULTIPLE_SELECTION"
    ) {
      const hasChoice: boolean = val.choices && val.choices.length > 0;

      if (!question.acceptChoice) {
        return hasChoice;
      }

      return hasChoice && val.choices.indexOf(question.acceptChoice) > -1;
    }

    return false;
  };

  useEffect(() => {
    setAnswer(Object.assign({}, props.answer));
  }, [props.answer]);

  const handleSubmit = (e) => {
    e.preventDefault();

    props.onChange(getAnswer());
  };

  const handleFieldChange = (newAnswer) => {
    if (
      question.inputType === "SINGLE_SELECTION" &&
      isAnswerValid(newAnswer) &&
      !question.acceptChoice
    ) {
      props.onChange(newAnswer);
    } else {
      setAnswer(newAnswer);
    }
  };

  const getBottomNavText = () => {
    if (isLastStep) {
      return t("submit");
    }

    if (question.inputType === "PICTURE") {
      return t("survey.useit");
    }

    if (question.inputType === "SINGLE_SELECTION" && isAnswerValid()) {
      // need confirm button if single selection has already selected
      return t("survey.confirm");
    }

    return t("survey.next");
  };

  const ProgressBar = () => (
    <Typography variant="body1" component="div" color="textSecondary">
      {`${currentIndex} / ${totalQuestions}`}
    </Typography>
  );

  const BottomNav = () => {
    if (
      question.inputType === "SINGLE_SELECTION" &&
      !isAnswerValid() &&
      !question.acceptChoice
    ) {
      // non selected single selection does not need submit button
      return null;
    }

    return (
      <SubmitButton
        isLoading={isLoading}
        text={getBottomNavText()}
        disabled={!isAnswerValid() || isLoading}
        onClick={handleSubmit}
      />
    );
  };

  const canDisplayImage = (q: Question) =>
    q.imageUrl && q.inputType !== "PICTURE";

  return (
    <Paper className={classes.paper} elevation={0} ref={ref}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid
          container
          direction="column"
          justify="space-between"
          className={classes.container}
        >
          <Grid item className={classes.progress}>
            <ProgressBar />
          </Grid>
          <Grid item className={classes.heading}>
            <Box className={classes.label}>
              <Typography variant="h2" component="h2" className={classes.title}>
                {question.description}
              </Typography>
              {question.hint ? (
                <Typography
                  variant="body2"
                  component="div"
                  className={classes.hint}
                >
                  <HtmlComponent htmlStr={question.hint} />
                </Typography>
              ) : null}
            </Box>
            {canDisplayImage(question) ? (
              <ImagePreview
                imageUrl={question.imageUrl}
                description={question.description}
              />
            ) : null}
          </Grid>
          <Grid item>
            <Grid item xs={12}>
              <Field
                onChange={handleFieldChange}
                question={question}
                answer={getAnswer()}
              />
            </Grid>
          </Grid>
          <Grid item className={classes.submit}>
            {BottomNav()}
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
});

export default Assessment;