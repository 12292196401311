import { useContext } from "react";
import { PopNotificationContext } from "../providers/PopNotificationProvider";

export default function usePopNotifications() {
  const { notification, setNotification, removeNotification } = useContext(
    PopNotificationContext
  );

  return { notification, setNotification, removeNotification };
}
