import React, { createContext, useContext, useState } from "react";

type GlobalState = {
  questionIndex: number,
};

export const stateContext = createContext({});

const StateProvider = ({ children }) => {
  const [state, setState] = useState({});

  const setGlobalState = (data: GlobalState) => {
    setState(Object.assign({}, state, data));
  };

  // a function that will help us to add the user data in the auth;
  return (
    <stateContext.Provider value={{ globalState: state, setGlobalState }}>
      {children}
    </stateContext.Provider>
  );
};

function useGlobalState() {
  return useContext(stateContext);
}

export { StateProvider, useGlobalState };
