// @flow

import { useQuery } from "react-query";
import { findPersonalQuestions, findQuestionsByCategoryAndReason } from "../services/api";

export default function useQuestions(category: string, reason: string) {
  const fetchQuestions = async () => {
    return await findQuestionsByCategoryAndReason(category, reason);
  };

  return useQuery(`questions-${category}-${reason}`, fetchQuestions);
}

export function usePersonalQuestions(categoryId: string) {
  return useQuery(`questions-personal`, async () => {
    return await findPersonalQuestions(categoryId);
  });
}
