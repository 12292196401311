// @flow
import React from "react";
import { useTranslation } from "react-i18next";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import { useNavigate } from "react-router-dom";

import { useConsultation } from "../providers/ConsultationProvider";
import ConsultationSection from "./ConsultationSection";
import { formatTimeDistance, getLocalPath } from "../common/utils";

type Props = {};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  item: {
    color: theme.palette.primary.main,
  },
}));

export default function ConsultationLinks(props: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { consultation, parentConsultation } = useConsultation();
  const navigate = useNavigate();
  const hasParent = Boolean(parentConsultation);
  const hasChildren = consultation.children && consultation.children.length > 0;

  if (!parentConsultation && !hasChildren) {
    return null;
  }

  const onOpen = (id: string) => {
    const url = hasParent ? `consultations/${id}?origin=${consultation.id}` : `consultations/${id}`;

    navigate(getLocalPath(url));
  };

  const getItems = () => {
    if (hasParent) return [parentConsultation];

    return consultation.children;
  };

  return (
    <ConsultationSection title={t("consultation.links")} defaultExpanded={true}>
      <List className={classes.root}>
        {getItems().map((item) => (
          <ListItem
            key={item.id}
            button
            onClick={() => onOpen(item.id)}
            className={classes.item}
          >
            <ListItemText
              primary={
                hasParent
                  ? t("consultation.initial")
                  : t("consultation.followup")
              }
            />
            <ListItemText primary={formatTimeDistance(item.updatedAt)} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="open">
                <LinkIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </ConsultationSection>
  );
}
