// @flow

import React from "react";
import { useTranslation } from "react-i18next";

import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { useConsultation } from "../providers/ConsultationProvider";
import PersonalInfo from "./PersonalInfo";
import Seperator from "./Seperator";
import ConsultationMeta from "./ConsultationMeta";
import DoctorInfo from "./DoctorInfo";
import { useAuth } from "../providers/AuthProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(3),
    paddingRight: theme.spacing(8),
    backgroundColor: theme.palette.background.paper,
  },
  rootMobile: {
    backgroundColor: theme.palette.background.paper,
  },
  status: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  prescribe: {
    marginBottom: theme.spacing(2),
  },
}));

type Props = {};

export default function ConsultationSidePanel(props: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { consultation } = useConsultation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useAuth();

  return (
    <Grid container direction="column" className={isMobile ? classes.mobileRoot : classes.root}>
      {user.doctor ? null : (
        <>
          <Grid item>
            <DoctorInfo />
          </Grid>
          <Seperator />
        </>
      )}
      {user.patient && isMobile ? null : (
        <>
          <Grid item>
            <PersonalInfo title={t("consultation.personal")} />
          </Grid>
          <Seperator />
        </>
      )}
      {isMobile ? null : (
        <Grid item>
          <ConsultationMeta consultation={consultation} />
        </Grid>
      )}
    </Grid>
  );
}
