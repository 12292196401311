// @flow

import * as React from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import usePopNotifications from "../hooks/usePopNotifications";

import { Grow } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  close: {
    padding: theme.spacing(1),
  },
}));

export default function PopNotifier() {
  const classes = useStyles();
  const { notification, removeNotification } = usePopNotifications();
  const [transition, setTransition] = useState(null);
  const [open, setOpen] = useState<boolean & Function>(false);

  const Transition = (props: any): React.Node => {
    return <Grow {...props} />;
  };

  useEffect(() => {
    if (notification) {
      setTransition(() => Transition);
      setOpen(true);
    } else if (notification && open) {
      setTransition(() => Transition);
    }
  }, [notification, open]);

  const handleClose = (event: Event, reason: string): void => {
    if (reason === "clickaway") {
      return;
    }
    removeNotification();

    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        key={Math.random() * 10}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        TransitionComponent={transition}
        autoHideDuration={6000}
        onClose={handleClose}
        onExited={() => {}}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      >
        {notification ? (
          <Alert severity={notification.severity} onClose={handleClose}>
            {notification.message}
          </Alert>
        ) : null}
      </Snackbar>
    </div>
  );
}
