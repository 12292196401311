// @flow

import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  overrides: {
    MuiAccordion: {
      root: {
        "&:before": {
          display: "none",
        },
      },
    },
    PrivateNotchedOutline: {
      root: {
        borderWidth: 2,
      },
    },
    MUIRichTextEditor: {
      container: {
        fontSize: 16,
      },
    },
  },
  palette: {
    background: {
      dark: "#EDEAE5",
      default: "hsla(0,0%,100%,.8)",
    },
    primary: {
      main: "#00A2AE",
    },
    secondary: {
      main: "#263BCD",
    },
    text: {
      primary: "#0D0D0D",
      secondary: "#666666",
    },
    success: {
      main: "#22CF7B",
    },
    error: {
      main: "#FF5C5C",
    },
    warning: {
      main: "#FFBC32",
    },
    // prettier-ignore
    grey: {
      "100": "#F2F2F2",
      "200": "#E5E5E5",
      "300": "#CCCCCC",
      "500": "#666666",
      "900": "#0D0D0D",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
    fontSize: 16,
    fontFamily: "Gibson W01 Regular, Arial",
    h1: {
      fontWeight: 600,
      fontSize: 30,
      letterSpacing: "-0.24px",
      lineHeight: 1.1,
    },
    h2: {
      fontWeight: 600,
      fontSize: 24,
      letterSpacing: "-0.24px",
      lineHeight: 1.1,
    },
    h3: {
      fontWeight: 600,
      fontSize: 20,
      letterSpacing: "-0.06px",
      lineHeight: 1.1,
    },
    h4: {
      fontWeight: 600,
      fontSize: 18,
      letterSpacing: "-0.06px",
      lineHeight: 1.2,
    },
    h5: {
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: "-0.05px",
      lineHeight: 1.2,
    },
    h6: {
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: "-0.05px",
      lineHeight: 1.2,
    },
    body1: {
      fontSize: 18,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: 16,
      lineHeight: 1.5,
    },
    overline: {
      fontSize: 14,
      lineHeight: 1.2,
    },
    caption: {
      fontSize: 14,
      lineHeight: 1.2,
    },
  },
  spacing: [2, 4, 8, 12, 16, 20, 24, 32, 40, 48, 56, 64],
  shadows: [
    "none",
    "0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 2px 2px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 5px 8px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 6px 12px -4px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 7px 12px -4px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 6px 16px -4px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 7px 16px -4px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 8px 18px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 9px 18px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 10px 20px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 11px 20px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 12px 22px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 13px 22px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 14px 24px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 16px 28px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 18px 30px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 20px 32px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 22px 34px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 24px 36px -8px rgba(0,0,0,0.25)",
  ],
});

export default theme;
