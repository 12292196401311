// @flow
import { useQuery } from "react-query";
import {
  findAddresses,
} from "../services/api";
import { getDefaultAddress } from "../services/address";

type Options = {
  onSuccess?: Function,
  onError?: Function,
};

const fetchDefaultAddress = async () => {
  const addresses = await findAddresses();

  return getDefaultAddress(addresses);
};

export function useAddress(opts?: Options) {
  return useQuery(`default-address`, fetchDefaultAddress, {
    onSuccess: (data) => {
      if (opts && opts.onSuccess) {
        opts.onSuccess(data);
      }
    },
  });
}
