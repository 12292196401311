// @flow

import { apiUrl } from "../common/globals";
import type {
  ConsultationRequest,
  Address,
  Answer,
  Comment,
  CommentUpdateRequest,
  AssigneeRequest,
  Item,
  TaskRequest,
  StatusModel,
  CardModel,
  RegisterAuthRequest,
  VerificationRequest,
  UserNote,
  ReviewRequest,
} from "../models";
import axios from "axios";
import { request } from "./request";
import { getToken } from "./auth";

export function registerUser(fields: RegisterAuthRequest) {
  return request("register", { data: fields });
}

export function verifyUser(data: VerificationRequest) {
  return request("verify", { data });
}

export function createConsultation(consultation: ConsultationRequest) {
  return request(`consultations`, { data: consultation });
}

export function findConsultations(status: StatusModel) {
  if (!status) {
    return request("consultations");
  }

  return request(`consultations?status=${status}`);
}

export function findUnassignedConsultations() {
  return request(`consultations/unassigned`);
}

export function getConsultation(id: string) {
  return request(`consultations/${id}`);
}

export function findItems(category: string): Promise<Array<Item>> {
  return request(`items?category=${category}`);
}

export function getSurvey(reason: string, category: string) {
  const url = `survey?reason=${reason}&category=${category}`;

  return request(url);
}

export function findQuestions(surveyId: number) {
  const url = `questions?survey=${surveyId}`;

  return request(url);
}

export function findPersonalQuestions(categoryId: string) {
  const url = `questions/personal?category=${categoryId}`;

  return request(url);
}

export function findQuestionsByCategoryAndReason(
  category: string,
  reason: string
) {
  const url = `questions?category=${category}&reason=${reason}`;

  return request(url);
}

export function findTaskContents(taskId: string) {
  const url = `consultations/tasks/${taskId}/contents`;

  return request(url);
}

export function findAnswers(consultationId: string) {
  const url = `consultations/${consultationId}/answers`;

  return request(url);
}

export function findPersonalAnswers(consultationId: string) {
  const url = `consultations/${consultationId}/answers/personal`;

  return request(url);
}

export function createAnswer(answer: Answer) {
  return request(`answers`, { data: answer });
}

export function updateAnswer(answer: Answer, answerId: number) {
  return request(`answers/${answerId}`, { data: answer, method: "put" });
}

export function findNotes(userId: string): Promise<Array<UserNote>> {
  return request(`users/${userId}/notes`);
}

export function createNote(userId: string, note: string) {
  return request(`users/${userId}/notes`, { data: { note } });
}

export function findComments(consultationId: string) {
  return request(`consultations/${consultationId}/comments`);
}

export function createComment(comment: Comment) {
  return request(`comments`, { data: comment });
}

export function updateComment(props: {
  commentId: number,
  commentUpdateRequest: CommentUpdateRequest,
}) {
  return request(`comments/${props.commentId}`, {
    data: props.commentUpdateRequest,
    method: "put",
  });
}

export function getCategories() {
  return request(`categories`);
}

export function getCategory(categoryId: string) {
  return request(`categories/${categoryId}`);
}

export function getDefaultSteps(reason: string) {
  return request(`consultations/steps?reason=${reason}`);
}

export function submitTask(consultationId: string, task: TaskRequest) {
  return request(`consultations/${consultationId}/submit`, { data: task });
}

export function assignConsultations(
  consultations: Array<string>,
  userId: string
) {
  const data: AssigneeRequest = {
    consultations,
    userId,
  };
  return request("consultations/assignee", { data });
}

export function reviewConsult(consultationId: string, data: ReviewRequest) {
  return request(`consultations/${consultationId}/review`, { data });
}

export function refillConsult(consultationId: string) {
  return request(`consultations/${consultationId}/refill`, { data: {} });
}

export function findAddresses() {
  return request("address");
}

export function findPayments() {
  return request("payments");
}

export function findByPaymentId(paymentId: string) {
  return request(`payments/${paymentId}`);
}

export function updatePayment(customerId: string, card: CardModel) {
  return request(`payments/${customerId}`, { data: card, method: "put" });
}

export function createPayment(card: CardModel) {
  return request("payments", { data: card });
}

export function createPaymentSession(
  orderId: string,
  addressId: number,
  returnUrl: string,
  coupon?: string
) {
  return request("payments/session", {
    data: { orderId, addressId, returnUrl, coupon },
  });
}

export function saveAddress(addressData: Address) {
  const url = "address";

  return request(url, { method: "post", data: addressData });
}

export function updateAddress(addressId: number, address: Address) {
  return request(`address/${addressId}`, { data: address, method: "put" });
}

export function findUsers(groupId: string) {
  return request(`users?group=${groupId}`);
}

export function findDoctors() {
  return request(`users?group=doctor`);
}

export function findOrders(consultationId: string) {
  return request(`orders?consultation=${consultationId}`);
}

export async function uploadImage(blob: Blob) {
  const url = `${apiUrl}/uploadFile`;
  const token = await getToken();

  // convert url to blob
  const formData = new FormData();
  formData.append("file", blob);

  return axios
    .post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    })
    .then((response) => response.data);
}
