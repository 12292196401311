// @flow

import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Editor } from "@tinymce/tinymce-react";

import {
  Button,
  Grid,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";
import OpenFileButton from "./OpenFileButton";
import { uploadImage } from "../services/api";
import ImagePreview from "./Assessment/ImagePreview";
import usePopNotifications from "../hooks/usePopNotifications";
import { TINYMCE_SRC } from "../constants";

const cardPopverStyles = makeStyles((theme) => ({
  popover: {
    width: "100%",
  },
  root: {
    padding: theme.spacing(6),
    width: "100%",
  },
  textField: {
    width: "100%",
  },
  uploadContainer: {
    minWidth: 400,
    minHeight: 200,
  },
  preview: {},
}));

type UploadImagePopoverProps = {
  anchor: ?Element,
  onSubmit: Function,
};

function UploadImagePopover(props: UploadImagePopoverProps) {
  const { t } = useTranslation();
  const classes = cardPopverStyles(props);
  const [state, setState] = useState({
    anchor: null,
    isCancelled: false,
  });
  const [data, setData] = useState({});

  useEffect(() => {
    setState({
      anchor: props.anchor,
      isCancelled: false,
    });
    setData({
      file: undefined,
    });
  }, [props.anchor]);

  const preview = data.file ? URL.createObjectURL(data.file) : null;
  const fileName = data.file ? data.file.name || "" : "";

  return (
    <Popover
      anchorEl={state.anchor}
      open={state.anchor !== null}
      onExited={() => {
        props.onSubmit(data, !state.isCancelled);
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      className={classes.popover}
    >
      <Grid container spacing={1} className={classes.root} direction="column">
        <Grid
          item
          spacing={4}
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.uploadContainer}
        >
          <Grid item>
            <Typography variant="body2" component="div">
              {t("editor.attachimage")}
            </Typography>
          </Grid>
          <Grid item>
            <OpenFileButton
              fullSize={false}
              text={t("editor.uploadimage")}
              onChange={(event) => {
                setData({
                  ...data,
                  file: event.target.files[0],
                });
              }}
            />
          </Grid>
          <Grid item className={classes.preview}>
            {preview ? (
              <ImagePreview
                imageUrl={preview}
                description={fileName}
                width={300}
                height={200}
              />
            ) : null}
          </Grid>
        </Grid>
        <Grid item container xs={12} justify="flex-end">
          <Button
            onClick={() => {
              setState({
                anchor: null,
                isCancelled: true,
              });
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            disabled={!data.file}
            onClick={() => {
              setState({
                anchor: null,
                isCancelled: false,
              });
            }}
          >
            {t("upload")}
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
}

type Props = {
  label?: string,
  defaultValue?: string,
  onFocus?: Function,
  onChange?: Function,
  autoFocus?: boolean,
  canUploadImage?: boolean,
  height?: number
};

const RichTextField = ({
  label,
  defaultValue,
  onFocus,
  onChange,
  autoFocus,
  canUploadImage,
  height
}: Props) => {
  const [anchor, setAnchor] = useState(null);
  const ref = useRef(null);
  const { setNotification } = usePopNotifications();

  const handleFileUpload = (file) => {
    if (ref.current && ref.current.editor) {
      uploadImage(file)
        .then((result) => {
          // $FlowFixMe
          ref.current.editor.insertContent(
            `<img height="200" src="${result.url}" />`
          );
        })
        .catch((err) => setNotification(err.message, "error"));
    }
  };

  return (
    <>
      {canUploadImage ? (
        <UploadImagePopover
          anchor={anchor}
          onSubmit={(data, insert) => {
            if (insert && data.file) {
              handleFileUpload(data.file);
            }
            setAnchor(null);
          }}
        />
      ) : null}
      <Editor
        ref={ref}
        tinymceScriptSrc={TINYMCE_SRC}
        outputFormat="text"
        onFocus={onFocus}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.getContent());
          }
        }}
        initialValue={defaultValue}
        init={{
          auto_focus: autoFocus,
          height: height || 300,
          menubar: false,
          statusbar: false,
          plugins: ["image"],
          toolbar: canUploadImage ? ["uploadImage"] : false,
          branding: false,
          setup: (editor) => {
            editor.ui.registry.addButton("uploadImage", {
              text: "Upload Image",
              onAction: function (_) {
                setAnchor(editor.getContainer());
              },
            });
          },
        }}
      />
    </>
  );
};

export default RichTextField;
