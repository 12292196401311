// @flow

import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  makeStyles,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import type { ConsultationStep } from "../models";

const useColorlibStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    color: "#fff",
    width: 100,
    height: 100,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();

  return (
    <div className={classes.root}>
      <img alt={props.name} src={props.imageUrl} height="90" />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
    textAlign: "center",
  },
  stepper: ({ isSmallPage }) => ({
    alignItems: isSmallPage ? "center" : "flex-start",
    justifyContent: "space-between",
  }),
  step: {
    marginTop: theme.spacing(4),
  },
  stepLabel: {
    flex: 0.3,
  },
  hint: {
    marginTop: theme.spacing(4),
    fontWeight: theme.typography.fontWeightBold,
  },
  content: {
    textAlign: "left",
  },
}));

type Props = {
  steps: Array<ConsultationStep>,
  orientation: string,
};

export default function StepsList(props: Props) {
  const theme = useTheme();
  const isSmallPage = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles({ isSmallPage });

  return (
    <Stepper
      alternativeLabel
      orientation={props.orientation}
      connector={null}
      className={classes.stepper}
    >
      {props.steps.map((step, idx) => (
        <Step
          key={`step_${idx}`}
          className={classes.step}
          active={true}
          classes={{ alternativeLabel: classes.stepLabel }}
        >
          <StepLabel
            StepIconComponent={() => (
              <ColorlibStepIcon name={step.name} imageUrl={step.imageUrl} />
            )}
          >
            <Grid container direction="column" className={classes.content}>
              <Grid item className={classes.title}>
                <Typography variant="body1" component="div">
                  {step.name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" component="p">
                  {step.description}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h5"
                  component="p"
                  className={classes.hint}
                >
                  {step.hint}
                </Typography>
              </Grid>
            </Grid>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
