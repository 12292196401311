// @flow

import { useTranslation } from "react-i18next";
import { useMutation, useQueryCache } from "react-query";
import { getCurrentTaskName } from "../services/task";
import usePopNotifications from "./usePopNotifications";

type Options = {
  onSuccess?: Function,
  onError?: Function,
  successText?: string,
};

export default function useConsultMutation(
  consultationId: string,
  update: Function,
  opts?: Options
) {
  const cache = useQueryCache();
  const { setNotification } = usePopNotifications();
  const { t } = useTranslation();
  const options = opts || {};
  const queryKey = `consultation-${consultationId}`;

  return useMutation(update, {
    onSuccess: (result) => {
      const consultation = cache.getQueryData(queryKey);

      if (consultation) {
        const successText =
          options.successText ||
          `${getCurrentTaskName(consultation)} ${t("notify.success")}`;
        setNotification(successText, "success");
      }

      cache.removeQueries(queryKey);
      if (options.onSuccess) {
        options.onSuccess(result);
      }
    },
    onError: (err) => {
      setNotification(err.message, "error");

      if (options.onError) {
        options.onError(err);
      }
    },
  });
}
