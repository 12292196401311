import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Grid,
  TextField,
  makeStyles,
  Box,
  Button,
  CircularProgress,
} from "@material-ui/core";

import type { Address } from "../models";
import CustomSelect from "./CustomSelect";
import { AUStates } from "../data";

type Props = {
  onSubmit: Function,
  onCancel: Function,
  isLoading?: Boolean,
  defaultAddress?: Address,
  submitText?: string,
  alwaysShow: Boolean,
};

const useStyles = makeStyles((theme) => ({
  actions: {
    marginTop: theme.spacing(4)
  },
}));

export default function AddressForm(props: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [address, setAddress] = useState(props.defaultAddress || {});

  const validate = (name, value) => {
    // Error checking: Fields empty
    if (name === "phone" && value.length === 0) {
      return t("address.phoneempty");
    }

    if (name === "address1" && value.length === 0) {
      return t("address.address1empty");
    }

    if (name === "city" && value.length === 0) {
      return t("address.cityempty");
    }

    if (name === "state" && value.length === 0) {
      return t("address.stateempty");
    }

    if (name === "postalCode" && value.length === 0) {
      return t("address.postalcodeempty");
    }

    return null;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const error = validate(name, value);

    setErrors(Object.assign({}, errors, { [name]: error }));

    if (!error) {
      setAddress(Object.assign({}, address, { [name]: value }));
    }
  };

  const emptyErrors = () => {
    return Object.keys(errors).every(
      (key) => !errors[key] || !errors[key].length
    );
  };

  const isValid = () => {
    return (
      emptyErrors() &&
      address.phone &&
      address.address1 &&
      address.city &&
      address.state &&
      address.postalCode &&
      !props.isLoading
    );
  };

  const handleSubmit = () => {
    props.onSubmit(address);
  };

  return (
    <React.Fragment>
      <Grid container spacing={6} className={classes.container}>
        <Grid item xs={12}>
          <TextField
            required
            error={Boolean(errors.phone)}
            helperText={errors.phone}
            defaultValue={address.phone}
            id="phone"
            name="phone"
            label={t("contact.number")}
            fullWidth
            autoComplete="tel"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            error={Boolean(errors.address1)}
            helperText={errors.address1}
            defaultValue={address.address1}
            id="address1"
            name="address1"
            label={t("address.line1")}
            fullWidth
            autoComplete="shipping address-line1"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            defaultValue={address.address2}
            id="address2"
            name="address2"
            label={t("address.line2")}
            fullWidth
            autoComplete="shipping address-line2"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={Boolean(errors.city)}
            helperText={errors.city}
            required
            defaultValue={address.city}
            id="city"
            name="city"
            label={t("address.city")}
            fullWidth
            autoComplete="shipping address-level2"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomSelect
            required
            value={address.state}
            label={t("address.state")}
            name="state"
            options={AUStates}
            onChange={handleChange}
            errorText={errors.state}
            autoComplete="shipping address-level1"
          />
        </Grid>
        <Box display="none">
          <Grid item xs={12} sm={6}>
            <TextField
              required
              defaultValue={address.country}
              id="country"
              name="country"
              label={t("address.country")}
              fullWidth
              autoComplete="shipping country-name"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Box>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            defaultValue={address.postalCode}
            error={Boolean(errors.postalCode)}
            helperText={errors.postalCode}
            id="zip"
            name="postalCode"
            label={t("address.zip")}
            fullWidth
            autoComplete="shipping postal-code"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" justify="flex-end" className={classes.actions}>
        <Box display={props.alwaysShow ? "none" : "inherit"}>
          <Button onClick={props.onCancel}>{t("cancel")}</Button>
        </Box>
        <Button
          color="primary"
          variant="outlined"
          disabled={!isValid()}
          onClick={handleSubmit}
        >
          {props.isLoading && <CircularProgress size={14} />}
          {!props.isLoading && t("address.save")}
        </Button>
      </Grid>
    </React.Fragment>
  );
}
