import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import {
  Container,
  Typography,
  makeStyles,
  Grid,
  Button,
} from "@material-ui/core";
import DefaultLayout from "../components/DefaultLayout";
import Seperator from "../components/Seperator";
import { getLocalPath } from "../common/utils";
import HtmlComponent from "../components/HtmlComponent";
import { ORDER_COMPLETE_IMG, PHARMACY_URL } from "../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  content: {
    marginTop: theme.spacing(2),
    margin: "auto",
  },
  title: {
    marginBottom: theme.spacing(6),
  },
  description: {
    marginTop: theme.spacing(7),
  },
  more: {
    marginBottom: theme.spacing(7),
  },
  aboutpharmacy: {
    marginBottom: theme.spacing(6),
  },
}));

export default function OrderCompletePage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <DefaultLayout title={t("page.payment_complete")}>
      <Container maxWidth="sm" className={classes.root}>
        <Grid container className={classes.content} direction="column">
          <Typography variant="h2" component="h2" className={classes.title}>
            {t("payment_complete.title")}
          </Typography>

          <Grid item container justify="center">
            <img
              width="80%"
              height="80%"
              src={ORDER_COMPLETE_IMG}
              alt="payment complete expression"
            />
          </Grid>

          <Typography
            variant="body2"
            component="div"
            className={classes.description}
          >
            <HtmlComponent htmlStr={t("payment_complete.description")} />
          </Typography>

          <Seperator />

          <Typography
            variant="h2"
            component="h2"
            className={classes.aboutpharmacy}
          >
            {t("payment_complete.pharmacy.about")}
          </Typography>

          <Typography variant="body2" component="div">
            <HtmlComponent
              htmlStr={t("payment_complete.pharmacy.description")}
            />
          </Typography>
          <Typography variant="body2" component="div" className={classes.more}>
            <Trans i18nKey="payment_complete.pharmacy.more">
              Want to learn more? Head to our <a href={PHARMACY_URL} target="_blank" rel="noopener noreferrer">About Our Pharmacy</a> page to get to know Mike and his team more.
            </Trans>
          </Typography>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate(getLocalPath(`/consultations/${id}`))}
            >
              {t("payment_complete.access")}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </DefaultLayout>
  );
}
