// @flow

import React from "react";
import {
  makeStyles,
  Card,
  CardActionArea,
  CardMedia,
  CardHeader,
} from "@material-ui/core";
import clsx from "clsx";

type Props = {
  imageUrl: string,
  description: string,
  onSelect: Function,
  selected: boolean,
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey[300]}`,
    "&:hover": {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  focusHighlight: {
    backgroundColor: theme.palette.primary.light
  },
  selected: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  header: {
    minHeight: 70,
    textAlign: "center",
    paddingTop: 0,
  },
  text: {},
  image: {
    width: "50%",
    margin: "auto",
    paddingTop: theme.spacing(6),
  },
}));

export default function ImageCardField({
  imageUrl,
  description,
  onSelect,
  selected,
}: Props) {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, { [classes.selected]: selected })}
      elevation={0}
      variant="outlined"
    >
      <CardActionArea
        onClick={() => onSelect(description)}
        classes={{ focusHighlight: classes.focusHighlight }}
      >
        <CardMedia
          component="img"
          alt={description}
          className={classes.image}
          image={imageUrl}
          title={description}
        />
        <CardHeader
          className={classes.header}
          classes={{ content: classes.text }}
          title={description}
        />
      </CardActionArea>
    </Card>
  );
}
