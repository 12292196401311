import React, { useState } from "react";
import { findConsultations } from "../services/api";
import { Trans, useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import DefaultLayout from "../components/DefaultLayout";
import ConsultationTileListView from "./ConsultationTileListView";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { Status } from "../models/enums";
import { useAuth } from "../providers/AuthProvider";
import { getLocalPath } from "../common/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
  },
  item: {
    width: "100%",
  },
}));

export default function PatientHomePage() {
  const { t } = useTranslation();
  const [selectedConsultation, setSelectedConsultation] = useState(null);
  const classes = useStyles();
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleSelect = (consultation: Consultation) => {
    setSelectedConsultation(consultation);
  };

  if (selectedConsultation) {
    return (
      <Navigate
        push
        to={getLocalPath(`consultations/${selectedConsultation.id}`)}
      />
    );
  }

  return (
    <DefaultLayout title={t("page.home")}>
      <Grid container direction="column" spacing={6} className={classes.root}>
        <Grid item className={classes.item}>
          <Typography variant="h2" component="h1">
            <Trans
              i18nKey="greeting"
              values={{ firstName: user.firstName }}
            ></Trans>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" component="p" gutterBottom>
            {t("greeting.welcome")}
          </Typography>
          <Typography variant="body1" component="p">
            {t("greeting.service")}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            size="large"
            disableElevation
            onClick={() => navigate(getLocalPath("entry"))}
          >
            {t("consultation.startnew")}
          </Button>
        </Grid>
        <Grid item className={classes.item}>
          <ConsultationTileListView
            title={t("consultations.ongoing")}
            viewId="ongoing"
            onSelect={handleSelect}
            fetch={() => findConsultations(Status.TODO)}
          />
        </Grid>
        <Grid item className={classes.item}>
          <ConsultationTileListView
            title={t("consultations.waiting")}
            viewId="waiting"
            onSelect={handleSelect}
            fetch={() => findConsultations(Status.INPROGRESS)}
          />
        </Grid>
        <Grid item className={classes.item}>
          <ConsultationTileListView
            title={t("consultations.completed")}
            viewId="completed"
            onSelect={handleSelect}
            fetch={() => findConsultations(Status.DONE)}
          />
        </Grid>
      </Grid>
    </DefaultLayout>
  );
}
