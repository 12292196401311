// @flow

import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  seperator: {
    marginTop: theme.spacing(6),
    borderBottom: `1px solid ${theme.palette.grey["300"]}`,
    marginBottom: theme.spacing(6),
  },
}));

export default function Seperator() {
  const classes = useStyles();
  return <div className={classes.seperator}></div>;
}
