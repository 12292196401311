// @flow
import React from "react";
import { Typography } from "@material-ui/core";

type Props = {
  message: string,
};

// TODO: make a better error presentation
export default function ErrorTypography({ message }: Props) {
  return (
    <Typography variant="body2" component="p" gutterBottom>
      {`Error: ${message}`}
    </Typography>
  );
}
