// @flow
import React from "react";
import {
  Typography,
  makeStyles,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PriceTable from "./PriceTable";
import { getPriceList } from "../services/order";
import type { Order } from "../models";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  slogan: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    "& ul": {
      listStyle: "none",
      marginLeft: theme.spacing(0),
    },
  },
}));

type Props = {
  order?: Order,
  showSlogan?: boolean,
};

export default function OrderSummary({ order, showSlogan }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  if (!order) {
    return null;
  }

  const slogans: Array<string> = [
    t("order.slogan.line1"),
    t("order.slogan.line2"),
    t("order.slogan.line3"),
  ];

  const Slogan = () => (
    <List dense={true} disablePadding={true}>
      {slogans.map((slogan, i) => (
        <ListItem dense={true} disableGutters={true} key={`slogan_${i}`}>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText primary={slogan} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      <Typography variant="h3" component="h4" className={classes.title}>
        {t("order.summary")}
      </Typography>
      <PriceTable items={getPriceList(order, { consultation: t("consultation.name") })} />
      <Grid container className={classes.slogan}>
        {showSlogan ? <Slogan /> : null}
      </Grid>
    </>
  );
}
