// @flow

import React from "react";
import type { Node } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

type Props = {
  children: Node,
  open: boolean,
  title: string,
  error?: Error,
  actionRenderer: Function,
  fullWidth?: boolean
};

export default function DialogContainer({
  children,
  open,
  title,
  error,
  actionRenderer,
  fullWidth
}: Props) {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        fullScreen={fullWidth === undefined ? fullScreen : fullWidth}
        open={open}
        aria-labelledby="a-common-dialog"
        className={classes.root}
      >
        <DialogTitle disableTypography>
          <Typography variant="h2" component="h2">
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>{actionRenderer()}</DialogActions>
        {error ? <Alert severity="error">{error.message}</Alert> : null}
      </Dialog>
    </div>
  );
}
